import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { CloudDownload, ErrorOutline, Print } from "@mui/icons-material";
import { Box, Button, Card, FormLabel, FormControl, FormControlLabel, Grid, RadioGroup, Radio, Icon, MenuItem, TextField, Tooltip, styled } from "@mui/material";
import { Loader } from "app/components";
import { getAllProjectLocationListForReports } from "app/redux/actions/ProjectLocationActions";
import { getRateAnalysisReport } from "app/redux/actions/ReportsAction";
import { AppConstants } from "app/utils/AppConstants";
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import { checkValidation, formatNumberWithCommasAndDecimals, getTwoDigitDecimal } from "app/utils/utils";
import { SimpleCard } from "components";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { FaEye } from "react-icons/fa";
import { ServiceUrl } from 'app/utils/Constants';
import { RateAnalysisCalculation } from '../ProjectLocation/RateAnalysisCalculation';

const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));

const styles = {
  tableCell: {
    textAlign: "center"
  },
  subHeader: {
    textAlign: "center",
    fontWeight: 700
  }

}
const DecoratedTextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));




export const RateAnalysisReport = () => {

  const dispatch = useDispatch();
  const [isDownloading, setIsDownloading] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [projectDetails, setProjectDetails] = useState()
  const [totalAmount, setTotalAmount] = useState()
  const { reportsProjectLocationList } = useSelector((state) => state.projectLocation);
  const [rateType, setRateType] = useState("p_l")
  // const {userList}=useSelector((state)=>state.userList)
  const [tableData, setTableData] = useState([])
    ;
  const [filteredTableData, setFilteredTableData] = useState({})
  const [itemWiseTableData, setItemWiseTableData] = useState([])
  const [itemCodes, setItemsCodes] = useState([])
  // const[TableRows,setTableRows]=useState()
  const [filterState, setFilterState] = useState({
    chapter_name: "",
    limit: "all",
  })
  const [chapterList, setChapterList] = useState()
  const [pdfTableData, setPdfTableData] = useState({})
  const [state, setState] = useState({
    project_location_id: '',
    items: []
  })

  const handleFilterState = (event) => {
    if (event.target.name === "chapter_name") {
      setItemsCodes([])
      setFilterState({
        ...filterState,
        [event.target.name]: event.target.value
      })

    }
    else if (event.target.name === "items") {
      const selectedItems = event.target.type === "checkbox"
        ? event.target.checked
          ? [...state.items, event.target.value]
          : state.items.filter(id => id !== event.target.value)
        : event.target.value;

      // Limit the selection to a maximum of 4 items
      // if (selectedItems.length > 4) {
      //   const key = enqueueSnackbar("Can not be selected more than four base location", { variant: 'info' });
      //     SnackBarProperty.info(key, closeSnackbar);
      //   return;
      // }

      setState(state => ({
        ...state,
        [event.target.name]: selectedItems,
      }));
    }



  }
  useEffect(() => {
    dispatch(getAllProjectLocationListForReports(showLoader, hideLoader, showToast))
  }, []);
  const showLoader = () => {
    setLoading(true)
  }
  const showToast = (message) => {
    const key = enqueueSnackbar(message ?? AppConstants.somethingWentWrong, { variant: 'error' });
    SnackBarProperty.error(key, closeSnackbar);
  }

  const hideLoader = () => {
    setLoading(false)
  }
  const onRateTypeChange = (event) => {
    setRateType(event.target.value);
    setTableData([])
    setItemWiseTableData()
    setItemsCodes([])
    setChapterList([])
    setFilteredTableData([])
    setFilteredTableData([])
    setPdfTableData([])
    setFilterState({
      chapter_name: "",
    })
    setState({ ...state, items: [] })
  };
  const handleChange = (event, source) => {

    if (event.target.name === "project_location_id") {
      const filteredArray = reportsProjectLocationList.filter(project => {
        return project.project_location_id === event.target.value
      });
      state.project_name = filteredArray[0].project_name
      state.project_location = filteredArray[0]?.project_location
      state.project_location_id = filteredArray[0].project_location_id
      state.project_id = filteredArray[0].project_id
      state.bsr_year = filteredArray[0]?.bsr_year

      setState({ ...state, [event.target.name]: event.target.value });
      setTableData([])
      setItemWiseTableData()
      setItemsCodes([])
      setChapterList([])
      setFilteredTableData([])
      setFilteredTableData([])
      setPdfTableData([])
      setFilterState({
        chapter_name: "",
      })
      setState({ ...state, items: [] })

    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  }

  function getTotalAmountForEachItem(builtupItemRateDetails) {
    var totalAmount = 0
    builtupItemRateDetails?.forEach((item) => {
      totalAmount += getTwoDigitDecimal(getTwoDigitDecimal(item?.original_rate) * getTwoDigitDecimal(item?.coefficient, 5))
    });
    return getTwoDigitDecimal(totalAmount)
  }

  function getTotalAmountForEachBuiltupItem(builtupItemRateDetails) {
    var totalAmount = 0
    builtupItemRateDetails?.forEach((item) => {
      if ((item?.isBuiltUpItem ?? false) === true) {
        totalAmount += getTwoDigitDecimal(getTwoDigitDecimal(item?.original_rate) * getTwoDigitDecimal(item?.coefficient, 5))
      }
    });
    return getTwoDigitDecimal(totalAmount)
  }

  function getTotalAmountForEachBMLItem(builtupItemRateDetails) {
    var totalAmount = 0
    builtupItemRateDetails?.forEach((item) => {
      if ((item?.isBuiltUpItem ?? false) === false) {
        totalAmount += getTwoDigitDecimal(getTwoDigitDecimal(item?.original_rate) * getTwoDigitDecimal(item?.coefficient, 5))
      }
    });
    return getTwoDigitDecimal(totalAmount)
  }


  function getWastagePercentage(builtupItemRateDetails) {
    var totalAmount = getTotalAmountForEachBMLItem(builtupItemRateDetails)
    var wastageAmount = ((totalAmount * builtupItemRateDetails[0]?.original_wastage_percentage) / 100)
    return getTwoDigitDecimal(wastageAmount)
  }

  function getTotalBAmount(builtupItemRateDetails) {
    var getTotalAmount = getTotalAmountForEachItem(builtupItemRateDetails)
    var wastageAmount = getWastagePercentage(builtupItemRateDetails)
    var total = getTotalAmount + wastageAmount
    return getTwoDigitDecimal(total)
  }

  function getWaterPercentage(builtupItemRateDetails) {
    var totalAmount = getTotalBAmount(builtupItemRateDetails)
    var waterAmount = ((totalAmount * builtupItemRateDetails[0]?.original_water_percentage) / 100)
    return getTwoDigitDecimal(waterAmount)
  }

  function getTotalCAmount(builtupItemRateDetails) {
    var totalAmount = getTotalBAmount(builtupItemRateDetails)
    var waterAmount = getWaterPercentage(builtupItemRateDetails)
    var total = totalAmount + waterAmount
    return getTwoDigitDecimal(total)
  }

  function getOverheadPercentage(builtupItemRateDetails) {
    var totalAmount = getTotalCAmount(builtupItemRateDetails)
    var overheadAmount = ((totalAmount * builtupItemRateDetails[0]?.original_overhead_percentage) / 100)
    return getTwoDigitDecimal(overheadAmount)
  }

  function getRatePerUnit(builtupItemRateDetails) {
    var totalAmount = getTotalCAmount(builtupItemRateDetails)
    var overheadAmount = getOverheadPercentage(builtupItemRateDetails)
    var total = totalAmount + overheadAmount + getTotalAmountForEachBuiltupItem(builtupItemRateDetails)
    return getTwoDigitDecimal(total)
  }


  const handleFormSubmit = () => {
    setLoading(true)
    setTableData([])
    setChapterList([])
    setFilteredTableData([])
    setFilterState({
      chapter_name: "",
      limit: "all"
    })
    getRateAnalysisReport({ projectlocation_id: state?.project_location_id })
      .then((res) => {
        if (res?.status < 300) {
          if (res?.data?.success) {
            if (res?.data?.obj) {
              setProjectDetails({
                header: res?.data?.obj?.header_logo,
                footer: res?.data?.obj?.footer_logo
              })
              const tableDataList = res?.data?.obj?.item?.map((elem) => {
                const chapter_name = elem?.chapter_name
                const dataList = elem?.item_list?.map((item, index) => {

                  const subChapter = { code: item?.code, description: item?.description, unit_name: item?.unit_name }
                  let waterPercentage = 0
                  let wastagePercentage = 0
                  let overheadPercentage = 0
                  const itemDataList = item?.projectLocationBsrBuiltupItemsDetailsList ?? []
                  console.log('itemDataList ++++ ', itemDataList)

                  const wastageAmount = rateType === 'p_l' ? getTwoDigitDecimal(item?.wastage_amount) : getWastagePercentage(itemDataList)
                  console.log('}}}}', wastageAmount)

                  const amountB = rateType === 'p_l' ? getTwoDigitDecimal(item?.total_wastage) : getTotalBAmount(itemDataList)

                  const waterAmount = rateType === 'p_l' ? getTwoDigitDecimal(item?.water_amount) : getWaterPercentage(itemDataList)
                  const amountC = rateType === 'p_l' ? getTwoDigitDecimal(item?.total_water) : getTotalCAmount(itemDataList)

                  const overheadAmount = rateType === 'p_l' ? getTwoDigitDecimal(item?.overhead_charges) : getOverheadPercentage(itemDataList)
                  const totalAmountFinal = rateType === 'p_l' ? parseFloat(parseFloat(item?.total_water ?? 0).toFixed(2)) + parseFloat(item?.overhead_charges ?? 0) : getRatePerUnit(itemDataList)

                  const list = item?.projectLocationBsrBuiltupItemsDetailsList?.map((builtUpItem) => {
                    waterPercentage = rateType === 'p_l' ? builtUpItem?.water_percentage : builtUpItem?.original_water_percentage
                    wastagePercentage = rateType === 'p_l' ? builtUpItem?.wastage_percentage : builtUpItem?.original_wastage_percentage
                    overheadPercentage = rateType === 'p_l' ? builtUpItem?.overhead_percentage : builtUpItem?.original_overhead_percentage
                    
                    return {
                      code: builtUpItem?.code,
                      description: builtUpItem?.description,
                      quantity: builtUpItem?.coefficient,
                      unit: builtUpItem?.unit_name,
                      rate: rateType === 'p_l' ? getTwoDigitDecimal(builtUpItem?.rate) : getTwoDigitDecimal(builtUpItem?.original_rate),
                      amount: rateType === 'p_l' ? parseFloat(parseFloat(builtUpItem?.calculaterate).toFixed(2)) : getTwoDigitDecimal((getTwoDigitDecimal(builtUpItem?.original_rate) * getTwoDigitDecimal(builtUpItem?.coefficient, 5))) 
                      //amount: rateType === 'p_l' ? parseFloat(parseFloat(builtUpItem?.calculaterate).toFixed(2)) : 
                    }
                  })

                  // console.log("Sub Chapter: ",subChapter,"bsr: ",list)
                  //  console.log(`list ${index}`,list)
                  let SubTotalAmount = 0
                  if (list?.length > 0) {
                    SubTotalAmount = list.reduce((total, item) => total + item?.amount, 0);
                  }
                  const extraCharges = [
                    {
                      charges: "Wastage Charges",
                      percent: wastagePercentage,
                      amount: wastageAmount
                    },
                    {
                      charges: "Sub Total Amount",
                      amount: amountB
                    },
                    {
                      charges: "Water Charges",
                      percent: waterPercentage,
                      amount: waterAmount
                    },
                    {
                      charges: "Sub Total Amount",
                      amount: amountC
                    },
                    {
                      charges: "Over Head Charges & Profit",
                      percent: overheadPercentage,
                      amount: overheadAmount
                    }
                  ]


                  const totalCharges = extraCharges.reduce((total, item) => total + item.amount, 0)
                  const totalAmount = totalAmountFinal

                  if (rateType === "b_l") {
                    return {
                      subChapter: subChapter,
                      subChapterList: list,
                      subTotal: parseFloat(parseFloat(SubTotalAmount).toFixed(2)),
                      charges: extraCharges,
                      total: totalAmount ? totalAmount : 0
                    }
                  }
                  else if (rateType === "p_l" && item?.builtupitem_rate !== item?.original_copied_rate) {
                    return {
                      subChapter: subChapter,
                      subChapterList: list,
                      subTotal: parseFloat(parseFloat(SubTotalAmount).toFixed(2)),
                      charges: extraCharges,
                      total: totalAmount ? totalAmount : 0
                    }
                  }
                  else {
                    return 0
                  }
                })

                const obj = {
                  chapter_name: chapter_name,
                  data: dataList?.filter((elem) => elem !== 0),
                }
                return obj

              })
              console.log(tableDataList)


              setTableData(tableDataList)



            }
          }
          else {
            const key = enqueueSnackbar(res?.data?.message, { variant: 'info' });
            SnackBarProperty.info(key, closeSnackbar);

          }
        }
        else {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        }
      }).catch(error => {
        setLoading(false)
        const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      })


    function mergeByConstructionType(data) {
      console.log("data", data)
      const result = data.reduce((acc, curr) => {
        console.log("current", curr)
        const constructionType = curr.construcion_type;
        const totalAmount = curr.item_list.reduce((sum, item) => sum + item.amount, 0);

        // Create a new object with construction_type and total
        acc.push({ description: `Total for ${constructionType} (Nu)`, total: totalAmount });
        return acc;
      }, []);

      return result;
    }


  }

  const handleReportDownload = (isPrint) => {
    const doc = new jsPDF();
    const contentHeight = 150;
    if (isPrint) {
      setIsPrinting(true)
      setIsDownloading(false)
    }
    else {
      setIsPrinting(false)
      setIsDownloading(true)
    }
    const headerImage = document.getElementById("headerLogo"); // Assuming you have an element with id "header" for the header image

    doc.setFontSize(12); // Set font size


    html2canvas(headerImage, {
      useCORS: true, // Add this line if the image is from a different domain
      allowTaint: true, // Add this line if the image is from a different domain
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Calculate the width and height of the header image based on the PDF page width
      const pdfWidth = doc.internal.pageSize.getWidth() - 50;
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      const headerHeight = pdfHeight;
      const tableY = headerHeight + 15;
      // Insert the header image into the PDF
      const topBorderPadding = 10; // Adjust the padding value as needed
      doc.setDrawColor(255, 255, 255); // Black color for border
      doc.setLineWidth(topBorderPadding); // Border width (padding)

      // Draw the top border (padding)
      doc.line(0, topBorderPadding / 2, pdfWidth, topBorderPadding / 2);

      // Insert the header image into the PDF
      doc.addImage(imgData, "PNG", 25, topBorderPadding, pdfWidth, pdfHeight);
      const textX = 35;
      const textY = tableY + 5;
      doc.setFont('helvetica', 'bold'); // Set font to bold
      doc.setFontSize(12); // Set font size
      doc.text(`Rate Analysis`, (doc.internal.pageSize.getWidth() / 2) - ((doc.getStringUnitWidth("Rate Analysis") * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2), textX);

      const headerSectionHeight = headerHeight + pdfHeight; // Adjust the value as needed

      const textWidth = doc.getStringUnitWidth("Project Location:") * doc.internal.getFontSize() / doc.internal.scaleFactor;

      doc.text("Project Location:", textX, textY + 5);
      doc.setFont('helvetica', 'normal');
      doc.text(state?.project_location, textX + textWidth + 5, textY + 5);
      doc.setFont('helvetica', 'bold');
      doc.text("Project Name:", textX, textY + 10);
      doc.setFont('helvetica', 'normal');
      doc.text(state?.project_name, textX + textWidth + 5, textY + 10);
      doc.setFont('helvetica', 'bold');
      doc.text("Project ID:", textX, textY + 15);
      doc.setFont('helvetica', 'normal');
      doc.text(`${state?.project_id}`, textX + textWidth + 5, textY + 15);
      doc.setFont('helvetica', 'bold');
      doc.text("BSR Year:", textX, textY + 20);
      doc.setFont('helvetica', 'normal');
      doc.text(`${state?.bsr_year}`, textX + textWidth + 5, textY + 20);

      // Calculate the starting Y-coordinate for the table
      const tableStartY = headerSectionHeight + 30;
      doc.setFont('helvetica', 'normal');
      const body = []


      pdfTableData && pdfTableData?.length > 0 && pdfTableData?.forEach((elem) => {

        body.push([{ content: elem?.chapter_name, colSpan: 6, styles: { fontStyle: "bold", valign: "middle", halign: "center" } }])
        elem?.data?.forEach((subItem) => {
          body.push([{ content: subItem?.subChapter?.code, styles: { fontStyle: "bold" } }, 
                     { content: subItem?.subChapter?.description, colSpan: 4, styles: { fontStyle: "bold" } },
                     { content: subItem?.subChapter?.unit_name, styles: { fontStyle: "bold" } }])
          subItem?.subChapterList?.length > 0 && subItem?.subChapterList?.forEach((itemList) => {
            body.push([itemList?.code, itemList?.description, itemList?.quantity, itemList?.unit, formatNumberWithCommasAndDecimals(itemList?.rate), formatNumberWithCommasAndDecimals(itemList?.amount)])
          })

          body.push([{ content: "Sub Total Amount", colSpan: 5, styles: { halign: "right", fontStyle: "bold" } }, { content: formatNumberWithCommasAndDecimals(subItem?.subTotal), styles: { fontStyle: "bold" } }])
          subItem?.charges?.length > 0 && subItem?.charges?.forEach((charge) => {
            if (charge?.percent) {
              body.push([{ content: charge?.charges, colSpan: 4, styles: { halign: "right" } }, `${charge?.percent}%`, { content: formatNumberWithCommasAndDecimals(charge?.amount), styles: { halign: "right" } }])
            }
            else {
              body.push([{ content: charge?.charges, colSpan: 5, styles: { halign: "right", fontStyle: "bold" } }, { content: formatNumberWithCommasAndDecimals(charge?.amount), styles: { halign: "right", fontStyle: "bold" } }])
            }

          })
          body.push([{ content: "Total Amount", colSpan: 5, styles: { halign: "right", fontStyle: "bold" } }, { content: formatNumberWithCommasAndDecimals(subItem?.total), styles: { fontStyle: "bold" } }])

        })
        const grandTotal = elem?.data?.reduce((total, current) => total + parseFloat(parseFloat(current?.total).toFixed(2)), 0)
        body.push([{ content: "Grand Total", colSpan: 5, styles: { fontStyle: "bold", valign: "middle", halign: "right" } }, { content: formatNumberWithCommasAndDecimals(grandTotal), styles: { halign: "right", fontStyle: "bold" } }])
      })
      //body.push([{content:"Total Amount",colSpan:4,styles: { halign:"right"}},totalAmount])

      doc.autoTable({
        startY: tableStartY,
        head: [[
          { content: "Code", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Description", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Quantity", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Unit", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Rate (Nu)", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Amount (Nu)", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } }
        ]],
        body: body, // Use the transformed data here
        theme: "grid",
        styles: {
          fillColor: [255, 255, 255], // Remove background color from the table
          lineColor: [0, 0, 0], // Set border color for main table
          lineWidth: 0.1, // Set border width for main table
          textColor: [0, 0, 0], // Set text color to black
          cellPadding: 2, // Add padding to cells
        },
        columnStyles: {
          2: { cellWidth: 50 }, // "ITEM DESCRIPTION" column width
          5: { halign: "right" }, // "RATE" column width
          6: { halign: "right" }, // "AMOUNT" column width
          3: { halign: "right" }, // "UNIT" column width
        },
      });

      // Function to add footer on each page
      function addFooter(pageNumber) {
        // const footerText = "Hello CMS User";
        // const pageString = "Page " + pageNumber + " of " + doc.internal.getNumberOfPages();
        const footerX = 0; // Starting X position for the footer
        const footerY = doc.internal.pageSize.getHeight() - 10; // 10 units from the bottom
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = 10;
        // const availableWidth = pdfWidth - 2 * footerX;

        // doc.setTextColor(0, 0, 0); // Set text color to black
        // doc.setFontSize(10); // Set font size for the footer

        // // Calculate the width of the text and page numbers
        // const textWidth = doc.getStringUnitWidth(footerText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        // const pageWidth = doc.getStringUnitWidth(pageString) * doc.internal.getFontSize() / doc.internal.scaleFactor;

        // // Calculate the space needed for equal spacing
        // const spaceWidth = (availableWidth - textWidth - pageWidth) / 2;

        // // Add footer text and page numbers in the same row, justified with space between them
        // doc.text(footerX, footerY, footerText);
        // doc.text(footerX + spaceWidth + textWidth, footerY, pageString);
        doc.addImage(`${ServiceUrl?.showImageUrl}${projectDetails?.footer}`, "PNG", footerX, footerY, pdfWidth, pdfHeight, 'FAST')
      }

      // Get the total number of pages
      const totalNumPages = doc.internal.getNumberOfPages();

      // Loop through each page to add the footer with page numbers
      for (let i = 1; i <= totalNumPages; i++) {
        doc.setPage(i);
        addFooter(i);
      }


      const scaleFactor = 1.0; // Adjust this value as needed
      const pdfBlob = doc.output('blob', { scale: scaleFactor });

      // Create a URL for the Blob
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Open a new window with the PDF URL

      if (isPrint) {
        const newWindow = window.open(pdfUrl, '_blank');

        // After the new window is opened, you can close the URL object
        URL.revokeObjectURL(pdfUrl);
        if (newWindow) {
          newWindow.onload = () => {
            setIsPrinting(false)
            setIsDownloading(false)
            // Wait for the window to load and then trigger the print dialog
            newWindow.print();
          };
        }
      }

      setIsPrinting(false)
      setIsDownloading(false)

      isPrint === false && doc.save('Rate_Analysis_Report.pdf');

    });
  };
  useEffect(() => {
    if (tableData?.length > 0) {
      console.log("tableData: ", tableData)
      const chapters = tableData?.map(elem => { return elem?.chapter_name })
      setChapterList(chapters)
    }
  }, [tableData])

  useEffect(() => {
    if (chapterList?.length > 0) {
      setLoading(false)

    }
  }, [chapterList])
  useEffect(() => {
    if (filterState?.chapter_name !== "") {
      setLoading(true)
      let filteredArray = tableData?.filter(elem => elem?.chapter_name === filterState?.chapter_name)
      setItemsCodes(filteredArray[0]?.data)
      console.log("filteredArray:", filteredArray[0])
      // setPdfTableData(filteredArray)
      // let data = []
      // if (filterState?.limit === "all") {
      //   console.log("all called")
      //   data = filteredArray[0]?.data
      // }
      // else {
      //   const limitedData = []
      //   filteredArray[0]?.data?.forEach((elem, index) => {
      //     if (index < filterState?.limit) {
      //       limitedData?.push(elem)
      //     }
      //   })
      //   data = limitedData
      // }
      // console.log("data:",data)

      setItemWiseTableData({ chapter_name: filterState?.chapter_name, data: filteredArray[0]?.data })
      setLoading(false)
    }
  }, [filterState])

  const handleViewFilteredReport = () => {
    setFilteredTableData()
    setPdfTableData()
    if (itemWiseTableData && itemWiseTableData?.data?.length > 0 && state?.items?.length > 0) {


      const result = itemWiseTableData?.data?.map((elem) => {
        if (state?.items?.some(item => item === elem?.subChapter?.code)) {
          return elem
        }
        else {
          return 0
        }
      })?.filter((elem) => elem !== 0)
      setFilteredTableData({ chapter_name: itemWiseTableData?.chapter_name, data: result })
      setPdfTableData([{ chapter_name: itemWiseTableData?.chapter_name, data: result }])
    }
    else {
      const key = enqueueSnackbar("Please select at least one item", { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    }
  }

  return (
    <Container>

      <div style={{ height: 'auto', width: "100%", display: "flex", flexDirection: "column", gap: "20px" }}>

        <Grid container>

          <Grid item xs={12}>
            <Button
              size="small"
              sx={{ width: "100px" }}
              variant="contained"
              color="error"
              onClick={() => { window.history.back() }}
            >
              <Icon color="primary" fontSize="small">
                skip_previous
              </Icon>
              Back
            </Button>
          </Grid>
        </Grid>
        <SimpleCard>

          <ValidatorForm onSubmit={handleFormSubmit}>
            <Grid container spacing={3} style={{ marginTop: '-45px', marginBottom: '15px' }} >
              <Grid item xs={12}><b className="font20 extraBold" style={{ color: "#ebad4e" }}>Rate Analysis Report</b></Grid>
            </Grid>
            <Grid container spacing={3}>

              <Grid item sm={6} xs={12}>
                <TextField
                  select
                  size="small"
                  fullWidth
                  sx={{ mb: 3 }}
                  label="Project ID"
                  id="project_location_id"
                  variant="outlined"
                  name="project_location_id"
                  value={state?.project_location_id}
                  required
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={["Project ID is required"]}
                >
                  {reportsProjectLocationList?.map((item) => (
                    <MenuItem value={item?.project_location_id} key={item?.project_id}>
                      {item?.project_id}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item sm={4} xs={12}>
                <Button size="medium" color="success" variant="contained" type="submit" style={{ marginLeft: 4 }} >View</Button>
              </Grid>

              <Grid item md={10} sm={12} xs={12} style={{ marginTop: -30 }} >
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    name="rate_type"
                    value={rateType}
                    onChange={onRateTypeChange}
                  >
                     <FormControlLabel
                      label="Project Location Rate"
                      value="p_l"
                      control={<Radio size="small" color="secondary" />}
                      sx={{ height: 20 }}
                    />
                     <FormControlLabel
                      label="Base Location Rate"
                      value="b_l"
                      control={<Radio size="small" color="secondary" />}
                      sx={{ mr: 3, height: 20 }}
                    />
                   
                  </RadioGroup>
                </FormControl>
              </Grid>

            </Grid>

          </ValidatorForm>
        </SimpleCard>
        {chapterList && chapterList?.length > 0 &&
          <Grid container sx={{ gap: 2 }}>

            <Grid item xs={12} sm={5}>
              <TextField
                select
                size="small"
                fullWidth
                label="Chapter"
                id="chapter_name"
                variant="outlined"
                sx={{ backgroundColor: "white" }}
                name="chapter_name"
                value={filterState?.chapter_name}
                onChange={handleFilterState}
              >
                {chapterList?.map((item, index) => (
                  <MenuItem value={item} key={index}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {/* <Grid item xs={6} sm={1}>
              <TextField
                select
                size="small"
                fullWidth
                sx={{ ml: 2, backgroundColor: "white" }}
                label="Items"
                id="items"
                variant="outlined"
                name="limit"
                value={filterState?.limit}
                required
                onChange={handleFilterState}
                validators={["required"]}
                errorMessages={["Project ID is required"]}
              >
                <MenuItem value={10} key={10}>
                  10
                </MenuItem>
                <MenuItem value={25} key={25}>
                  25
                </MenuItem>
                <MenuItem value={50} key={50}>
                  50
                </MenuItem>
                <MenuItem value={"all"} key={100}>
                  All
                </MenuItem>
              </TextField>
            </Grid> */}
            {itemCodes && itemCodes?.length > 0 && <Grid item xs={12} sm={2}>
              <TextField
                fullWidth
                select
                size="small"
                name="items"
                id="items"
                variant="outlined"
                label="Item Code"
                SelectProps={{
                  multiple: true,
                  value: state.items,
                  onChange: handleFilterState
                }}
              >
                {/* {itemCodes && itemCodes?.length > 0 && itemCodes?.map((element) => (
                  <MenuItem value={element?.subChapter?.code} style={state.items?.includes(element?.subChapter?.code) ? { backgroundColor: '#FFA33C' } : {}}>{element?.subChapter?.code}
                  </MenuItem>
                ))} */}

                {itemCodes && itemCodes.length > 0 && itemCodes.map((element) => (

                  <MenuItem
                    value={element?.subChapter?.code}
                    style={state.items?.includes(element?.subChapter?.code) ? { backgroundColor: '#FFA33C' } : {}}
                  >
                    {element?.subChapter?.code}
                    {/* <Tooltip
                      key={element?.subChapter?.code}
                      title={element?.subChapter?.description || "No description available"}
                      arrow placement="right"
                    >
                      {element?.subChapter?.code}
                    </Tooltip> */}
                  </MenuItem>

                ))}

              </TextField>
            </Grid>}
            {state?.items?.length > 0 && <Grid items xs={12} sm={2}>
              <Button
                size="small"
                sx={{ width: "100px" }}
                variant="contained"
                color="primary"
                startIcon={<FaEye />}
                onClick={() => { handleViewFilteredReport() }}
              >
                View
              </Button>
            </Grid>}

            {filterState?.chapter_name && filteredTableData?.data?.length &&
              <>
                <Grid xs={12} sm={6}>
                  {checkValidation(projectDetails?.header) === false && <FormLabel sx={{ color: "#ff9800" }} color="warning">! Please upload header and footer for download/print</FormLabel>}
                </Grid>
                <Grid item sm={5} xs={12}>
                  <Box component="span" display="flex" justifyContent="flex-end">
                    <Button
                      size="small"
                      sx={{ width: "130px", marginLeft: 4 }}
                      variant="contained"
                      color="warning"
                      disabled={projectDetails?.header ? false : true}
                      startIcon={<CloudDownload />}
                      onClick={() => { handleReportDownload(false) }}
                    >
                      Download
                    </Button>
                    <Button
                      size="small"
                      sx={{ width: "100px", marginLeft: 2 }}
                      variant="contained"
                      color="primary"
                      disabled={projectDetails?.header ? false : true}
                      startIcon={<Print />}
                      onClick={() => { handleReportDownload(true) }}
                    >
                      Print
                    </Button>
                  </Box>
                </Grid>

              </>}

          </Grid>}

        {/* table */}
        {
          filteredTableData && Object.keys(filteredTableData).length > 0 &&
          <Card sx={{ padding: 3 }}>

            <div style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px" }}>
              <div id="header" style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px", paddingLeft: "100px", paddingRight: "100px" }}>
                <div style={{ width: "100%", display: "flex", justifyContent: "center", gap: "20px", alignItems: "center" }}>
                  <img id="headerLogo" src={`${ServiceUrl?.showImageUrl}${projectDetails?.header}`} style={{ height: "150px", width: "auto" }} alt="img" />

                </div>

                <Grid item xs={12} align='center' >
                  <FormLabel sx={{ fontSize: 22 }} ><b>RATE ANALYSIS</b></FormLabel>
                </Grid>

                <Grid container >
                  <Grid item xs={12} sx={{ marginTop: -2 }}>
                    <FormLabel sx={{ fontSize: 18 }}><b>Project Location: </b>{state?.project_location}</FormLabel>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: 2 }}>
                    <FormLabel sx={{ fontSize: 18 }}><b>Project Name: </b>{state?.project_name}</FormLabel>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: 2 }}>
                    <FormLabel sx={{ fontSize: 18 }}><b>Project ID: </b>{state?.project_id}</FormLabel>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: 2 }}>
                    <FormLabel sx={{ fontSize: 18 }}><b>BSR Year: </b>{state?.bsr_year}</FormLabel>
                  </Grid>

                </Grid>

              </div>

              <div id="tableData">
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead style={{ backgroundColor: '#87CEEB' }}>
                      <TableRow>
                        <TableCell>Code</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Unit</TableCell>
                        <TableCell>Rate (Nu)</TableCell>
                        <TableCell colSpan={2}>Amount (Nu)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      {filteredTableData && filteredTableData?.chapter_name &&

                        <TableRow>
                          <TableCell colSpan={6} align="center"><b>{filteredTableData?.chapter_name}</b></TableCell>
                        </TableRow>
                      }
                      {
                        filteredTableData && filteredTableData?.data?.length > 0 && filteredTableData?.data?.map((subItem) => {
                          return (
                            <> <TableRow>
                              <TableCell ><b>{subItem?.subChapter?.code}</b></TableCell>
                              <TableCell colSpan={5}><b>{subItem?.subChapter?.description}</b></TableCell>
                            </TableRow>
                              <TableRow>
                                <TableCell ></TableCell>
                                <TableCell ><b>Unit: {subItem?.subChapter?.unit_name}</b></TableCell>
                              </TableRow>

                              {
                                subItem?.subChapterList?.length > 0 && subItem?.subChapterList?.map((item, itemIndex) => {
                                  return (
                                    <TableRow>
                                      <TableCell >{item?.code}</TableCell>
                                      <TableCell >{item?.description}</TableCell>
                                      <TableCell >{item?.quantity}</TableCell>
                                      <TableCell >{item?.unit}</TableCell>
                                      <TableCell align="right">{formatNumberWithCommasAndDecimals(item?.rate)}</TableCell>
                                      <TableCell align="right">{formatNumberWithCommasAndDecimals(item?.amount)}</TableCell>
                                    </TableRow>
                                  )
                                })
                              }

                              <TableRow>
                                <TableCell colSpan={5} sx={{ fontWeight: 600 }} align="right"><b>Sub Total Amount</b></TableCell>
                                <TableCell sx={{ fontWeight: 600 }} align="right">
                                  <b>{formatNumberWithCommasAndDecimals(subItem?.subTotal)}</b>
                                </TableCell>
                              </TableRow>
                              {
                                subItem?.charges?.length > 0 && subItem?.charges?.map((charge) => {
                                  return (
                                    <TableRow>
                                      <TableCell colSpan={charge?.percent ? 4 : 5} align="right">{charge?.percent ? charge?.charges : <b>{charge?.charges}</b>}</TableCell>
                                      {charge?.percent && <TableCell >{charge?.percent}%</TableCell>}
                                      <TableCell align="right">{charge?.percent ? formatNumberWithCommasAndDecimals(charge?.amount) : <b>{formatNumberWithCommasAndDecimals(charge?.amount)}</b>}</TableCell>
                                    </TableRow>
                                  )
                                })
                              }
                              <TableRow>
                                <TableCell colSpan={5} sx={{ fontWeight: 600 }} align="right"><b>Total Amount</b></TableCell>
                                <TableCell align="right">
                                  <b>{formatNumberWithCommasAndDecimals(subItem?.total)}</b>
                                </TableCell>
                              </TableRow>
                            </>
                          )
                        })
                      }




                    </TableBody>
                  </Table>
                </TableContainer>

              </div>
            </div>
          </Card>}

      </div>
      {loading && (
        <Loader
          show={loading}
        />
      )}
      {(isPrinting || isDownloading) && (
        <Loader
          show={isPrinting ? isPrinting : isDownloading}
          text="Processing..."
        />
      )}

    </Container>

  )
}