import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { CloudDownload, Print } from "@mui/icons-material";
import { Box, Button, Card, FormControlLabel, FormLabel, Grid, Icon, MenuItem, Radio, RadioGroup, styled } from "@mui/material";
import { Loader } from "app/components";
import { getAllProjectLocationListForReports } from "app/redux/actions/ProjectLocationActions";
import { getProjectBoqContractReport, getProjectBOQReport } from "app/redux/actions/ReportsAction";
import { AppConstants } from "app/utils/AppConstants";
import { ServiceUrl } from 'app/utils/Constants';
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import { checkValidation, formatNumberWithCommasAndDecimals, getTwoDigitDecimal } from "app/utils/utils";
import { SimpleCard } from "components";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";


const styles = {
  tableCell: {
    textAlign: "center"
  },
  subHeader: {
    textAlign: "center",
    fontWeight: 700
  }

}
const DecoratedTextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));




export const ProjectBoQReport = () => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [reportType, setReportType] = useState('report')
  const [isDownloading, setIsDownloading] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)
  const [projectDetails, setProjectDetails] = useState()
  const [totalAmount, setTotalAmount] = useState()
  const { reportsProjectLocationList } = useSelector((state) => state.projectLocation);
  const [boqType, setBoqType] = useState("project")
  const [tableData, setTableData] = useState([])
    ;
  const [state, setState] = useState({
    project_location_id: '',
  })


  useEffect(() => {
    dispatch(getAllProjectLocationListForReports(showLoader, hideLoader, showToast))
  }, []);
  const showLoader = () => {
    setLoading(true)
  }
  const showToast = (msg) => {
    const key = enqueueSnackbar(msg ?? AppConstants.somethingWentWrong, { variant: 'error' });
    SnackBarProperty.error(key, closeSnackbar);
  }

  const hideLoader = () => {
    setLoading(false)
  }
  const handleChange = (event, source) => {
    setTableData([])
    if (event.target.name === "project_location_id") {
      const filteredArray = reportsProjectLocationList.filter(project => {
        return project.project_location_id === event.target.value
      });
      state.project_name = filteredArray[0].project_name
      state.project_location_id = filteredArray[0].project_location_id
      state.project_id = filteredArray[0].project_id

      setState({ ...state, [event.target.name]: event.target.value });

    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  }


  const handleFormSubmit = () => {
    console.log('boqtype', boqType)
    if (boqType === 'contract') {
      getContractProjectBoQReport()
    } else {
      getProjectBoQReport()
    }
  }

  const getProjectBoQReport = () => {
    setLoading(true)
    setTableData([])
    getProjectBOQReport({ project_location_id: state?.project_location_id })
      .then((res) => {
        setLoading(false)
        if (res?.status < 300) {
          if (res?.data?.obj && res?.data?.success) {
            if (res?.data?.obj && res?.data?.obj?.item && res?.data?.obj?.item?.construcion_type?.length > 0) {
              if (reportType === "report") {
                const tableDataList = res?.data?.obj?.item?.construcion_type?.map((elem) => {
                  const construcion_type = elem?.construcion_type
                  const dataList = elem?.item_list?.map((item, index) => {

                    return {
                      sl_no: index + 1,
                      bsr_ref_no: item?.item_code,
                      item_description: item?.item_description,
                      quantity: item?.quantity,
                      unit: item?.unit_name,
                      rate: item?.bsr_rate,
                      amount: parseFloat(item?.amount).toFixed(2),
                      workcharge_amount: item?.workcharge_percent ?? 0,
                      cont_percentage: item?.contingency_percentage ?? 0,
                      cont_amount: item?.contingency_amount ?? 0,
                    }
                  })
                  const obj = {
                    constructionType: construcion_type,
                    data: dataList
                  }
                  return obj
                })
                const TotalAmount = tableDataList.reduce((accumulator, constructionTypeData) => {
                  const constructionTypeTotal = constructionTypeData.data.reduce((subAccumulator, itemData) => {
                    return subAccumulator + parseFloat(itemData.amount);
                  }, 0);

                  return accumulator + constructionTypeTotal;
                }, 0);

                setTotalAmount(parseFloat(TotalAmount).toFixed(2))
                setTableData(tableDataList)
              }
              else {

                const filteredArray = mergeByConstructionType(res?.data?.obj?.item?.construcion_type)
                setTableData(filteredArray)
                const grandTotal = filteredArray.reduce((sum, item) => sum + parseFloat(item.total), 0);
                setTotalAmount(parseFloat(grandTotal).toFixed(2))
              }

              setProjectDetails({
                company: res?.data?.obj?.item?.project_location,
                project_id: res?.data?.obj?.item?.project_id,
                tenderId: res?.data?.obj?.item?.tender_id,
                project_name: res?.data?.obj?.item?.project_name,
                header: res?.data?.obj?.header_logo,
                footer: res?.data?.obj?.footer_logo,
                workcharge_amount: res?.data?.obj?.item?.construcion_type?.[0]?.item_list?.[0]?.workcharge_percent ?? 0,
                cont_percentage: res?.data?.obj?.item?.construcion_type?.[0]?.item_list?.[0]?.contingency_percentage ?? 0,
                cont_amount: res?.data?.obj?.item?.construcion_type?.[0]?.item_list?.[0]?.contingency_amount ?? 0


              })
            }
          }
          else {
            const key = enqueueSnackbar(res?.data?.message, { variant: 'info' });
            SnackBarProperty.info(key, closeSnackbar);
          }
        }
        else {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        }
      }).catch(error => {
        setLoading(false)
        const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      })
  }

  const getContractProjectBoQReport = () => {
    setLoading(true)
    setTableData([])
    getProjectBoqContractReport({ project_location_id: state?.project_location_id }).then((res) => {
        setLoading(false)
        if (res?.status < 300) {
          if (res?.data?.obj && res?.data?.success) {
            if (res?.data?.obj && res?.data?.obj?.item && res?.data?.obj?.item?.construcion_type?.length > 0) {
                const tableDataList = res?.data?.obj?.item?.construcion_type?.map((elem) => {
                  const construcion_type = elem?.construcion_type
                  const dataList = elem?.item_list?.map((item, index) => {
                    return {
                      sl_no: index + 1,
                      bsr_ref_no: item?.item_code,
                      item_description: item?.item_description,
                      quantity: item?.quantity,
                      unit: item?.unit_name,
                      rate: item?.contract_rate,
                      amount: getTwoDigitDecimal(item?.amount),
                      workcharge_amount: item?.workcharge_percent ?? 0,
                      cont_percentage: item?.contingency_percentage ?? 0,
                      cont_amount: item?.contingency_amount ?? 0,
                    }
                  })
                  const obj = {
                    constructionType: construcion_type,
                    data: dataList
                  }
                  return obj
                })
                const TotalAmount = tableDataList.reduce((accumulator, constructionTypeData) => {
                  const constructionTypeTotal = constructionTypeData?.data?.reduce((subAccumulator, itemData) => {
                    return subAccumulator + getTwoDigitDecimal(itemData?.amount);
                  }, 0);
                  return accumulator + constructionTypeTotal;
                }, 0);

                setTotalAmount(getTwoDigitDecimal(TotalAmount))
                setTableData(tableDataList)

              setProjectDetails({
                company: res?.data?.obj?.item?.project_location,
                project_id: res?.data?.obj?.item?.project_id,
                tenderId: res?.data?.obj?.item?.tender_id,
                project_name: res?.data?.obj?.item?.project_name,
                header: res?.data?.obj?.header_logo,
                footer: res?.data?.obj?.footer_logo,
                workcharge_amount: res?.data?.obj?.item?.construcion_type?.[0]?.item_list?.[0]?.workcharge_percent ?? 0,
                cont_percentage: res?.data?.obj?.item?.construcion_type?.[0]?.item_list?.[0]?.contingency_percentage ?? 0,
                cont_amount: res?.data?.obj?.item?.construcion_type?.[0]?.item_list?.[0]?.contingency_amount ?? 0
              })
            }
          }
          else {
            const key = enqueueSnackbar(res?.data?.message, { variant: 'info' });
            SnackBarProperty.info(key, closeSnackbar);
          }
        }
        else {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        }
      }).catch(error => {
        setLoading(false)
        const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      })
  }

  function mergeByConstructionType(data) {
    const result = data.reduce((acc, curr) => {
      const constructionType = curr.construcion_type;
      const totalAmount = curr.item_list.reduce((sum, item) => sum + item.amount, 0);

      // Create a new object with construction_type and total
      acc.push({ description: `Total for ${constructionType} (Nu)`, total: parseFloat(totalAmount).toFixed(2) });
      return acc;
    }, []);

    return result;
  }

 

  const handleReportDownload = (isPrint) => {
    if (isPrint) {
      setIsPrinting(true)
      setIsDownloading(false)
    }
    else {
      setIsPrinting(false)
      setIsDownloading(true)
    }
    const doc = new jsPDF();
    const contentHeight = 150;
    const headerImage = document.getElementById("headerLogo"); // Assuming you have an element with id "header" for the header image

    doc.setFontSize(12); // Set font size


    html2canvas(headerImage, {
      useCORS: true, // Add this line if the image is from a different domain
      allowTaint: true, // Add this line if the image is from a different domain
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      // A4 dimensions in mm
      const pdfWidth = 210;
      const pdfHeight = 297;

      // Calculate aspect ratio
      const aspectRatio = imgWidth / imgHeight;

      // Calculate scaled dimensions to fit within A4 while maintaining aspect ratio
      let scaledWidth = pdfWidth;
      let scaledHeight = pdfWidth / aspectRatio;

      if (scaledHeight > pdfHeight) {
        scaledHeight = pdfHeight;
        scaledWidth = pdfHeight * aspectRatio;
      }
      // Insert the header image into the PDF
      const topBorderPadding = 10; // Adjust the padding value as needed
      doc.setDrawColor(255, 255, 255); // Black color for border
      doc.setLineWidth(topBorderPadding); // Border width (padding)

      // Draw the top border (padding)
      doc.line(0, topBorderPadding / 2, pdfWidth, topBorderPadding / 2);

      // Insert the header image into the PDF
      doc.addImage(imgData, "PNG", 0, 0, scaledWidth, scaledHeight);
      const textX = 15;
      const textY = scaledHeight + 10;

      doc.setFont('helvetica', 'bold');
      doc.setFontSize(12);
      doc.text("PROJECT BOQ REPORT", (doc.internal.pageSize.getWidth() / 2) - ((doc.getStringUnitWidth("PROJECT BOQ REPORT") * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2), textY);
      // Add the text content below the header image
      doc.setFont('helvetica', 'bold'); // Set font to bold
      doc.setFontSize(8); // Set font size
      const textWidth = doc.getStringUnitWidth("Project Location:") * doc.internal.getFontSize() / doc.internal.scaleFactor;

      doc.text("Project Name:", textX, textY + 10);
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.project_name, textX + textWidth + 5, textY + 10);
      doc.setFont('helvetica', 'bold');
      doc.text("Project Location:", textX, textY + 15);
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.company ?? "", textX + textWidth + 5, textY + 15);
      doc.setFont('helvetica', 'bold');
      doc.text("Project ID:", textX, textY + 20);
      doc.setFont('helvetica', 'normal');
      doc.text(`${projectDetails?.project_id}`, textX + textWidth + 5, textY + 20);

      const headerSectionHeight = scaledHeight + 35; // Adjust the value as needed

      // Calculate the starting Y-coordinate for the table
      const tableStartY = headerSectionHeight + 5;
      doc.setFont('helvetica', 'normal');

      function transformData(data) {
        const transformedData = [];
        data.forEach((section) => {
          // Create a row for the section header value (spanning across all 8 columns)
          const headerRow = [{ content: section.constructionType, colSpan: reportType === "report" && boqType !== "tender" ? 7 : 8, styles: { fontStyle: "bold", fontSize: 8 } }];
          transformedData.push(headerRow);

          // Loop through the data of the section and create rows for each object in the data
          section.data.forEach((row, index) => {
            if (boqType === "tender") {
              row.rate = ""
              row.amount = ""
            }
            console.log(`row ${index}:`, row)
            const rowData = Object.values(row); // Extract values of each object

            transformedData.push([
              { content: index + 1, styles: { fontSize: 8 } },
              { content: row?.bsr_ref_no ?? "", styles: { fontSize: 8 } },
              { content: row?.item_description ?? "", colSpan: reportType === "report" && boqType !== "tender" ? 2 : 1, styles: { fontSize: 8 } },
              { content: row?.quantity ?? "", styles: { fontSize: 8 } },
              { content: row?.unit ?? "", styles: { fontSize: 8 } },
              { content: row?.rate ?? "", styles: { fontSize: 8 } },
              { content: row?.amount ?? "", styles: { fontSize: 8 } },
            ]);
            console.log("transformed data:", transformData)
          });

        });

        return transformedData;
      }
      const transformedData = transformData(tableData);
      if (boqType !== "tender") {
        transformedData.push([{ content: "Sub-total amount (Nu):", colSpan: reportType === "report" ? 7 : 6, styles: { halign: "right", fontStyle: "bold", fontSize: 8 } }, { content: formatNumberWithCommasAndDecimals(totalAmount), styles: { halign: "right", fontStyle: "bold", fontSize: 8 } }])
        transformedData.push([
          { content: "Work Charge Amount (Nu):", colSpan: 7, styles: { halign: "right", fontStyle: "bold", fontSize: 8 } },
          { content: formatNumberWithCommasAndDecimals(parseFloat(projectDetails?.workcharge_amount ?? 0)?.toFixed(2)), styles: { halign: "right", fontSize: 8 } }
        ])
        transformedData.push([
          { content: `Contingency Percentage (%): ${parseFloat(projectDetails?.cont_percentage ?? 0)?.toFixed(2)}`, colSpan: 4, styles: { halign: "left", fontStyle: "bold", fontSize: 8 } },
          // { content: formatNumberWithCommasAndDecimals(parseFloat(projectDetails?.cont_percentage??0)?.toFixed(2)), styles: { halign: "left"}},
          { content: "Contingency Amount (Nu):", colSpan: 3, styles: { halign: "right", fontStyle: "bold", fontSize: 8 } },
          { content: formatNumberWithCommasAndDecimals(parseFloat(projectDetails?.cont_amount ?? 0)?.toFixed(2)), styles: { halign: "right", fontSize: 8 } },
        ])
        transformedData.push([
          { content: "Sub-Total Amount (Nu):", colSpan: 7, styles: { halign: "right", fontStyle: "bold", fontSize: 8 } },
          { content: formatNumberWithCommasAndDecimals(parseFloat((projectDetails?.workcharge_amount ?? 0) + (projectDetails?.cont_amount ?? 0))?.toFixed(2)), styles: { halign: "right", fontStyle: "bold", fontSize: 8 } }
        ])
        if (reportType === "report") {
          transformedData.push([{ content: "Total Amount (Nu)", colSpan: reportType === "report" ? 7 : 6, styles: { halign: "right", fontStyle: "bold", fontSize: 8 } }, { content: formatNumberWithCommasAndDecimals(parseFloat((parseFloat((projectDetails?.workcharge_amount ?? 0) + (projectDetails?.cont_amount ?? 0))) + parseFloat(totalAmount))?.toFixed(2)), styles: { halign: "right", fontStyle: "bold", fontSize: 8 } }])
        }
      }


      doc.autoTable({
        startY: tableStartY,
        head: [[
          { content: "SL NO.", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "ITEM CODE", colSpan: 1, styles: { valign: "middle", halign: "left", cellPadding: 2, fontSize: 8 } },
          { content: "ITEM DESCRIPTION", colSpan: reportType === "report" && boqType !== "tender" ? 2 : 1, styles: { valign: "middle", halign: "left", cellPadding: 2, fontSize: 8 } },
          { content: "QUANTITY", colSpan: 1, styles: { valign: "middle", halign: "right", cellPadding: 2, fontSize: 8 } },
          { content: "UNIT", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "RATE (Nu)", colSpan: 1, styles: { valign: "middle", halign: "right", cellPadding: 2, fontSize: 8 } },
          { content: "AMOUNT (Nu)", colSpan: 1, styles: { valign: "middle", halign: "right", cellPadding: 2, fontSize: 8 } }
        ]],
        body: transformedData, // Use the transformed data here
        theme: "grid",
        styles: {
          fillColor: [255, 255, 255], // Remove background color from the table
          lineColor: [0, 0, 0], // Set border color for main table
          lineWidth: 0.1, // Set border width for main table
          textColor: [0, 0, 0], // Set text color to black
          cellPadding: 2, // Add padding to cells
        },
        columnStyles: {
          2: { cellWidth: 50 }, // "ITEM DESCRIPTION" column width
          4: { halign: "right" },
          5: { halign: "left" }, // "RATE" column width
          6: { halign: "right" }, // "AMOUNT" column width
          7: { halign: "right" }, // "AMOUNT" column width

        },
      });

      // Function to add footer on each page
      function addFooter(pageNumber) {
        // const footerText = "Hello CMS User";
        // const pageString = "Page " + pageNumber + " of " + doc.internal.getNumberOfPages();
        const footerX = 0; // Starting X position for the footer
        const footerY = doc.internal.pageSize.getHeight() - 10; // 10 units from the bottom
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = 10;
        // const availableWidth = pdfWidth - 2 * footerX;

        // doc.setTextColor(0, 0, 0); // Set text color to black
        // doc.setFontSize(10); // Set font size for the footer

        // // Calculate the width of the text and page numbers
        // const textWidth = doc.getStringUnitWidth(footerText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        // const pageWidth = doc.getStringUnitWidth(pageString) * doc.internal.getFontSize() / doc.internal.scaleFactor;

        // // Calculate the space needed for equal spacing
        // const spaceWidth = (availableWidth - textWidth - pageWidth) / 2;

        // // Add footer text and page numbers in the same row, justified with space between them
        // doc.text(footerX, footerY, footerText);
        // doc.text(footerX + spaceWidth + textWidth, footerY, pageString);
        doc.addImage(`${ServiceUrl?.showImageUrl}${projectDetails?.footer}`, "PNG", footerX, footerY, pdfWidth, pdfHeight, 'FAST')
      }

      // Get the total number of pages
      const totalNumPages = doc.internal.getNumberOfPages();

      // Loop through each page to add the footer with page numbers
      for (let i = 1; i <= totalNumPages; i++) {
        doc.setPage(i);
        addFooter(i);
      }

      const scaleFactor = 1.0; // Adjust this value as needed
      const pdfBlob = doc.output('blob', { scale: scaleFactor });

      // Create a URL for the Blob
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Open a new window with the PDF URL

      if (isPrint) {
        const newWindow = window.open(pdfUrl, '_blank');

        // After the new window is opened, you can close the URL object
        URL.revokeObjectURL(pdfUrl);
        if (newWindow) {
          newWindow.onload = () => {
            setIsPrinting(false)
            setIsDownloading(false)
            // Wait for the window to load and then trigger the print dialog
            newWindow.print();
          };
        }
      }

      setIsPrinting(false)
      setIsDownloading(false)

      isPrint === false && doc.save('ProjectBoQ_Report.pdf');
    });
  };

  const handleReportSummaryDownload = (isPrint) => {
    if (isPrint) {
      setIsPrinting(true)
      setIsDownloading(false)
    }
    else {
      setIsPrinting(false)
      setIsDownloading(true)
    }
    const doc = new jsPDF();
    const contentHeight = 150;
    const headerImage = document.getElementById("headerLogo"); // Assuming you have an element with id "header" for the header image

    doc.setFontSize(12); // Set font size


    html2canvas(headerImage, {
      useCORS: true, // Add this line if the image is from a different domain
      allowTaint: true, // Add this line if the image is from a different domain
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");


      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      // A4 dimensions in mm
      const pdfWidth = 210;
      const pdfHeight = 297;

      // Calculate aspect ratio
      const aspectRatio = imgWidth / imgHeight;

      // Calculate scaled dimensions to fit within A4 while maintaining aspect ratio
      let scaledWidth = pdfWidth;
      let scaledHeight = pdfWidth / aspectRatio;

      if (scaledHeight > pdfHeight) {
        scaledHeight = pdfHeight;
        scaledWidth = pdfHeight * aspectRatio;
      }
      // Insert the header image into the PDF
      const topBorderPadding = 10; // Adjust the padding value as needed
      doc.setDrawColor(255, 255, 255); // Black color for border
      doc.setLineWidth(topBorderPadding); // Border width (padding)

      // Draw the top border (padding)
      doc.line(0, topBorderPadding / 2, pdfWidth, topBorderPadding / 2);

      // Insert the header image into the PDF
      doc.addImage(imgData, "PNG", 0, 0, scaledWidth, scaledHeight);
      const textX = 15;
      const textY = scaledHeight + 10;

      // Add the text content below the header image
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(12);
      doc.text("PROJECT BOQ SUMMARY REPORT", (doc.internal.pageSize.getWidth() / 2) - ((doc.getStringUnitWidth("PROJECT BOQ SUMMARY REPORT") * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2), textY);
      const textWidth = doc.getStringUnitWidth("Project Name:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
      doc.setFont('helvetica', 'bold'); // Set font to bold
      doc.setFontSize(8); // Set font size
      doc.text("Project Name:", textX, textY + 10);
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.project_name, textX + textWidth + 5, textY + 10);
      doc.setFont('helvetica', 'bold');
      doc.text("Project Location:", textX, textY + 15);
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.company ?? "", textX + textWidth + 5, textY + 15);
      doc.setFont('helvetica', 'bold');
      doc.text("Project ID:", textX, textY + 20);
      doc.setFont('helvetica', 'normal');
      doc.text(`${projectDetails?.project_id}`, textX + textWidth + 5, textY + 20);
      const headerSectionHeight = scaledHeight + 35; // Adjust the value as needed

      // Calculate the starting Y-coordinate for the table
      const tableStartY = headerSectionHeight + 5;



      const data = tableData.map((item, index) => [
        { content: index + 1, styles: { fontSize: 8 } },
        { content: item?.description, colSpan: 6, styles: { fontSize: 8 } },
        { content: formatNumberWithCommasAndDecimals(item?.total), styles: { halign: "right", fontSize: 8 } }
      ]);
      // data.push([{ content: "Sub-Total Amount (Nu)", colSpan: 2, styles: { halign: "right", fontStyle: "bold" } }, { content: formatNumberWithCommasAndDecimals(totalAmount), styles: { halign: "right", fontStyle: "bold" } }])

      data.push([{ content: "Sub-Total Amount (Nu):", colSpan: 7, styles: { halign: "right", fontStyle: "bold", fontSize: 8 } }, { content: formatNumberWithCommasAndDecimals(totalAmount), styles: { halign: "right", fontStyle: "bold", fontSize: 8 } }])
      data.push([
        { content: "Work Charge Amount (Nu):", colSpan: 7, styles: { halign: "right", fontStyle: "bold", fontSize: 8 } },
        { content: formatNumberWithCommasAndDecimals(parseFloat(projectDetails?.workcharge_amount ?? 0)?.toFixed(2)), styles: { halign: "right", fontSize: 8 } }
      ])
      data.push([
        { content: `Contingency Percentage (%): ${formatNumberWithCommasAndDecimals(parseFloat(projectDetails?.cont_percentage ?? 0)?.toFixed(2))}`, colSpan: 1, styles: { halign: "left", fontStyle: "bold", fontSize: 8 } },
        // { content: formatNumberWithCommasAndDecimals(parseFloat(projectDetails?.cont_percentage??0)?.toFixed(2)), styles: { halign: "left"}},
        { content: "Contingency Amount (Nu):", colSpan: 6, styles: { halign: "right", valign: "middle", fontStyle: "bold", fontSize: 8 } },
        { content: formatNumberWithCommasAndDecimals(parseFloat(projectDetails?.cont_amount ?? 0)?.toFixed(2)), styles: { halign: "right", fontSize: 8 } },
      ])
      data.push([
        { content: "Sub-Total Amount (Nu):", colSpan: 7, styles: { halign: "right", fontStyle: "bold", fontSize: 8 } },
        { content: formatNumberWithCommasAndDecimals(parseFloat((projectDetails?.workcharge_amount ?? 0) + (projectDetails?.cont_amount ?? 0))?.toFixed(2)), styles: { halign: "right", fontStyle: "bold", fontSize: 8 } }
      ])

      data.push([{ content: "Total Amount (Nu):", colSpan: 7, styles: { halign: "right", fontStyle: "bold", fontSize: 8 } }, { content: formatNumberWithCommasAndDecimals(parseFloat((parseFloat((projectDetails?.workcharge_amount ?? 0) + (projectDetails?.cont_amount ?? 0))) + parseFloat(totalAmount))?.toFixed(2)), styles: { halign: "right", fontStyle: "bold", fontSize: 8 } }])

      doc.autoTable({
        head: [[
          { content: "Sl No.", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "DESCRIPTION", colSpan: 6, styles: { valign: "middle", halign: "left", cellPadding: 2, fontSize: 8 } },
          { content: "TOTAL AMOUNT (Nu)", colSpan: 1, styles: { valign: "middle", halign: "right", cellPadding: 2, fontSize: 8 } },
        ]],
        columnStyles: {// Width for Construction Name
          0: { cellWidth: 40 },
          1: { cellWidth: 95 }   // Width for Construction ID
        },
        body: data,
        startY: tableStartY,
        theme: "grid",
        styles: {

          fillColor: [255, 255, 255], // Remove background color from the table
          lineColor: [0, 0, 0], // Set border color for main table
          lineWidth: 0.1, // Set border width for main table
          textColor: [0, 0, 0], // Set text color to black
          cellPadding: 2, // Add padding to cells
        },
      });

      // Function to add footer on each page
      function addFooter(pageNumber) {
        // const footerText = "Hello CMS User";
        // const pageString = "Page " + pageNumber + " of " + doc.internal.getNumberOfPages();
        const footerX = 0; // Starting X position for the footer
        const footerY = doc.internal.pageSize.getHeight() - 10; // 10 units from the bottom
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = 10;
        // const availableWidth = pdfWidth - 2 * footerX;

        // doc.setTextColor(0, 0, 0); // Set text color to black
        // doc.setFontSize(10); // Set font size for the footer

        // // Calculate the width of the text and page numbers
        // const textWidth = doc.getStringUnitWidth(footerText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        // const pageWidth = doc.getStringUnitWidth(pageString) * doc.internal.getFontSize() / doc.internal.scaleFactor;

        // // Calculate the space needed for equal spacing
        // const spaceWidth = (availableWidth - textWidth - pageWidth) / 2;

        // // Add footer text and page numbers in the same row, justified with space between them
        // doc.text(footerX, footerY, footerText);
        // doc.text(footerX + spaceWidth + textWidth, footerY, pageString);
        doc.addImage(`${ServiceUrl?.showImageUrl}${projectDetails?.footer}`, "PNG", footerX, footerY, pdfWidth, pdfHeight, 'FAST')
      }

      // Get the total number of pages
      const totalNumPages = doc.internal.getNumberOfPages();

      // Loop through each page to add the footer with page numbers
      for (let i = 1; i <= totalNumPages; i++) {
        doc.setPage(i);
        addFooter(i);
      }



      const scaleFactor = 1.0; // Adjust this value as needed
      const pdfBlob = doc.output('blob', { scale: scaleFactor });

      // Create a URL for the Blob
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Open a new window with the PDF URL

      if (isPrint) {
        const newWindow = window.open(pdfUrl, '_blank');

        // After the new window is opened, you can close the URL object
        URL.revokeObjectURL(pdfUrl);
        if (newWindow) {
          newWindow.onload = () => {
            setIsPrinting(false)
            setIsDownloading(false)
            // Wait for the window to load and then trigger the print dialog
            newWindow.print();
          };
        }
      }

      setIsPrinting(false)
      setIsDownloading(false)

      isPrint === false && doc.save('ProjectBoQ_Summary_Report.pdf');
    });
  };

  return (
    <>
      <div style={{ height: 'auto', width: "100%", padding: '30px', display: "flex", flexDirection: "column", gap: "20px" }}>

        <Grid container>

          <Grid item xs={12}>
            <Button
              size="small"
              sx={{ width: "100px" }}
              variant="contained"
              color="error"
              onClick={() => { window.history.back() }}
            >
              <Icon color="primary" fontSize="small">
                skip_previous
              </Icon>
              Back
            </Button>
          </Grid>
        </Grid>
        <SimpleCard>
          <ValidatorForm onSubmit={handleFormSubmit}>
            <Grid container spacing={3} style={{ marginTop: '-45px', marginBottom: '15px' }} >
              <Grid item xs={12}><b className="font20 extraBold" style={{ color: "#ebad4e" }}>Project BoQ</b></Grid>
            </Grid>
            <Grid container spacing={3} alignItems={"center"} >
              <Grid item xs={12}>
                <RadioGroup
                  row
                  name="reportType"
                  value={reportType}
                  onChange={(e) => { setReportType(e.target.value); setBoqType("project"); setTableData([]) }}
                >&nbsp;&nbsp;
                  <FormControlLabel
                    label="Project BoQ Report"
                    value="report"
                    control={<Radio size="small" color="secondary" />}
                    sx={{ mr: 3, height: 20 }}
                  />
                  <FormControlLabel
                    label="Project BoQ Report Summary"
                    value="summaryReport"
                    control={<Radio size="small" color="secondary" />}
                    sx={{ height: 20 }}
                  />
                </RadioGroup>

              </Grid>
              <Grid item sm={4} xs={12}>
                <DecoratedTextField
                  select
                  size="small"
                  fullWidth
                  sx={{ mb: 3 }}
                  label="Project ID"
                  id="project_location_id"
                  variant="outlined"
                  name="project_location_id"
                  value={state?.project_location_id}
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={["Project ID is required"]}
                >
                  {reportsProjectLocationList?.map((item) => (
                    <MenuItem value={item?.project_location_id} key={item?.project_id}>
                      {item?.project_id}
                    </MenuItem>
                  ))}
                </DecoratedTextField>
              </Grid>
              {reportType !== "summaryReport" && <Grid item sm={6} xs={12} sx={{ paddingTop: { xs: "0px !important", md: 'auto' } }}>
                <RadioGroup
                  row
                  name="boqType"
                  value={boqType}
                  onChange={(e) => { setBoqType(e.target.value); setTableData([]) }}
                >&nbsp;&nbsp;
                  <FormControlLabel
                    label="Tender BoQ "
                    value="tender"
                    control={<Radio size="small" color="secondary" />}
                    sx={{ mr: 3, height: 20 }}
                  />
                  <FormControlLabel
                    label="Project BoQ"
                    value="project"
                    control={<Radio size="small" color="secondary" />}
                    sx={{ height: 20 }}
                  />
                  <FormControlLabel
                    label="Contract PBoQ"
                    value="contract"
                    control={<Radio size="small" color="secondary" />}
                    sx={{ height: 20 }}
                  />
                </RadioGroup>

              </Grid>}

              <Grid item sm={4} xs={12} sx={{ paddingTop: { xs: "0px !important", md: 'auto' } }}>


                <Button size="medium" color="success" variant="contained" type="submit" style={{ marginLeft: 4 }} >View</Button>

              </Grid>

            </Grid>

          </ValidatorForm>
        </SimpleCard>
        {tableData && tableData?.length > 0 && <Grid container>
          <Grid xs={12} md={6}>
            {checkValidation(projectDetails?.header) === false && <FormLabel sx={{ color: "#ff9800" }} color="warning">! Please upload header and footer for download/print</FormLabel>}
          </Grid>
          <Grid item xs={12} md={6}>
            <Box component="span" display="flex" justifyContent="flex-end">
              <Button
                size="small"
                sx={{ width: "130px", marginLeft: 4 }}
                variant="contained"
                color="warning"
                disabled={projectDetails?.header ? false : true}
                startIcon={<CloudDownload />}
                onClick={() => { reportType === "report" ? handleReportDownload(false) : handleReportSummaryDownload(false) }}
              >
                Download
              </Button>
              <Button
                size="small"
                sx={{ width: "100px", marginLeft: 2 }}
                variant="contained"
                color="primary"
                disabled={projectDetails?.header ? false : true}
                startIcon={<Print />}
                onClick={() => { reportType === "report" ? handleReportDownload(true) : handleReportSummaryDownload(true) }}
              >
                Print
              </Button>
            </Box>
          </Grid>

        </Grid>}
        {tableData && tableData?.length > 0 && <Card sx={{ padding: 3 }}>

          <div style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px" }}>
            <div id="header" style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px", paddingLeft: "100px", paddingRight: "100px" }}>
              <div style={{ width: "100%", display: "flex", justifyContent: "center", gap: "20px", alignItems: "center" }}>
                <img id="headerLogo" src={`${ServiceUrl?.showImageUrl}${projectDetails?.header}`} style={{ height: "150px", width: "auto" }} alt="img" />

              </div>

              <Grid container >
                <Grid item xs={12} align='CENTER' >
                  <FormLabel sx={{ fontSize: 22 }} ><b>{reportType === "report" ? "PROJECT BOQ REPORT" : "PROJECT BOQ SUMMARY REPORT"}</b></FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Project Name: </b>{projectDetails?.project_name}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Project Location: </b>{projectDetails?.company}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Project ID: </b>{projectDetails?.project_id}</FormLabel>
                </Grid>


              </Grid>
            </div>

            <div id="tableData">
              <TableContainer component={Paper}>
                <Table>
                  {reportType === "report" ? <TableHead style={{ backgroundColor: '#87CEEB' }}>
                    <TableRow>
                      <TableCell>Sl No.</TableCell>
                      <TableCell>ITEM CODE</TableCell>
                      <TableCell colSpan={2}>ITEM DESCRIPTION</TableCell>
                      <TableCell>QUANTITY</TableCell>
                      <TableCell>UNIT</TableCell>
                      <TableCell>RATE (Nu)</TableCell>
                      <TableCell>AMOUNT (Nu)</TableCell>
                    </TableRow>
                  </TableHead> :
                    <TableHead style={{ backgroundColor: '#87CEEB' }}>
                      <TableRow>
                        <TableCell align="left">Sl No.</TableCell>
                        <TableCell align="left" colSpan={6}>DESCRIPTION</TableCell>
                        <TableCell align="right">TOTAL AMOUNT (Nu)</TableCell>
                      </TableRow>
                    </TableHead>
                  }
                  <TableBody>
                    {reportType === "report" ?
                      <>
                        {tableData?.map((section) => (
                          <React.Fragment key={section.construcionType}>
                            <TableRow>
                              <TableCell colSpan={boqType !== "tender" ? 8 : 9}><b>{section.constructionType}</b></TableCell>
                            </TableRow>
                            {section?.data?.map((row, index) => (
                              <TableRow key={index}>
                                <TableCell>{row.sl_no}</TableCell>
                                <TableCell>{row.bsr_ref_no}</TableCell>
                                <TableCell colSpan={2}>{row.item_description}</TableCell>
                                <TableCell>{formatNumberWithCommasAndDecimals(row?.quantity)}</TableCell>
                                <TableCell>{row.unit}</TableCell>
                                <TableCell align="right">{boqType === "tender" ? "" : formatNumberWithCommasAndDecimals(row?.rate)}</TableCell>
                                <TableCell align="right">{boqType === "tender" ? "" : formatNumberWithCommasAndDecimals(row?.amount)}</TableCell>
                              </TableRow>
                            ))}


                          </React.Fragment>
                        ))}

                      </> :
                      <>
                        {tableData?.map((section, index) => {
                          return (
                            <TableRow>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell colSpan={6}>{section?.description}</TableCell>
                              <TableCell align="right">{formatNumberWithCommasAndDecimals(section?.total)}</TableCell>
                            </TableRow>
                          )
                        }
                        )
                        }

                      </>
                    }
                    {((reportType === "report" && boqType !== "tender") || reportType === "summaryReport") &&
                      <>
                        <TableRow>
                          <TableCell colSpan={7} align="right" style={{ fontWeight: 600 }}><b>{reportType === "report" ? "Sub-Total amount (Nu)" : "Total Amount (Nu)"}</b></TableCell>
                          <TableCell colSpan={1} align="right" style={{ fontWeight: 600 }}><b>{formatNumberWithCommasAndDecimals(totalAmount)}</b></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={7} align="right"><b>Work Charge Amount (Nu):</b></TableCell>
                          <TableCell align="right">{formatNumberWithCommasAndDecimals(parseFloat(projectDetails?.workcharge_amount)?.toFixed(2))}</TableCell>

                        </TableRow>
                        <TableRow>
                          {/* <TableCell colSpan={3}><b>Contingency Percentage (%): {parseFloat(projectDetails?.cont_percentage ?? 0)?.toFixed(2)}</b></TableCell> */}

                          {/* <TableCell colSpan={4} align="right"><b>Contingency Amount (Nu):</b></TableCell> */}
                          <TableCell colSpan={7} align="right"><b>{`${parseFloat(projectDetails?.cont_percentage ?? 0)?.toFixed(2)}% Contingency Amount (Nu):`}</b></TableCell>
                          <TableCell align="right">{formatNumberWithCommasAndDecimals(parseFloat(projectDetails?.cont_amount)?.toFixed(2))}</TableCell>

                        </TableRow>
                        <TableRow>

                          <TableCell colSpan={7} align="right"><b>Sub-Total Amount(Nu):</b></TableCell>
                          <TableCell align="right"><b>{formatNumberWithCommasAndDecimals(parseFloat((projectDetails?.workcharge_amount ?? 0) + (projectDetails?.cont_amount ?? 0))?.toFixed(2))}</b></TableCell></TableRow>

                      </>

                    }
                    {((reportType === "report" && boqType !== "tender") || reportType === "summaryReport") &&
                      <>
                        <TableRow>
                          <TableCell colSpan={7} align="right" style={{ fontWeight: 600 }}><b>Total Amount (Nu)</b></TableCell>
                          <TableCell colSpan={1} align="right" style={{ fontWeight: 600 }}><b>{formatNumberWithCommasAndDecimals(parseFloat((parseFloat((projectDetails?.workcharge_amount ?? 0) + (projectDetails?.cont_amount ?? 0))) + parseFloat(totalAmount))?.toFixed(2))}</b></TableCell>
                        </TableRow>


                      </>

                    }
                  </TableBody>
                </Table>
              </TableContainer>

            </div>
          </div>
        </Card>}

      </div>
      {loading && (
        <Loader
          show={loading}
        />
      )}
      {(isPrinting || isDownloading) && (
        <Loader
          show={isPrinting ? isPrinting : isDownloading}
          text="Processing...Please wait."
        />
      )}

    </>
  )
}