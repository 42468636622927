
import { AppConstants } from 'app/utils/AppConstants';
import { ServiceUrl } from 'app/utils/Constants';
import { ProjectStatus, UserRole } from 'app/utils/ProjectStatus';
import { exportDataToFile, getCurrentRole } from 'app/utils/utils';
import axios from 'axios';

export const GET_PROJECT_LOCATION_LIST_CONTRACT = 'GET_PROJECT_LOCATION_LIST_CONTRACT';
export const GET_ADVANCE_PAYMENT_REQUEST_LIST = 'GET_ADVANCE_PAYMENT_REQUEST_LIST';
export const GET_ADVANCE_TYPE_LIST = 'GET_ADVANCE_TYPE_LIST';
export const GET_ADVANCE_PAYMENT_RECEIPT_LIST = 'GET_ADVANCE_PAYMENT_RECEIPT_LIST';
export const GET_GOODS_RECEIPT_LIST_VERIFICATION = 'GET_GOODS_RECEIPT_LIST_VERIFICATION';




export const getAllProjectLocationListForContract = (showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(ServiceUrl.getAllProjectLocationListForProjectBOQ + `?role_id=${getCurrentRole()}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_PROJECT_LOCATION_LIST_CONTRACT,
        payload: res.data,
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};
export const getAllProjectLocationListForContractDetails = (showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(ServiceUrl.getAllProjectLocationForContractDetails + `?role_id=${getCurrentRole()}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {

      dispatch({
        type: GET_PROJECT_LOCATION_LIST_CONTRACT,
        payload: res.data,
      });


    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};

export const getAllGoodReceiptListByProjectLocationIdForAdvancePayment = (project_location_id, advancepayment_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  // showLoader()
  return axios.post(`/api/implementation/inventory/${project_location_id}/${advancepayment_id}${ServiceUrl.getAllGoodReceiptListByProjectLocationIdForAdvancePayment}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
  // .then((res) => {
  //   hideLoader()
  //   if (res?.status < 300 && res?.data?.success) {
  //     dispatch({
  //       type: GET_ADVANCE_PAYMENT_RECEIPT_LIST,
  //       payload: res?.data?.obj,
  //     });
  //   } else {
  //     showToast(res?.data?.message)
  //   }
  // }).catch(function (error) {
  //   hideLoader()
  //   showToast(error?.response?.data?.errorMessage ?? error?.message);;
  // })
};

export const getAllGoodReceiptsListByGoodReceiptIds = (params, showLoader, hideLoader, showToast, handleGoodsReceiptSucces) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(ServiceUrl.getAllGoodReceiptsListByGoodReceiptIds, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300 && res?.data?.success) {
      dispatch({
        type: GET_GOODS_RECEIPT_LIST_VERIFICATION,
        payload: res?.data?.obj,
      });
      handleGoodsReceiptSucces()
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};

export const getAllGoodReceiptsListByGoodReceiptIdsManually = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.getAllGoodReceiptsListByGoodReceiptIds, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAllAdvancePaymentListByProjectLocationIdOrStatus = (project_location_id, status_id, handleAdvancePaymentSuccess, showLoader, hideLoader, showToast, isVerification = false) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(`/api/implementation/advancePayment/${project_location_id}/${status_id}${ServiceUrl.getAllAdvancePaymentListByProjectLocationIdOrStatus}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      if (isVerification === true) {
        dispatch({
          type: GET_ADVANCE_PAYMENT_REQUEST_LIST,
          payload: res?.data?.filter((e) => {
            return e.status_id === ProjectStatus.SUBMITTED || e.status_id === ProjectStatus.REVIEWED || e.status_id === ProjectStatus.RECOMMENDED || e.status_id === ProjectStatus.APPROVED
          }),
        });
      } else {
        dispatch({
          type: GET_ADVANCE_PAYMENT_REQUEST_LIST,
          payload: res?.data,
        });
      }

      handleAdvancePaymentSuccess()
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};


export const getAllAdvanceTypeList = (showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(ServiceUrl.getAllAdvanceTypeList, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_ADVANCE_TYPE_LIST,
        payload: res.data,
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};

export const saveOrUpdateContractDetails = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateContractDetails, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveOrUpdateWorkPlan = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateWorkPlan, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveOrUpdateContractBOQRate = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateContractBOQRate, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};


export const checkEmailAvailabilityForContractDetails = ({ email, project_location_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.get(`${ServiceUrl.checkEmailAvailabilityForContractDetails}?email=${email}&project_location_id=${project_location_id}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
}

export const getAllContractDetailsList = (project_location_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/project/contractDetails/${project_location_id}${ServiceUrl.getAllContractDetailsList}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
// export const getEGPContractToken = () => {
//   const formData = new URLSearchParams();
//    formData.append('grant_type', "client_credentials");
//    formData.append('client_id', "moit");
//    formData.append('client_secret', "JxXBYj6hkPpUMEdIwk4JXWGzEWSn6Iaa");

//   // const params={
//   //   grant_type:"client_credentials",
//   //   client_id:"moit",
//   //   client_secret:"JxXBYj6hkPpUMEdIwk4JXWGzEWSn6Iaa"
//   // }
//   // const accessToken = window.localStorage.getItem('accessToken')
//   return axios.post(`${ServiceUrl.tokenForEGPContract}`, formData, {
//     headers: {
//       // 'Authorization': `Bearer ${accessToken}`,
//       'Content-Type': 'application/x-www-form-urlencoded',
//     }
//   })
// };
export const getEGPContractDetails = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`${ServiceUrl.getEGPContractDetails}`, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAlRunningAccountBillDetailsListById = (ra_bill_id, project_location_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/runningAccountBill/${ra_bill_id}/${project_location_id}${ServiceUrl.getAlRunningAccountBillDetailsListById}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAllContractBOQRateList = (contractdetails_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/project/contractDetails/${contractdetails_id}${ServiceUrl.getAllContractBOQRateList}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAllCNBoQContractBOQRateList = (project_location_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/project/contractDetails/${project_location_id}${ServiceUrl.getCNBoQContractBOQRateList}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveOrUpdateRABillRateAnalysis = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateRABillRateAnalysis, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const updateRABillRevisedRateByRunningAccountBillDetailsIds = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.updateRABillRevisedRateByRunningAccountBillDetailsIds, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};


export const saveOrUpdateRaBillAmendmentRateAnalysis = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateRaBillAmendmentRateAnalysis, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getRABillRateAnalysis = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.getRABillRateAnalysis, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getRaBillAmendmentRateAnalysis = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.getRaBillAmendmentRateAnalysis, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const approveNotApproveRateAnalysisForRABill = ({ id, status }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`api/implementation/runningAccountBill/${id}/${status}/approveNotApproveRateAnalysisForRABill`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const deleteContractBOQRate = (contractboqrate_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/project/contractDetails/${contractboqrate_id}${ServiceUrl.deleteContractBOQRate}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const deleteAdvanceRecoveryById = (recovery_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/implementation/advancePayment/${recovery_id}${ServiceUrl.deleteRecoveryById}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const deleteAdvancePaymentById = (advancePaymentById) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/implementation/advancePayment/${advancePaymentById}${ServiceUrl.deleteAdvancePaymentById}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAllPhysicalProgressByConstructionlevelOrProjectLevel = (project_location_id, constructionname_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/physicalProgress/${project_location_id}/${constructionname_id}${ServiceUrl.getAllPhysicalProgressByConstructionlevelOrProjectLevel}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const deletePhysicalProgress = (physical_progress_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/implementation/physicalProgress/${physical_progress_id}${ServiceUrl.deletePhysicalProgress}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getLatestPhysicalProgress = (constructionname_id, stageinformation_id, level_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/physicalProgress/${constructionname_id}/${stageinformation_id}/${level_id}${ServiceUrl.getLatestPhysicalProgress}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getProjectBoQDetailsByConstructionName = (constructionname_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/project/projectBOQ/${constructionname_id}${ServiceUrl.getProjectBoQDetailsByConstructionName}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveOrUpdatePhysicalProgress = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdatePhysicalProgress, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveOrUpdatePhysicalProgressAmendment = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdatePhysicalProgressAmendment, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getAllPhysicalProgressAmendmentListByProjectLocationId = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/physicalProgress/${params}${ServiceUrl.getAllPhysicalProgressAmendmentListByProjectLocationId}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAllPhysicalProgressByCriteria = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/physicalProgress${ServiceUrl.getAllPhysicalProgressByCriteria}`, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveOrUpdateAdvancePayment = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateAdvancePayment, params, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data'
    }
  })
};

export const saveOrUpdateRecovery = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateRecovery, params, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};


export const getAllRecoveryListByProjectLocationIdAndPaymentcategoryId = (project_location_id, advancetype_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/advancePayment/${project_location_id}/${advancetype_id}${ServiceUrl.getAllRecoveryListByProjectLocationIdAndPaymentcategoryId}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getAllGoodIssueListByRunningAccountBillIdForAdvanceRecovery = (project_location_id, rabill_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/advancePayment/${project_location_id}/${rabill_id}${ServiceUrl.getAllGoodIssueListByRunningAccountBillIdForAdvanceRecovery}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};


export const getAllRunningAccountBillForRecovery = (project_location_id, statusType, advanceType) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/runningAccountBill/${project_location_id}/${statusType}/${advanceType}${ServiceUrl.getAllRunningAccountBillByProjectLocationId}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getRABillItemsForVerification = (runningaccountbill_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/runningAccountBill/${runningaccountbill_id}${ServiceUrl.getRunningAccountBillDetailsForVerificationList}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAllAdvancePaymentListForRecovery = (project_location_id, handleAdvancePaymentSuccess, showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(`/api/implementation/advancePayment/${project_location_id}${ServiceUrl.getAllAdvancePaymentListForRecovery}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    console.log("responses:", res?.data)
    if (res?.status < 300) {
      dispatch({
        type: GET_ADVANCE_PAYMENT_REQUEST_LIST,
        payload: res?.data?.obj,
      });
      handleAdvancePaymentSuccess()
    } else {
      console.log("else called")
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong);
  })
};

// export const downloadImplementationFile = (file_name) => {
//   const accessToken = window.localStorage.getItem('accessToken')
//   return axios.get(`${ServiceUrl.downloadImplementationFile}${file_name}`, {
//     responseType: 'blob',
//     headers: {
//       'Authorization': `Bearer ${accessToken}`
//     }
//   })
// }

export const downloadImplementationFile = (file_name, download_name, showLoader = false, hideLoader = false, showToast = false) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader && showLoader()
  axios.get(`${ServiceUrl.downloadImplementationFile}${file_name}`, {
    responseType: 'blob',
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader && hideLoader()
    if (res?.status < 300) {
      exportDataToFile(res?.data, download_name, file_name)
    } else {
      showToast && showToast(res?.data?.message ?? AppConstants.somethingWentWrong)
    }
  }).catch(function (error) {
    hideLoader && hideLoader()
    showToast && showToast(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong)
  })
}