import { AppConstants } from 'app/utils/AppConstants';
import { ServiceUrl } from 'app/utils/Constants';
import axios from 'axios';
import { SHOW_LOADING } from './RoleActions';
import { checkValidation, getCurrentRole } from 'app/utils/utils';
import dayjs from 'dayjs';
import { UserRole } from 'app/utils/ProjectStatus';

export const GET_WORK_TYPE_LIST = 'GET_WORK_TYPE_LIST';
export const GET_UNIT_LIST_COST_INDEX = 'GET_UNIT_LIST_COST_INDEX';
export const UPDATE_CURRENT_COST_INDEX_TAB = 'UPDATE_CURRENT_COST_INDEX_TAB';
export const GET_DZONGKHAG_LIST_COST_INDEX = 'GET_DZONGKHAG_LIST_COST_INDEX';
export const GET_GEWOG_LIST_COST_INDEX = 'GET_GEWOG_LIST_COST_INDEX';
export const GET_PROJECT_LOCATION_LIST = 'GET_PROJECT_LOCATION_LIST';
export const GET_CONSTRUCTION_NAME_LIST_BY_PARAMS = "GET_CONSTRUCTION_NAME_LIST_BY_PARAMS"

export const GET_ITEM_DETAILS_LIST = 'GET_ITEM_DETAILS_LIST';
export const GET_ITEM_RATE_WEIGHT_DETAILS_LIST = 'GET_ITEM_RATE_WEIGHT_DETAILS_LIST';
export const GET_BSR_RATE_WEIGHT_DETAILS_LIST = 'GET_BSR_RATE_WEIGHT_DETAILS_LIST'


export const getAllWorkTypeList = (showLoader, hideLoader, showToast, type) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(ServiceUrl.getAllWorkTypeList, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {

    hideLoader()
    if (res?.status < 300) {
      if (type === 'bsr') {
        if (res.data.obj.length > 0) {
          const filteredArray = res.data.obj?.filter(worktype => {
            return !checkValidation(worktype.created_by)
          });
          filteredArray.sort((a, b) => dayjs(b.created_on) - dayjs(a.created_on))
          dispatch({
            type: GET_WORK_TYPE_LIST,
            payload: filteredArray,
          });
        }
      } else if (type === 'user') {
        if (res?.data?.obj?.length > 0) {
          const filteredArray = res?.data.obj?.filter(worktype => {
            return checkValidation(worktype.created_by)
          });
          filteredArray.sort((a, b) => dayjs(b.created_on) - dayjs(a.created_on))
          dispatch({
            type: GET_WORK_TYPE_LIST,
            payload: filteredArray,
          });
        }
      } else {
        var responseArray = res?.data?.obj
        responseArray.sort((a, b) => dayjs(b.created_on) - dayjs(a.created_on))
        dispatch({
          type: GET_WORK_TYPE_LIST,
          payload: res?.data?.obj,
        });
      }
    } else {
      showToast("", res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong);;
  })
};

export const getAllWorkTypeListForConstruction = () => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.getAllWorkTypeList, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
}

export const getAllUnitListForCostIndex = (showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(ServiceUrl.getAllUnitList, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_UNIT_LIST_COST_INDEX,
        payload: res.data,
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};

export const saveUpdateWorkType = (stageInformation) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveUpdateWorkType, stageInformation, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
}


export const deleteWorkTypeById = (worktype_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/project/workType/${worktype_id}${ServiceUrl.deleteWorkTypeById}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const deleteCostIndexWeightageById = (costindexweightage_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/project/workType/${costindexweightage_id}${ServiceUrl.deleteCostIndexWeightageById}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveUpdateCostIndexWeightage = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveUpdateCostIndexWeightage, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};


export const getAllDzongkhagListForCostIndex = (showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(ServiceUrl.getAllDzongkhagList, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_DZONGKHAG_LIST_COST_INDEX,
        payload: res.data,
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};




export const getGewogListByDzongkhagIdForCostIndex = (dzongkhagId, showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(`/api/agency/${dzongkhagId}${ServiceUrl.getGewogListByDzongkhagId}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader !== false && hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_GEWOG_LIST_COST_INDEX,
        payload: res.data,
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader !== false && hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};

export const saveUpdateCostindexRate = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveUpdateCostindexRate, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};


export const getAllCostindexRateByCriteria = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.getAllCostindexRateByCriteria, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};


export const getAllCostIndexRateForDateWise = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/project/workType/${params.worktype_id}/${params.project_location_id}${ServiceUrl.getAllCostIndexRateForDateWise}`, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};


export const getAllConstructionNameListByParamsCostIndex = (constructiontype_id, projectlocation_id, showLoading, hideLoading, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  const roleId = getCurrentRole()
  showLoading()
  axios.post(`/api/project/construction/${constructiontype_id}/${projectlocation_id}/${roleId}${ServiceUrl.getAllConstructionNameList}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoading()
    if (res?.status < 300) {
      dispatch({
        type: GET_CONSTRUCTION_NAME_LIST_BY_PARAMS,
        payload: res.data,
      });
    } else {
      showToast(res?.data?.message)
    }

  }).catch(error => {
    hideLoading()
    showToast(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong)
  });
};

export const fetchItemDetailsByProjectLocationforCostIndex = (projectlocation_id, constructionname_id, year, baselocation_id, showLoading, hideLoading, showToast, handleSuccess) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoading()
  axios.post(`/api/project/projectLocation/${projectlocation_id}/${constructionname_id}/${year}/${baselocation_id}${ServiceUrl.fetchItemDetailsByProjectLocationforCostIndex}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoading()
    if (res?.status < 300) {
      if (res?.data?.length === 0) {
        showToast("info", "No details found")
      }
      dispatch({
        type: GET_ITEM_DETAILS_LIST,
        payload: res?.data,
      });
      handleSuccess()
    } else {
      showToast("error", res?.data?.message)
    }
  }).catch(err => {
    hideLoading()
    showToast(err?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong)
  });
};

export const fetchItemRateWeightDetailsByProjectLocationforCostIndex = (projectlocation_id, worktype_id, showLoading, hideLoading, showToast, handleSuccess) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoading()
  axios.post(`/api/project/projectLocation/${projectlocation_id}/${worktype_id}${ServiceUrl.fetchItemRateWeightDetailsByProjectLocationforCostIndex}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoading()
    if (res?.status < 300) {
      dispatch({
        type: GET_ITEM_RATE_WEIGHT_DETAILS_LIST,
        payload: res?.data,
      });
      handleSuccess()
    } else {
      showToast(res?.data?.message)
    }
  });
};

export const fetchBSRRateWeightDetailsByProjectLocationforCostIndex = (worktype_id, bsr_year, showLoading, hideLoading, showToast, handleSuccess) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoading()
  axios.post(`/api/project/workType/${worktype_id}/${bsr_year}${ServiceUrl.getAllWorkTypeItemsForBSRSample}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoading()
    if (res?.status < 300) {
      dispatch({
        type: GET_BSR_RATE_WEIGHT_DETAILS_LIST,
        payload: res?.data?.obj,
      });
      handleSuccess()
    } else {
      showToast(res?.data?.message)
    }
  });
};

export const getAllProjectLocationListForProjectBoQCostIndex = (showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  dispatch({ type: SHOW_LOADING, payload: true });
  axios.post(ServiceUrl.getAllProjectLocationListForProjectBOQ + `?role_id=${getCurrentRole()}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_PROJECT_LOCATION_LIST,
        payload: res.data,
      });
    } else {
      alert(res?.data?.message)
    }

  }).catch(err => {
    hideLoader()
    showToast(err?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong)
    console.log(err)
  });
};

///workType/saveUpdateCostindexRate


//`/api/project/workType/${costindexweightage_id}${ServiceUrl.deleteCostIndexWeightageById}`

//http://103.2.134.43:9090/api/agency/getAllDzongkhagList
