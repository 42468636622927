import { Box, Card, Grid, Icon, Table, TableBody, TableCell, TableHead, TableRow, TextField, styled } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Loader } from 'app/components';
import { H3, H4 } from "app/components/Typography";
import { saveOrUpdateRaBillAmendmentRateAnalysis } from 'app/redux/actions/ImplementationActions';
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import { DecimalInputField, NumberFormatCustom, checkValidation, currencyFormat, getTwoDigitDecimal } from "app/utils/utils";
import { useSnackbar } from "notistack";
import * as React from 'react';
import { useEffect, useState } from "react";
import '../../../index.css';
import { AppConstants } from "app/utils/AppConstants";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Star = styled("small")(({ theme }) => ({
  color: "#f00",
  overflow: "hidden",
  fontWeight: "bolder",
}));

const MyTable = styled(Table)(() => ({
  "& thead": { "& th": { padding: 5 } },
  "& td": { borderBottom: "none" },
  "& td": { padding: "5px !important" },
}));

export const RABillAmendmentRateDetailsDialog = ({ isUpdated, title, parentRaBill, raBillDetails, raBillRateDetails, rateAnalysisDetails, open, handleClose, handleSuccess }) => {
  const [state, setState] = useState({
    wastage_percentage: "",
    water_percentage: "",
    overhead_percentage: ""
  });
  console.log("ra bill details:", raBillDetails)
  console.log("ra bill rate details:", raBillRateDetails)
  console.log("ra bill rate analysis details:", rateAnalysisDetails)
  const [isLoad, setIsLoad] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [rateDetailsArray, setRateDetailsArray] = useState([])
  const [isPrinting, setIsPrinting] = useState(false)

  const handleChange = (event, source) => {
    ;
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleRateChange = (type, index, value) => {
    const newRateDetailsArray = [...rateDetailsArray]
    newRateDetailsArray[index].rate = value
    setRateDetailsArray(newRateDetailsArray)
  }

  const handleChangePrint = () => {
    isPrinting === false ? setIsPrinting(true) : setIsPrinting(false)
  }
  const handlePrint = () => {


    const printableDiv = document.getElementById('printablediv');
    const printContents = printableDiv.innerHTML;
    const originalContents = document.body.innerHTML;
    const printWindow = window.open('', '_blank');
    const printStyle = `
          /* Custom style for printing the table */
          table {
          border-collapse: collapse;
          width: 100%;
          }

          th, td {
          border: 1px solid #000;
          padding: 8px;
          text-align: left;
          }
          `;
    printWindow.document.write('<html><head><title>Print</title></head><body>');
    printWindow.document.write(`<style>${printStyle}</style>`); // Add the custom print style
    printWindow.document.write(document.getElementById('printablediv').innerHTML);
    printWindow.document.write('</body></html>');
    printWindow.document.close();

    printWindow.onload = () => {
      printWindow.print();
      printWindow.close();
      setIsPrinting(false)

    }
    setIsPrinting(false)
  }

  useEffect(() => {
    if (raBillRateDetails?.length > 0) {
      state.overhead_percentage = raBillRateDetails[0].overhead_percentage
      state.water_percentage = raBillRateDetails[0].water_percentage
      state.wastage_percentage = raBillRateDetails[0].wastage_percentage
    }
    setRateDetailsArray(raBillRateDetails)

  }, [raBillRateDetails]);

  useEffect(() => {
    if (isPrinting === true) {
      handlePrint()
    }

  }, [isPrinting])


  function getTotalAmountForEachItem() {
    var totalAmount = 0
    raBillRateDetails?.forEach((item) => {
      totalAmount += (getTwoDigitDecimal(item?.rate) * getTwoDigitDecimal(item?.coefficient, 5))
    });
    return getTwoDigitDecimal(totalAmount)
  }

  function getTotalAmountForEachBuiltupItem() {
    var totalAmount = 0
    raBillRateDetails?.forEach((item) => {
      if (item?.isBuiltUpItem === true) {
        totalAmount += (getTwoDigitDecimal(item?.rate) * getTwoDigitDecimal(item?.coefficient, 5))
      }
    });
    return getTwoDigitDecimal(totalAmount)
  }

  function getTotalAmountForEachBMLItem() {
    var totalAmount = 0
    raBillRateDetails?.forEach((item) => {
      if (item?.isBuiltUpItem === false) {
        totalAmount += (getTwoDigitDecimal(item?.rate) * getTwoDigitDecimal(item?.coefficient, 5))
      }
    });
    return getTwoDigitDecimal(totalAmount)
  }


  function getWastagePercentage() {
    var totalAmount = getTotalAmountForEachBMLItem()
    var wastageAmount = getTwoDigitDecimal(getTwoDigitDecimal(totalAmount) * getTwoDigitDecimal(state?.wastage_percentage)) / 100
    return getTwoDigitDecimal(wastageAmount)
  }

  function getTotalBAmount() {
    var getTotalAmount = getTotalAmountForEachBMLItem()
    var wastageAmount = getWastagePercentage()
    var total = getTotalAmount + wastageAmount
    return getTwoDigitDecimal(total)
  }

  function getWaterPercentage() {
    var totalAmount = getTotalBAmount()
    var waterAmount = getTwoDigitDecimal(getTwoDigitDecimal(totalAmount) * getTwoDigitDecimal(state?.water_percentage)) / 100
    return getTwoDigitDecimal(waterAmount)
  }

  function getTotalCAmount() {
    var totalAmount = getTotalBAmount()
    var waterAmount = getWaterPercentage()
    var total = totalAmount + waterAmount
    return getTwoDigitDecimal(total)
  }

  function getOverheadPercentage() {
    var totalAmount = getTotalCAmount()
    var overheadAmount = getTwoDigitDecimal(getTwoDigitDecimal(totalAmount) * getTwoDigitDecimal(state?.overhead_percentage)) / 100
    return getTwoDigitDecimal(overheadAmount)
  }

  function getRatePerUnit() {
    var totalAmount = getTotalCAmount()
    var overheadAmount = getOverheadPercentage()
    var total = totalAmount + overheadAmount + getTotalAmountForEachBuiltupItem()
    return getTwoDigitDecimal(total)
  }

  const onClickSaveAllBuiltupItemRate = () => {
    const newRaItemDetails = []
    raBillRateDetails?.forEach((item, index) => {
      newRaItemDetails.push({
        runningaccountbill_id: parentRaBill?.runningaccountbill_id,
        rabillamendment_details_id: raBillDetails?.rabillamendment_details_id,
        item_code: checkValidation(item?.item_code) === true ? item?.item_code : item?.code,
        isBuiltUpItem: item?.isBuiltUpItem,
        unit_name: item?.unit_name,
        bsr_year: item?.bsr_year,
        description: item?.description,
        rate: getTwoDigitDecimal(item?.rate),
        wastage_percentage: item?.wastage_percentage,
        water_percentage: item?.water_percentage,
        overhead_percentage: item?.overhead_percentage,
        rabillamendmentraitem_details_id: item?.rabillamendmentraitem_details_id,
        rabillamendmentraitem_id: rateAnalysisDetails?.rabillamendmentraitem_id,
        coefficient: item?.coefficient
      })
    })

    const params = {
      runningaccountbill_id: parentRaBill?.runningaccountbill_id,
      constructiontype_id: raBillDetails?.constructiontype_id,
      rabillamendment_details_id: raBillDetails?.rabillamendment_details_id,
      constructionname_id: raBillDetails?.constructionname_id,
      classification_id: rateAnalysisDetails?.classification_id,
      item_code: raBillDetails?.item_code,
      rabillamendmentrateanalysis_id: rateAnalysisDetails?.rabillamendmentrateanalysis_id,
      rate: getRatePerUnit(),
      raBillAmendmentRaItem: {
        rabillamendmentraitem_id: rateAnalysisDetails?.rabillamendmentraitem_id,
        rabillamendmentrateanalysis_id: rateAnalysisDetails?.rabillamendmentrateanalysis_id,
        runningaccountbill_id: parentRaBill?.runningaccountbill_id,
        rabillamendment_details_id: raBillDetails?.rabillamendment_details_id,
        classification_id: rateAnalysisDetails?.classification_id,
        item_code: raBillDetails?.item_code,
        bml_item_id: rateAnalysisDetails?.bml_item_id,
        unit_id: rateAnalysisDetails?.unit_id,
        unit_name: rateAnalysisDetails?.unit_name,
        bsr_year: rateAnalysisDetails?.bsr_year,
        description: rateAnalysisDetails?.description,
        rate: getRatePerUnit(),
        raBillAmendmentRaItemDetailsList: newRaItemDetails
      }

    }

    saveOrUpdateRaBillAmendmentRateAnalysisByParams(params)
  }

  const saveOrUpdateRaBillAmendmentRateAnalysisByParams = (params) => {
    setIsLoad(true)
    saveOrUpdateRaBillAmendmentRateAnalysis(params).then((res) => {
      setIsLoad(false)
      if (res?.status < 300 && res?.data?.success) {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'success' });
        SnackBarProperty.success(key, closeSnackbar);

        handleSuccess()
      } else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      }
    }).catch(function (error) {
      setIsLoad(false)
      const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    })
  }

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar id="appBarContainer" sx={{ position: 'fixed' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              sx={{ padding: 0, width: 0, height: 0 }}
              aria-label="close"
            >

            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">

            </Typography>
            <Button variant="contained" color="success" onClick={() => handleChangePrint()}>
              <Icon>print</Icon> &nbsp;Print
            </Button>&nbsp;&nbsp;
            <Button variant="contained" color="error" onClick={() => handleClose()}>
              <Icon>cancel</Icon> &nbsp;Close
            </Button>
          </Toolbar>
        </AppBar>
        <div style={{ paddingTop: isPrinting ? 0 : 64, overflowY: isPrinting ? "hidden" : 'auto' }}>
          <Box p={3} id="printablediv">
            <H3 align="center" >Rate Analysis Details</H3>
            <Divider sx={{ mb: 3 }} />
            <H4 sx={{ mb: "5px" }}>{`Item Code: ${raBillDetails?.item_code}`} {`; Unit: ${raBillDetails.unit_name}`}</H4>
            <H4 sx={{ mb: "5px" }}>{`Description: ${raBillDetails?.name}`}</H4>
            <H4 sx={{ mb: "10px" }}>{`Construction Name: ${raBillDetails?.construction_name}`}</H4>

            {/* <H4 sx={{ mb: "5px" }}>{`Item Code: ${raBillDetails?.item_code}`}</H4>
            <H4 sx={{ mb: "10px" }}>{`Construction Name: ${raBillDetails?.construction_name}`}</H4> */}
            <Divider sx={{ mb: 3 }} />

            <div>
              <Grid container spacing={2} alignItems="center" id="flexContainer">
                <Grid item md={2} sm={4} xs={12} className='labelsForHide'>
                  Wastage (%) {isPrinting === false && <Star>*</Star>} {isPrinting && <span>: {state?.wastage_percentage}</span>}
                </Grid>

                {isPrinting === false && <Grid item md={2} sm={8} xs={12}>
                  <DecimalInputField
                    disabled
                    size="small"
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      inputProps: { limitMaxValue: 100 }
                    }}
                    id="wastage_percentage"
                    name="wastage_percentage"
                    label="Wastage Percentage"
                    onChange={handleChange}
                    value={state?.wastage_percentage || ""}
                    validators={["required"]}
                    errorMessages={["Wastage % is required"]}


                  />
                </Grid>}

                <Grid item md={1} sm={4} xs={12} className='labelsForHide'>
                  Water (%) {isPrinting === false && <Star>*</Star>} {isPrinting && <span>: {state?.water_percentage}</span>}
                </Grid>
                {isPrinting === false &&
                  <Grid item md={2} sm={8} xs={12}>
                    <DecimalInputField
                      disabled
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        inputProps: { limitMaxValue: 100 }
                      }}
                      size="small"
                      label="Water Percentage"
                      id="water_percentage"
                      variant="outlined"
                      name="water_percentage"
                      value={state?.water_percentage || ""}
                      onChange={handleChange}
                      validators={["required"]}
                      errorMessages={["Water % is required"]}
                    >

                    </DecimalInputField>
                  </Grid>}
                <Grid item md={2} sm={4} xs={12} className='labelsForHide'>
                  Overhead Charges & Profit (%) {isPrinting === false && <Star>*</Star>} {isPrinting && <span>: {state?.overhead_percentage}</span>}
                </Grid>

                {isPrinting === false && <Grid item md={2} sm={8} xs={12}>
                  <DecimalInputField
                    disabled
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      inputProps: { limitMaxValue: 100 }
                    }}
                    size="small"
                    label="Overhead Charge Percentage"
                    id="overhead_percentage"
                    variant="outlined"
                    value={state?.overhead_percentage || ""}
                    name="overhead_percentage"
                    validators={["required"]}
                    onChange={handleChange}
                    errorMessages={["Water % is required"]}
                  >

                  </DecimalInputField>
                </Grid>}

                {/* {isPrinting===false&&
               <Grid item md={1} sm={8} xs={12} id="btnAdd">
                 <Box component="span" m={1} display="flex" justifyContent="flex-end" >
                   <Button size="medium" color="success" variant="contained" type="submit" onClick={() => onClickApply()}>Apply</Button>
                 </Box>
               </Grid>} */}

              </Grid>
              <br />

            </div>

            <Card sx={{ width: "100%", overflow: "auto" }} elevation={6}>
              <MyTable>
                <TableHead style={{ backgroundColor: '#87CEEB' }} >
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell rows="4" colSpan={"4"} align="center"><b>Coefficient for per {rateAnalysisDetails?.unit_name}</b></TableCell>
                    <TableCell rows="2"></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell rows="2">Code</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell align="right">Quantity&nbsp;&nbsp;</TableCell>
                    <TableCell align="center">Units</TableCell>
                    <TableCell align="left">Rate (Nu)&nbsp;&nbsp;</TableCell>
                    <TableCell align="right">Total Amount(Nu)&nbsp;&nbsp;</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {rateDetailsArray?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{checkValidation(item?.item_code) === true ? item?.item_code : item?.code}</TableCell>
                      <TableCell>{item?.description}</TableCell>
                      <TableCell align="right">{currencyFormat(item?.coefficient, 5)}</TableCell>
                      <TableCell align="center">{item?.unit_name}</TableCell>
                      {item?.isBuiltUpItem ? (
                        <TableCell align="center">{getTwoDigitDecimal(item?.rate)}</TableCell>
                      ) : (
                        isPrinting ?
                          <TableCell align="right">
                            {item?.rate}
                          </TableCell>
                          :
                          <TableCell align="right">
                            <DecimalInputField
                              label=""
                              id="rate"
                              size="small"
                              width="50px"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              variant="outlined"
                              value={item?.rate}
                              onChange={e =>
                                handleRateChange('rate', index, e.target.value)
                              }
                              onKeyDown={(event) => {
                                if (event?.code === "ArrowDown" || event?.code === "ArrowUp" ||
                                  event?.key === "-" || event?.key === "+" || event?.key?.toLowerCase() === "e") {
                                  event.preventDefault();
                                }

                              }}
                            ></DecimalInputField>
                          </TableCell>
                      )
                      }
                      <TableCell align="right">{currencyFormat(getTwoDigitDecimal(item?.rate) * getTwoDigitDecimal(item?.coefficient, 5))}</TableCell>

                    </TableRow>
                  ))}
                </TableBody>
                <div style={{ pageBreakAfter: 'auto' }}></div>
                <TableHead style={{ backgroundColor: '#efefef' }}>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell rows="4" colSpan={"4"} align="center">Sub Total (A)</TableCell>
                    <TableCell colSpan={"1"} align="right">{currencyFormat(getTotalAmountForEachItem())}</TableCell>
                  </TableRow>
                </TableHead>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell rows="4" colSpan={"4"} align="center">Wastage Amount (Nu)</TableCell>
                    <TableCell colSpan={"1"} align="right">{currencyFormat(getWastagePercentage())}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell rows="4" colSpan={"4"} align="center">Total (B) = A + Wastage</TableCell>
                    <TableCell colSpan={"1"} align="right">{currencyFormat(getTotalBAmount())}</TableCell>
                  </TableRow>
                </TableHead>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell rows="4" colSpan={"4"} align="center">Water Amount</TableCell>
                    <TableCell colSpan={"1"} align="right">{getWaterPercentage()}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell rows="4" colSpan={"4"} align="center">Total (C) = B + Water</TableCell>
                    <TableCell colSpan={"1"} align="right">{getTotalCAmount()}</TableCell>
                  </TableRow>
                </TableHead>

                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell rows="4" colSpan={"4"} align="center">Overhead Charges & Profit</TableCell>
                    <TableCell colSpan={"1"} align="right">{getOverheadPercentage()}</TableCell>
                  </TableRow>
                  <TableRow style={{ backgroundColor: '#efefef' }}>
                    <TableCell></TableCell>
                    <TableCell rows="4" colSpan={"4"} align="center">Rate per Unit (Nu)</TableCell>
                    <TableCell colSpan={"1"} align="right"><h3>{getRatePerUnit()}</h3></TableCell>
                  </TableRow>
                </TableHead>
              </MyTable>
              {/* {(rateDetailsArray?.length > 0 && !isEvaluate) &&isPrinting===false&& ( */}
              {isUpdated && <Box component="span" m={1} display="flex" justifyContent="flex-end" id="btnUpdate" >
                <Button size="medium" color="primary" variant="contained" onClick={() => onClickSaveAllBuiltupItemRate()}>Update</Button>
              </Box>}
              {/* )} */}
            </Card>


          </Box>
        </div>
        {isLoad && (
          <Loader
            show={isLoad}
          />
        )}

      </Dialog>
    </div>
  );
}