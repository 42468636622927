import { ServiceUrl } from "app/utils/Constants";
import axios from "axios";


export const getConstructionNameBOQEstimateReport = ({ construction_name_id, project_location_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/project/report/${construction_name_id}/${project_location_id}/getItemListBasedOnProjectLocationAndConstructionName`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getProjectConstructionNameBOQDOMReport = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.getProjectBOQAndConstructionBOQReports, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getCostIndexReport = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/project/report/getCostIndexReport`, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getAllWorkPlanNosByParam = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/getAllWorkPlanNosByParam`, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getWorkPlanVsPhysicalProgressProjectLevel = ({ project_location_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${project_location_id}/getWorkPlanVsPhysicalProgressProjectLevel`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getRequisitionReport = (requisition_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${requisition_id}/getRequisitionReport `, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getSupplyOrderDetailsReport = (projectlocation_id, supplyorder_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${projectlocation_id}/${supplyorder_id}/getSupplyOrderDetailsReport
    `, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getMaterialBillDetailsReport = (projectlocation_id, supplier_id, supplyorder_id, bill_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${projectlocation_id}/${supplier_id}/${supplyorder_id}/${bill_id}/getMaterialBillDetailsReport
    `, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getMusterRollBillDetailsReport = (projectlocation_id, requisition_id, details_id, month, year) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${projectlocation_id}/${requisition_id}/${details_id}/${month}/${year}/getMusterRollBillDetailsReport 
    `, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getReceiptDetailsReport = (projectlocation_id, supplyorder_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${projectlocation_id}/${supplyorder_id}/getReceiptDetailsReport
    `, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getMachineryLogbookReport = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/getMachineryLogbookReport  `, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getBMLItemsForReport = (resource_requisition_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${resource_requisition_id}/getResourceRequisitionDetailsReport  `, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getWorkerAttendanceReport = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/getWorkerAttendanceReport`, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getPartThreeDomReport = (project_location_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${project_location_id}/getPartThreeDomReport `, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getWorkPlanVsPhysicalProgressConstructionLevel = ({ project_location_id, construction_name_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${construction_name_id}/${project_location_id}/getWorkPlanVsPhysicalProgressConstructionLevel`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getPhysicalAndFinalProgressCNLevelReport = ({ project_location_id, construction_name_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${project_location_id}/${construction_name_id}/getPhysicalAndFinalProgressCNLevelReport`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getExecutedBillOfQuantityReport = ({ construction_name_id, project_location_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${project_location_id}/${construction_name_id}/getExecutedBillOfQuantityReport`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getConstructionNameBOQContractReport = ({ construction_name_id, project_location_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/project/report/${construction_name_id}/${project_location_id}/getItemListBasedOnProjectLocationAndConstructionNameForContract`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getProjectBSRReport = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/project/report/getProjectBsr`, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getProjectBMLReport = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/project/report/getProjectBml`, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getProjectBOQReport = ({ project_location_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/project/report/${project_location_id}/getProjectBoqEstimate`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getProjectBoqContractReport = ({ project_location_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/project/report/${project_location_id}/getProjectBoqContract`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};


export const getContractWorkPlanReport = ({ project_location_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${project_location_id}/getContractWorkPlanReport`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getGoodAdjustmentDetailsReport = ({ project_location_id, adjustment_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${project_location_id}/${adjustment_id}/getGoodAdjustmentDetailsReport`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getRaBillAmendmentDetailsReport = ({ project_location_id, ra_bill_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${project_location_id}/${ra_bill_id}/getRaBillAmendmentDetailsReport`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getContractDetailsReport = ({ project_location_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${project_location_id}/getContractDetailsReport`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getProjectSummaryReport = ({ project_location_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${project_location_id}/getProjectSummaryReport`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getRaBillRecoveryDetailsForReport = ({ project_location_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${project_location_id}/getRaBillRecoveryDetailsReport`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getGoodsReceiptReport = ({ project_location_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${project_location_id}/getGoodReceiptReport `, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getItemStockReport = ({ project_location_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${project_location_id}/getAllStockReport `, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getAdvancePaymentRecoveryReport = ({ project_location_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/advancePayment/${project_location_id}/getAdvancePaymentReport`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getLiquidatedDamagesRecoveryReport = ({ project_location_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${project_location_id}/getLiquidatedDamagesRecoveryReport`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getDebitCreditDetailsForRABillReport = ({ project_location_id, ra_bill_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${project_location_id}/${ra_bill_id}/getDebitCreditDetailsForRABillReport`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getStatementOfTDSReport = ({ project_location_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${project_location_id}/getStatementOfTDSReport`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getFundForecastReport = (props) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${props?.project_location_id}/getFundForecastReport`, props, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getRaBillVsTimeReport = ({ project_location_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${project_location_id}/getRaBillVsTimeReport`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getStatementOfDeviationReport = ({ project_location_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${project_location_id}/getStatementOfDeviationReport`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getStatementOfRetentionReport = ({ project_location_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${project_location_id}/getStatementOfRetentionReport`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getRunningAccountBillForReport = ({ project_location_id, ra_bill_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${project_location_id}/${ra_bill_id}/getRunningAccountBillReport`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getPhysicalAndFinalProgressReport = ({ project_location_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${project_location_id}/getPhysicalAndFinalProgressReport`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getStatementOfPriceAdjustmentReport = ({ project_location_id, ra_bill_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${project_location_id}/${ra_bill_id}/getStatementOfPriceAdjustmentReport`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getAllRaBillNoForReport = ({ project_location_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${project_location_id}/getAllRaBillNoForReport`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getAllPriceAdjustmentForReport = ({ ra_bill_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${ra_bill_id}/getAllPriceAdjustmentReport`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getAllAdvanceDetailsForReport = ({ project_location_id, ra_bill_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${project_location_id}/${ra_bill_id}/getAllAdvanceDetailsReport`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getContractDetailsAndRunningAccountBillDetails = ({ project_location_id, ra_bill_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${project_location_id}/${ra_bill_id}/getContractDetailsAndRunningAccountBillDetails`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getRateAnalysisReport = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/project/report/getProjectBsrBuiltUp`, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getMaterialForecastReportByPR = ({ project_location_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${project_location_id}/getMaterialForecastReport`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getMaterialForecastReportByCN = ({ construction_name_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${construction_name_id}/getMaterialForecastReportByCNId`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getStatementOfMaterialConsumptionReport = ({ project_location_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${project_location_id}/getStatementOfMaterialConsumptionReport`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getAllSupplyOrderForReport = ({ project_location_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/resourceForecast/${project_location_id}/getAllSupplyOrderForReport`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getGoodIssueReport = ({ project_location_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${project_location_id}/getGoodIssueReport`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};



