import { LoadingButton } from "@mui/lab";
import { Box, Button, Card, Checkbox, Dialog, Fab, Grid, Icon, MenuItem, createTheme, styled } from "@mui/material";
import { FlexBetween } from "app/components/FlexBox";
import { H4 } from "app/components/Typography";
import { isProjectLocationNotPresentInConstructionName } from "app/redux/actions/ProjectLocationActions";
import { saveOrUpdateRunningAccountBill } from "app/redux/actions/RABillActions";
import { ProjectStatus } from "app/utils/ProjectStatus";
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import { checkValidation, getEpochTime, getTwoDigitDecimal } from "app/utils/utils";
import { Paragraph } from "components/Typography";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { AppConstants } from "app/utils/AppConstants";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import { cmsDateFormat } from "app/utils/constant";

const TextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));

const DecimalField = styled(TextField)(({ theme }) => ({
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
}));

const FabIcon = styled(Fab)(() => ({
  textTransform: "capitalize",
  "& .icon": { marginRight: "8px" },
}));

const MyCard = styled(Card)(() => ({
  "& thead": { "& th": { padding: 5 } },
  "& td": { borderBottom: "none" },
  "& td": { padding: "5px !important" },
}));


const theme = createTheme({

  components: {
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          backgroundColor: "#87CEEB",
        }
      }
    }
  }
});

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

const HiddenInput = styled("input")(() => ({ display: "none" }));


const NewRABillDialog = ({ title, type, raBill, projectLocationList, open, handleClose, handleSuccess }) => {

  const [approvalArray, setApprovalArray] = useState(["Approved", "Rejected"])

  const [state, setState] = useState({
    contractdetails_id: "",
    project_name: "",
    project_id: '',
    project_location_id: "",
    rabill_no: "",
    rabill_date: '',
    work_completion_date: "",
    extention_no: "",
    extention_date: '',
    total_delay_days: 0,
    penalty_amount: 0,
    amendment_amount: '',
    recovery_amount: '',
    submission_filename: '',
    final_bill: false,
    status: ProjectStatus.SUBMITTED,
    file: ''
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);


  const handleChange = (event, source) => {
    if (event.target.name === "status_name") {
      if (event.target.value === 'Recommended') {
        setState({ ...state, ['status_id']: ProjectStatus.RECOMMENDED });
      } else {
        setState({ ...state, ['status_id']: ProjectStatus.REJECTED });
      }
      setState({ ...state, [event.target.name]: event.target.value });
    } else if (event.target.name === "project_id") {
      const filteredArray = projectLocationList.filter(project => {
        return project.project_id === event.target.value
      });
      console.log('selected', filteredArray)
      state.project_name = filteredArray[0].project_name
      state.contractdetails_id = filteredArray[0].contractdetails_id
      state.contract_extension_no = filteredArray[0].contract_extension_no
      state.contract_extension_date = filteredArray[0].contract_extension_date
      state.previous_extension_date = filteredArray[0].contract_extension_date
      state.work_start_date = filteredArray[0].work_start_date
      state.work_end_date = filteredArray[0].work_end_date
      state.initial_contract_amount = filteredArray[0].initial_contract_amount
      state.penalty_amount_limit = filteredArray[0].penalty_amount_limit
      state.ld_percentage = filteredArray[0].ld_percentage
      state.project_location_id = filteredArray[0].project_location_id
      state.rabill_no = `${filteredArray[0].project_id}-${filteredArray[0].project_name}-${getEpochTime()}`
      state.total_delay_days = calculateTotalDelayDays(filteredArray[0].contract_extension_date, filteredArray[0].work_end_date)
      state.penalty_amount = calculatePenaltyAmount(state.total_delay_days,
        filteredArray[0].initial_contract_amount,
        filteredArray[0].penalty_amount_limit,
        filteredArray[0].ld_percentage)
      setState({ ...state, [event.target.name]: event.target.value });
    } else if (event.target.name === "contract_extension_date") {
      state.total_delay_days = calculateTotalDelayDays(event.target.value, state.work_end_date)
      state.penalty_amount = calculatePenaltyAmount(state.total_delay_days, state.initial_contract_amount,
        state.penalty_amount_limit, state.ld_percentage)
      setState({ ...state, [event.target.name]: event.target.value });

    } else if (event.target.name === "final_bill") {
      console.log('checkbox', event.target.checked)
      setState({ ...state, [event.target.name]: event.target.checked });
    } else {
      console.log(event)
      ;
      setState({ ...state, [event.target.name]: event.target.value });
    }
  };

  useEffect(()=> {
    if (type === 'Engineer') {
        setState(raBill)
    }
  }, [raBill])

  useEffect(() => {
    setDelayDays()
  },[state?.work_completion_date, state?.contract_extension_date, state?.rabill_date])

  const setDelayDays = () => {
    if (type === 'Contractor') {
    const delay_days = calculateTotalDelayDays(state?.contract_extension_date, state.work_end_date)
    const penalty_amount = calculatePenaltyAmount(delay_days, state.initial_contract_amount,
    state.penalty_amount_limit, state.ld_percentage)
    setState({ ...state, ['total_delay_days']: delay_days,
                         ['penalty_amount']: penalty_amount
     });
    }
  }

  function calculateTotalDelayDays(extension_date, work_end_date) {
    console.log('enter date', state?.work_completion_date)
    if (checkValidation(state?.work_completion_date)) {
      console.log('enter')
    var currentDate = new Date(state?.work_completion_date);
    if (checkValidation(extension_date)) {
      console.log('enter1')
      var extensionDate = new Date(extension_date)
      return extensionDate < currentDate ? calculateDifferenceInDays(currentDate, extensionDate) : 0
    } else {
      console.log('enter2')
      var workEndDate = new Date(work_end_date)
      return workEndDate < currentDate ? calculateDifferenceInDays(currentDate, workEndDate) : 0
      }
    } else {
      console.log('enter3')
      return 0
    }
  }

  function calculateDifferenceInDays(currentDate, previousDate) {
    var Difference_In_Time = currentDate.getTime() - previousDate.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return parseFloat(Difference_In_Days).toFixed(0)
  }

  function calculatePenaltyAmount(total_delay_days, initial_contract_amount, penalty_amount_limit, ld_percentage) {
    if (checkValidation(total_delay_days)) {
      var penaltyAmount = ((initial_contract_amount ?? 0) * (ld_percentage ?? 0) * (total_delay_days ?? 0) / 100)
      var estimatedPenaltyAmount = (((initial_contract_amount ?? 0) * (penalty_amount_limit ?? 0)) / 100)
      if (penaltyAmount > estimatedPenaltyAmount) {
        return getTwoDigitDecimal(estimatedPenaltyAmount)
      } else {
        return getTwoDigitDecimal(penaltyAmount)
      }
    } else {
      return 0
    }
  }

  const handleFileSelect = (event) => {
    let files = event.target.files;
    if (files.length > 0) {
      state.submission_filename = files[0].name
      setState({ ...state, ['file']: files[0] });
    }
  };

  const checkIsProjectLocationNotPresentInConstructionName = () => {
    setLoading(true)
    isProjectLocationNotPresentInConstructionName(state?.project_location_id).then((res) => {
      setLoading(false)
      console.log('my response code', res?.status)
      if (res?.status < 300) {
        if (res?.data.success) {
          saveRABillByParams()
        } else {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        }
      } else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      }
    }).catch(function (error) {
      setLoading(false)
      const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    })
  }

  const handleFormSubmit = () => {
    if (validateAllValues() === false) { return }
    checkIsProjectLocationNotPresentInConstructionName()
  };

  const saveRABillByParams = () => {
    setLoading(true)
    saveOrUpdateRunningAccountBill(createParamsFoRABill()).then((res) => {
      setLoading(false)
      console.log('my response code', res?.status)
      if (res?.status < 300) {
        if (res?.data.success) {
          const key = enqueueSnackbar("New RA Bill created successfully", { variant: 'success' });
          SnackBarProperty.success(key, closeSnackbar);
          handleSuccess()
        }
        else {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        }

      } else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      }
    }).catch(function (error) {
      setLoading(false)
      const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    })
  }

  function validateAllValues() {

    if (checkValidation(state.rabill_date) === false) {
      const key = enqueueSnackbar("Please select bill date", { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
      return false
    }

    if (state.final_bill) {
      if (checkValidation(state?.work_completion_date) === false) {
        const key = enqueueSnackbar("Please select work completion date", { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);
        return false
      }

      if (checkValidation(state.submission_filename) === false) {
        const key = enqueueSnackbar("Please attach supporting documents", { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);
        return false
      }
    }

  }

  function createParamsFoRABill() {
    const formData = new FormData()

    formData.append('contractdetails_id', state.contractdetails_id);
    formData.append('project_name', state.project_name);
    formData.append('project_id', state.project_id)
    formData.append('project_location_id', state.project_location_id);

    if (checkValidation(state.rabill_date) === true) {
      formData.append('rabill_date', dayjs(state?.rabill_date).format("ddd, DD MMM YYYY 00:00:00") + " GMT")
    }
    if (checkValidation(state.work_completion_date) === true) {
      formData.append('work_completion_date', dayjs(state?.work_completion_date).format("ddd, DD MMM YYYY 00:00:00") + " GMT")
    }
    if (checkValidation(state.contract_extension_date) === true) {
      formData.append('extention_date', dayjs(state?.contract_extension_date).format("ddd, DD MMM YYYY 00:00:00") + " GMT")
    }
    formData.append('extention_no', state.contract_extension_no);
    formData.append('amount', 0)
    formData.append('final_bill', state.final_bill)
    // formData.append('extention_date', state.extention_date)
    formData.append('total_delay_days', state.total_delay_days);
    formData.append('penalty_amount', state.penalty_amount);
    formData.append('amendment_amount', state.amendment_amount);
    formData.append('recovery_amount', state.recovery_amount)
    formData.append('status', ProjectStatus.CREATED);

    if (checkValidation(state.file) === true) {
      formData.append('file', state.file);
      formData.append('submission_filename', state.submission_filename);
    }

    return formData
  }

  return (
    <Dialog open={open} maxWidth="sm" fullWidth={true}>
      <Box p={3}>
        <H4 sx={{ mb: "20px" }}>{title}</H4>
        <ValidatorForm onSubmit={handleFormSubmit}>
          <Grid sx={{ mb: "16px" }} container spacing={4}>
            <Grid item sm={6} xs={12}>
              <TextField
                select
                label="Project ID"
                disabled={type === 'Engineer'}
                id="project_id"
                variant="outlined"
                name="project_id"
                value={state?.project_id || ""}
                onChange={handleChange}
                InputLabelProps={{ shrink: true, required: true }}
                validators={["required"]}
                errorMessages={["Project ID is required"]}
              >
                {projectLocationList?.map((item) => (
                  <MenuItem value={item?.project_id} key={item?.project_location_id}>
                    {item?.project_id}
                  </MenuItem>
                ))}
              </TextField>
              <DatePicker
                label="RA Bill Date"
                value={dayjs(state?.rabill_date)}
                sx={{mb: 1.5}}
                format={cmsDateFormat}
                minDate={dayjs(state?.work_start_date)}
                maxDate={dayjs()}
                slotProps={{
                  textField: {
                    required: true,
                    fullWidth: true,
                    variant: 'outlined',
                    error: false,
                    // helperText: `Current Date: ${moment().format("DD/MM/YYYY")}`
                  },
                }}
                onChange={(newValue) =>
                  setState({
                    ...state, ['rabill_date']: dayjs(newValue).format("YYYY-MM-DDTHH:mm:ss"), 
                    // ['work_completion_date']: dayjs(newValue).format("YYYY-MM-DDTHH:mm:ss")
                  })
                }
              />
              <br />
              <h4>Extension Information</h4>

              <TextField
                sx={{ mt: 3 }}
                disabled={checkValidation(state?.project_id) === false}
                type="text"
                id="contract_extension_no"
                name="contract_extension_no"
                label="Extension No."
                value={state?.contract_extension_no || ""}
                onChange={handleChange}
              />

              <DatePicker
                disabled={checkValidation(state?.project_id) === false}
                label="Extension Date"
                sx={{mb: 1.5}}
                value={dayjs(state?.contract_extension_date)}
                format={cmsDateFormat}
                minDate={checkValidation(state?.previous_extension_date) ? dayjs(state?.previous_extension_date) : dayjs(state?.work_end_date)}
                slotProps={{
                  textField: {
                    required: true,
                    fullWidth: true,
                    variant: 'outlined',
                    error: false,
                    // helperText: `Current Date: ${moment().format("DD/MM/YYYY")}`
                  },
                }}
                onChange={(newValue) =>
                  setState({ ...state, ['contract_extension_date']: dayjs(newValue).format("YYYY-MM-DDTHH:mm:ss") })
                }
              />
              {type === 'Contractor' &&
              <FlexBox gap={1}>
                <Checkbox
                  size="small"
                  name="final_bill"
                  onChange={handleChange}
                  checked={state.final_bill}
                  sx={{ padding: 0 }}
                />

                <Paragraph>Final Bill</Paragraph>
              </FlexBox>
            }

            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                disabled
                type="text"
                name="project_name"
                label="Project Name"
                value={state?.project_name || ""}
              >
              </TextField>

              <DatePicker
                label={state.final_bill ? "Actual Work Completion Date" : "Work Completion Date"}
                value={dayjs(state?.work_completion_date)}
                sx={{mb: 1.5}}
                // disabled={type === 'Engineer'}
                format={cmsDateFormat}
                maxDate={dayjs(new Date())}
                slotProps={{
                  textField: {
                    required: true,
                    fullWidth: true,
                    variant: 'outlined',
                    error: false,
                    // helperText: `Current Date: ${moment().format("DD/MM/YYYY")}`
                  },
                }}
                onChange={(newValue) =>
                  setState({
                    ...state, ['work_completion_date']: dayjs(newValue).format("YYYY-MM-DDTHH:mm:ss")
                  })
                }
              />
              {((state?.total_delay_days ?? 0) > 0) && (
                <div>
                  <h4>Delay Information</h4>
                  <p>Total Delay Days: {state?.total_delay_days}</p>
                  <p>Penalty Amount: {state?.penalty_amount}</p>
                </div>

              )}

            {type === 'Contractor' &&
              <div>
              <Box mb={1} mt={1.5} display="flex" flexWrap={1}>
                <label htmlFor="upload-single-file">
                  <FabIcon color="success" component="span" variant="extended">
                    <FlexBox>
                      <Icon className="icon">cloud_upload</Icon>
                      <span>Attach Supporting Document</span>
                    </FlexBox>
                  </FabIcon>
                </label>
                <HiddenInput onChange={handleFileSelect} id="upload-single-file" type="file" />
              </Box>
              <H4 mb={3}>{state?.submission_filename}</H4>
              </div>
            }
            </Grid>
          </Grid>

          <FlexBetween>
            <LoadingButton variant="contained" color="primary" type="submit" loading={loading}>
              {type === 'Contractor' ? "Create" : "Save"}
            </LoadingButton>
            <Button variant="outlined" color="secondary" onClick={() => handleClose()}>
            Close
            </Button>
          </FlexBetween>
        </ValidatorForm>
      </Box>
    </Dialog>
  );
};

export default NewRABillDialog;
