import { Autocomplete, Button, Card, Dialog, Fab, Grid, Popper, Icon, IconButton, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, TextField, ThemeProvider, Tooltip, createTheme, styled } from "@mui/material";
import { Box } from "@mui/system";
import { ConfirmationDialog, Loader } from "app/components";
import { FlexBox } from "app/components/FlexBox";
import { getAllChapterListById, getAllSectionListByClassificationId } from "app/redux/actions/BSRActions";
import { getAllBuildUpItemistForConstructionNameBOQ, getAllClassificationsListForConstruction, getAllConstructionTypeList, getAllConstructionTypeListForPBOQ } from "app/redux/actions/ConstructionActions";
import { getProjectBoQDetailsByConstructionName, getRaBillAmendmentRateAnalysis } from "app/redux/actions/ImplementationActions";
import { getAllConstructionNameListByParameters } from "app/redux/actions/ProjectLocationActions";
import { checkItemPresentInRunningAccountBill, deleteRaBillAmendmentDetails, deleteRaBillAmendmentDetailsDOM, getAllRaBillAmendmentByRunningAccountBillId, saveOrUpdateRaBillAmendment, saveRaBillAmendmentDetailsDOM } from "app/redux/actions/RABillActions";
import { AppConstants } from "app/utils/AppConstants";
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import { DecimalInputField, NumberFormatCustom, checkValidation, currencyFormat, getTwoDigitDecimal, goToViolation, validateTwoDigitDecimal } from "app/utils/utils";
import { H3, H4 } from "components/Typography";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { TextValidator } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import NewConstructionNameDialog from "../ConstructionName/NewConstructionNameDialog";
import NewConstructionTypeDialog from "../ConstructionName/NewConstructionTypeDialog";
import { RABillAmendmentRateDetailsDialog } from "./RABillAmendmentRateDetailsDialog";
import dayjs from "dayjs";


const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));

const MyCard = styled(Card)(() => ({
  minWidth: 750,
  whiteSpace: "pre",
  "& thead": { "& th:first-of-type": { padding: 16 } },
  "& td": { borderBottom: "true" },
  "& td": { padding: "3px !important", paddingLeft: "16px !important" },
}));

const MyTable = styled(Table)(() => ({
  minWidth: 750,
  whiteSpace: "pre",
  "& thead": { "& th:first-of-type": { paddingLeft: 16 } },
  "& td": { borderBottom: "none" },
  "& td:first-of-type": { paddingLeft: "16px !important" },
}));


const DecoratedTextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));

const theme = createTheme({

  components: {
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          backgroundColor: "#87CEEB",
        }
      }
    }
  }
});

const Star = styled("small")(({ theme }) => ({
  color: "#f00",
  overflow: "hidden",
  fontWeight: "bolder",
}));


const RABillAmendmentDialog = ({ isUpdated, open, raBill, project, parentState, handleSuccess, handleClose }) => {

  const [dialogTitle, setdialogTitle] = useState('');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [raBillDetailsList, setRaBillDetailsList] = useState()
  const [shouldOpenRaBillDetailsList, setShouldOpenRaBillDetailsList] = useState(false)
  const [shouldShowDOM, setShouldShowDOM] = useState(false)
  const [domList, setDomList] = useState([])
  const [domDetailsData, setDomDetailsData] = useState(null)
  const [raBillDetails, setRaBillDetails] = useState()
  const [shouldOpenDOMDeleteDialog, setShouldOpenDOMDeleteDialog] = useState(false)
  const [subTotalDomQuantity, setSubTotalDomQuantity] = useState(0)
  const [deductionsList, setDeductionsList] = useState(['+', '-'])
  const [shouldOpenDetailsDialog, setShouldOpenDetailsDialog] = useState(false)
  const [raBillRateDetails, setRaBillRateDetails] = useState(false)
  const [rateAnalysisDetails, setRateAnalysisDetails] = useState({})

  const { constructionTypeList } = useSelector((state) => state.raBill);
  //const [constructionNameList, setConstructionNameList] = useState()

  const [shouldOpenNewRABillDialog, setShouldOpenNewRABillDialog] = useState(false)
  const [selectedRABillDetailsIndex, setSelectedRABillDetailsIndex] = useState()
  const { projectLocationList, isLoading, classificationList } = useSelector((state) => state.construction);
  const [shouldOpenConstructionTypeDialog, setShouldOpenConstructionTypeDialog] = useState(false);
  const [shouldOpenConstructionNameDialog, setShouldOpenConstructionNameDialog] = useState(false);
  const [constructionType, setConstructionType] = useState({});
  const [constructionName, setConstructionName] = useState({});
  const [shouldOpenConfirmationDialog, setShouldOpenConfirmationDialog] = useState(false)
  const [state, setState] = useState({
    project_name: '',
    project_location_id: '',
    project_location: '',
    project_id: '',
    item_code: '',
  })

  const styles = (theme) => ({
    popper: {
      width: "fit-content"
    }
  });
  const PopperMy = function (props) {
    return <Popper {...props} style={styles.popper} placement="bottom-start" />;
  };

  const raBillAmendmentColumns = [
    {
      name: "index",
      label: "Sl No.",
      options: {
        filter: false,
        sort: false,
        Headers: "bold",
        customBodyRenderLite: (rowIndex) => rowIndex + 1
      },
    }, {
      name: "constructiontype_name",
      label: "Construction Type",
      options: {
        filter: true, sort: false, customBodyRenderLite: (rowIndex) => (
          <div>
            {raBillDetailsList[rowIndex].rabillamendment_details_id ? (
              <p>{raBillDetailsList[rowIndex].constructiontype_name || ''}</p>
            ) : (
              <Autocomplete
                size="small"
                PopperComponent={PopperMy}
                style={{ marginLeft: 5, marginRight: 5 }}
                value={raBillDetailsList[rowIndex]?.constructiontype_name}
                isOptionEqualToValue={(option, value) => option?.constructiontype_name === value?.constructiontype_name}
                onChange={(event, value) => {
                  handleRABillDetailsItemChange("Construction Type", rowIndex, value)
                }}
                options={constructionTypeList ?? []}
                getOptionLabel={(option) => option?.constructiontype_name || ""}
                id="cons_type"
                renderInput={(params) =>
                  <TextField {...params}
                    label="Construction Type" />
                }
              ></Autocomplete>
            )}
          </div>
        )
      }
    },
    {
      name: "construction_name",
      label: "Construction Name",
      options: {
        filter: true, sort: false, customBodyRenderLite: (rowIndex) => (
          <div>
            {raBillDetailsList[rowIndex]?.rabillamendment_details_id ? (
              <p>
                {raBillDetailsList[rowIndex]?.construction_name || ''}
              </p>
            ) : (
              <Autocomplete
                size="small"
                freeSolo
                PopperComponent={PopperMy}
                style={{ marginLeft: 5, marginRight: 5 }}
                value={raBillDetailsList[rowIndex]?.construction_name ?? ''}
                inputValue={raBillDetailsList[rowIndex]?.construction_name ?? ''}
                isOptionEqualToValue={(option, value) => option?.construction_name === value?.construction_name}
                onChange={(event, value) => {
                  handleRABillDetailsItemChange("Construction Name", rowIndex, value)
                }}
                options={raBillDetailsList[rowIndex]?.constructionNameList ?? []}
                getOptionLabel={(option) => option?.construction_name || ""}
                id="cons_name"
                renderInput={(params) =>
                  <TextField {...params}
                    label="Construction Name" />
                }
              ></Autocomplete>
            )}
          </div>
        )
      }
    },
    {
      name: "boq_name",
      label: "BoQ Name",
      options: {
        filter: true, sort: false, customBodyRenderLite: (rowIndex) => (
          <div>
            {raBillDetailsList[rowIndex]?.rabillamendment_details_id ? (
              <p>
                {raBillDetailsList[rowIndex]?.boq_name || ''}
              </p>
            ) : (
              <Autocomplete
                size="small"
                freeSolo
                PopperComponent={PopperMy}
                style={{ marginLeft: 5, marginRight: 5 }}
                value={raBillDetailsList[rowIndex]?.boq_name ?? ''}
                inputValue={raBillDetailsList[rowIndex]?.boq_name ?? ''}
                isOptionEqualToValue={(option, value) => option?.name === value?.boq_name}
                onChange={(event, value) => {
                  handleRABillDetailsItemChange("BoQ Name", rowIndex, value)
                }}
                options={raBillDetailsList[rowIndex]?.boqList ?? []}
                getOptionLabel={(option) => option?.name || ""}
                id="boq_name"
                renderInput={(params) =>
                  <TextField {...params}
                    label="BoQ Name" />
                }
              ></Autocomplete>
            )}
          </div>
        )
      }
    },
    {
      name: "chapter_name",
      label: "Chapter Name",
      options: {
        filter: true, sort: false, customBodyRenderLite: (rowIndex) => (
          <div>
            {raBillDetailsList[rowIndex]?.rabillamendment_details_id ? (
              <p>
                {raBillDetailsList[rowIndex]?.chapter_name || ''}
              </p>
            ) : (
              <Autocomplete
                size="small"
                freeSolo
                PopperComponent={PopperMy}
                style={{ marginLeft: 5, marginRight: 5 }}
                value={raBillDetailsList[rowIndex]?.chapter_name ?? ""}
                inputValue={raBillDetailsList[rowIndex]?.chapter_name ?? ''}
                isOptionEqualToValue={(option, value) => option?.chapter_name === value?.chapter_name}
                onChange={(event, value) => {
                  handleRABillDetailsItemChange("Chapter Name", rowIndex, value)
                }}
                options={raBillDetailsList[rowIndex]?.chapterList ?? []}
                getOptionLabel={(option) => option?.chapter_name || ""}
                id="chapter_name"
                ListboxProps={{
                  style: {
                    maxHeight: 300,  // Adjust this value to limit the height if necessary
                    width: '300px',  // Adjust this value to set the desired width
                  },
                }}
                renderInput={(params) =>
                  <TextField {...params}
                    label="Chapter Name" />
                }
              ></Autocomplete>
            )}
          </div>
        )
      }
    }, 
    {
      name: "item_code",
      label: "Item Code",
      options: {
        filter: true, sort: false, customBodyRenderLite: (rowIndex) => (
          <div>
            {raBillDetailsList[rowIndex]?.rabillamendment_details_id ? (
              <p>
                {raBillDetailsList[rowIndex]?.item_code || ''}
              </p>
            ) : (
              <Autocomplete
                freeSolo
                size="small"
                style={{ marginLeft: 5, marginRight: 5 }}
                value={raBillDetailsList[rowIndex]?.code ?? ""}
                inputValue={raBillDetailsList[rowIndex]?.code ?? ""}
                isOptionEqualToValue={(option, value) => option?.code === value?.code}
                onChange={(event, value) => {
                  handleRABillDetailsItemChange("Code", rowIndex, value)
                }}
                options={raBillDetailsList[rowIndex]?.itemList ?? []}
                getOptionLabel={(option) => option?.code || ""}
                id="code"
                renderOption={(props, option) => (
                  <li {...props}>
                    <Tooltip title={option?.description} arrow placement="right">
                      <span>{option?.code}</span>
                    </Tooltip>
                  </li>
                )}
                renderInput={(params) =>
                  <TextField {...params}
                    label="Item Code" />
                }
              ></Autocomplete>
            )}
          </div>
        )
      }
    }, {
      name: "unit_name",
      label: "Unit",
      options: {
        filter: true, sort: false
      }
    }, {
      name: "rate",
      label: "Rate (Nu)",
      options: {
        filter: true, 
        sort: false, 
        customBodyRenderLite: (rowIndex) =>
          checkValidation(raBillDetailsList[rowIndex]?.raBillAmendmentRateAnalysis?.rate) ? currencyFormat(raBillDetailsList[rowIndex]?.raBillAmendmentRateAnalysis?.rate) : currencyFormat(raBillDetailsList[rowIndex]?.rate)
      }
    },
    {
      name: "quantity",
      label: "Quantity",
      options: { filter: true, sort: false,

       }
    },
    {
      name: "amount",
      label: "Amount (Nu)",
      options: {
        filter: true, sort: false,
        customBodyRenderLite: (rowIndex) => {
          const rate = checkValidation(raBillDetailsList[rowIndex]?.raBillAmendmentRateAnalysis?.rate)
            ? raBillDetailsList[rowIndex]?.raBillAmendmentRateAnalysis?.rate
            : raBillDetailsList[rowIndex]?.rate;
          const quantity = raBillDetailsList[rowIndex]?.quantity;
          const amount = getTwoDigitDecimal(rate) * getTwoDigitDecimal(quantity); // Multiply rate and quantity
          return currencyFormat(amount); // Return the calculated amount, formatted to two decimal places
        }
      },
    },

    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (rowIndex) => (
          <FlexBox alignItems="center">
            {raBillDetailsList[rowIndex]?.rabillamendment_details_id && (
              <Tooltip title="Manage DOM">
                <IconButton onClick={() => handleClickManageDom(raBillDetailsList[rowIndex])}>
                  <Icon color="primary" fontSize="small">
                    tune
                  </Icon>
                </IconButton>
              </Tooltip>
            )}
            {raBillDetailsList[rowIndex]?.rabillamendment_details_id && (
              <Tooltip title="Rate Analysis">
                <IconButton onClick={() => handleDetailsClick(raBillDetailsList[rowIndex])}>
                  <Icon color="primary" fontSize="small">
                    rate_review
                  </Icon>
                </IconButton>
              </Tooltip>
            )}
            {isUpdated && <Tooltip title="Remove">
              <IconButton onClick={() => handleDeleteClick(raBillDetailsList[rowIndex], rowIndex)}>
                <Icon color="error" fontSize="small">
                  delete
                </Icon>
              </IconButton>
            </Tooltip>}
          </FlexBox>

        ),
      },
    }

  ]

  const handleConstructionTypeDialogClose = () => {
    setShouldOpenConstructionTypeDialog(false)
  }

  const handleConstructionTypeDialogSuccess = () => {
    dispatch(getAllConstructionTypeListForPBOQ(raBill?.project_location_id, showLoader, hideLoader, showToast))
    // dispatch(getAllConstructionTypeList(showLoader, hideLoader, showToast, "raBill"))
    setShouldOpenConstructionTypeDialog(false)
  }
  const handleConstructionNameSuccess = () => {
    setShouldOpenConstructionNameDialog(false);
  }
  const handleClickOpen = async () => {

    setdialogTitle('Add Construction Name')
    setShouldOpenConstructionNameDialog(true)
  }


  const handleConstructionDialogClose = () => {
    setShouldOpenConstructionNameDialog(false);
  };
  const fetchDataSequentially = async () => {
    dispatch(getAllConstructionTypeListForPBOQ(raBill?.project_location_id, showLoader, false, showToast, "raBill"))

    // dispatch(getAllConstructionTypeList(showLoader, false, showToast, "raBill"))
    await new Promise((resolve) => setTimeout(resolve, 2000));
    dispatch(getAllClassificationsListForConstruction(false, hideLoader, showToast))

  }
  useEffect(() => {
    console.log('ra bill', raBill)
    fetchDataSequentially()
  }, []);

  useEffect(() => {
    getAllRaBillAmendmentDetails(raBill?.runningaccountbill_id, state?.project_location_id)
  }, []);

  const showLoader = () => {
    setLoading(true)
  }

  const hideLoader = () => {
    setLoading(false)
  }

  const showToast = (msg) => {
    const key = enqueueSnackbar(msg ?? AppConstants.somethingWentWrong, { variant: 'error' });
    SnackBarProperty.error(key, closeSnackbar);
  }

  function calculateTotalAmount() {
    const totalCost = raBillDetailsList.reduce(
      (previousValue, currentValue, index) => previousValue + getTwoDigitDecimal(currentValue.total_amount), 0);
    return getTwoDigitDecimal(totalCost)
  }

  const getAllConstructionNameListByCriteria = (constructiontype_id, project_location_id, index) => {
    setLoading(true)
    getAllConstructionNameListByParameters(constructiontype_id, project_location_id).then((res) => {
      setLoading(false)
      if (res?.status < 300) {
        const newRABillDetailsList = [...raBillDetailsList]
        newRABillDetailsList[index].constructionNameList = res?.data
        setRaBillDetailsList(newRABillDetailsList)
      } else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      }
    }).catch(function (error) {
      setLoading(false)
      showToast(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong)
    });;
  }

  const getProjectBoQDetails = (id, index) => {
    setLoading(true)
    getProjectBoQDetailsByConstructionName(id).then((res) => {
      setLoading(false)
      if (res?.status < 300) {
        const newRABillDetailsList = [...raBillDetailsList]
        newRABillDetailsList[index].boqList = res?.data?.obj
        setRaBillDetailsList(newRABillDetailsList)
      } else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      }
    }).catch(function (error) {
      setLoading(false)
      const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    })
  }

  const getAllRaBillAmendmentDetails = (params) => {
    setLoading(true)
    getAllRaBillAmendmentByRunningAccountBillId(params).then((res) => {
      setLoading(false)
      if (res?.status < 300) {
        setRaBillDetails(res?.data[0])
        setRaBillDetailsList(res?.data[0]?.raBillAmendmentDetails ?? [])
        setShouldOpenRaBillDetailsList(true)
        // getContractDetails(project_location_id)
        // getItemCodeForBoq(project_location_id)
      } else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      }
    }).catch(function (error) {
      setLoading(false)
      showToast(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong)
    });;
  }

  const checkItemPresentInRunningAccountBillByParams = (params, index, value) => {
    setLoading(true)
    checkItemPresentInRunningAccountBill(params).then((res) => {
      setLoading(false)
      if (res?.status < 300 && res?.data?.success === false) {
        console.log(res)
        const newRABillDetailsList = [...raBillDetailsList]
        newRABillDetailsList[index].code = value?.code
        newRABillDetailsList[index].item_code = value?.code
        newRABillDetailsList[index].quantity = ''
        newRABillDetailsList[index].rate = value?.builtupitem_rate
        newRABillDetailsList[index].name = value?.description
        newRABillDetailsList[index].unit_name = value?.unit_name
        newRABillDetailsList[index].unit_id = value?.unit_id
        newRABillDetailsList[index].bml_item_id = value?.bml_item_id
        setRaBillDetailsList(newRABillDetailsList)
      } else {
        const key = enqueueSnackbar("Item code is already in use.", { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);
        const newRABillDetailsList = [...raBillDetailsList]
        newRABillDetailsList[index].item_code = ''
        newRABillDetailsList[index].bml_item_id = ''
        newRABillDetailsList[index].code = ''
        newRABillDetailsList[index].quantity = ''
        newRABillDetailsList[index].rate = ''
        newRABillDetailsList[index].name = ''
        newRABillDetailsList[index].unit_name = ''
        newRABillDetailsList[index].unit_id = ''
        setRaBillDetailsList(newRABillDetailsList)
      }
    }).catch(function (error) {
      setLoading(false)
      showToast(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong)
    });;
  }


  const handleRABillDetailsItemChange = (type, index, value) => {

    const newRABillDetailsList = [...raBillDetailsList]
    if (type === "Construction Type") {
      newRABillDetailsList[index].constructiontype_id = value?.constructiontype_id
      newRABillDetailsList[index].constructiontype_name = value?.constructiontype_name
      newRABillDetailsList[index].construction_name = ""
      newRABillDetailsList[index].constructionname_id = ""
      newRABillDetailsList[index].constructionNameList = []
      newRABillDetailsList[index].classification_id = value?.classification_id
      newRABillDetailsList[index].classification_name = value?.classification_name
      newRABillDetailsList[index].chapterList = []
      newRABillDetailsList[index].chapter_name = ""
      newRABillDetailsList[index].chapter_id = ""
      newRABillDetailsList[index].boqList = []
      newRABillDetailsList[index].itemList = []
      newRABillDetailsList[index].item_code = ''
      newRABillDetailsList[index].code = ''
      newRABillDetailsList[index].quantity = ''
      newRABillDetailsList[index].rate = ''
      newRABillDetailsList[index].name = ''
      newRABillDetailsList[index].unit_name = ''
      newRABillDetailsList[index].unit_id = ''
      newRABillDetailsList[index].bml_item_id = ''

      setRaBillDetailsList(newRABillDetailsList)
      if (checkValidation(value?.constructiontype_name)) {
        getAllConstructionNameListByCriteria(value?.constructiontype_id, raBill?.project_location_id, index)
      }

    } else if (type === "Construction Name") {
      newRABillDetailsList[index].construction_name = value?.construction_name
      newRABillDetailsList[index].constructionname_id = value?.constructionname_id
      newRABillDetailsList[index].classification_id = value?.classification_id
      newRABillDetailsList[index].classification_name = value?.classification_name
      newRABillDetailsList[index].chapterList = []
      newRABillDetailsList[index].boqList = []

      newRABillDetailsList[index].chapter_name = ""
      newRABillDetailsList[index].chapter_id = ""

      newRABillDetailsList[index].itemList = []
      newRABillDetailsList[index].item_code = ''
      newRABillDetailsList[index].code = ''
      newRABillDetailsList[index].quantity = ''
      newRABillDetailsList[index].rate = ''
      newRABillDetailsList[index].name = ''
      newRABillDetailsList[index].unit_name = ''
      newRABillDetailsList[index].unit_id = ''
      newRABillDetailsList[index].bml_item_id = ''

      setRaBillDetailsList(newRABillDetailsList)
      if (checkValidation(value?.construction_name)) {
        getProjectBoQDetails(value?.constructionname_id, index)
      }

    } else if (type === "BoQ Name") {
      newRABillDetailsList[index].boq_name = value?.name
      newRABillDetailsList[index].chapterList = []

      newRABillDetailsList[index].chapter_name = ""
      newRABillDetailsList[index].chapter_id = ""

      newRABillDetailsList[index].itemList = []
      newRABillDetailsList[index].item_code = ''
      newRABillDetailsList[index].code = ''
      newRABillDetailsList[index].quantity = ''
      newRABillDetailsList[index].rate = ''
      newRABillDetailsList[index].name = ''
      newRABillDetailsList[index].unit_name = ''
      newRABillDetailsList[index].unit_id = ''
      newRABillDetailsList[index].bml_item_id = ''

      setRaBillDetailsList(newRABillDetailsList)
      if (checkValidation(value?.name)) {
        getAllSectionListById(newRABillDetailsList[index]?.classification_id, index)
      }

    } else if (type === "Chapter Name") {
      newRABillDetailsList[index].chapter_name = value?.chapter_name
      newRABillDetailsList[index].chapter_id = value?.chapter_id

      newRABillDetailsList[index].itemList = []
      newRABillDetailsList[index].bml_item_id = ''
      newRABillDetailsList[index].item_code = ''
      newRABillDetailsList[index].code = ''
      newRABillDetailsList[index].quantity = ''
      newRABillDetailsList[index].rate = ''
      newRABillDetailsList[index].name = ''
      newRABillDetailsList[index].unit_name = ''
      newRABillDetailsList[index].unit_id = ''

      setRaBillDetailsList(newRABillDetailsList)
      if (checkValidation(value?.chapter_name)) {
        getAllBuildUpItemistForConstructionNameBOQByCriteria(value?.chapter_id, index)
      }

    } else if (type === "Code") {
      if (value !== "" && value !== null && value !== undefined) {
        const params = {
          "project_location_id": state.project_location_id,
          "constructionname_id": newRABillDetailsList[index].constructionname_id,
          "boq_name": newRABillDetailsList[index].boq_name,
          "item_code": value?.code
        }
        checkItemPresentInRunningAccountBillByParams(params, index, value)

      } else {
        newRABillDetailsList[index].item_code = ''
        newRABillDetailsList[index].bml_item_id = ''
        newRABillDetailsList[index].code = ''
        newRABillDetailsList[index].quantity = ''
        newRABillDetailsList[index].rate = ''
        newRABillDetailsList[index].name = ''
        newRABillDetailsList[index].unit_name = ''
        newRABillDetailsList[index].unit_id = ''
        setRaBillDetailsList(newRABillDetailsList)

      }

    }
    else if (type === "New Quantity") {
      if (value !== "" && value !== null && value !== undefined) {
        newRABillDetailsList[index].new_quantity = value
      } else {
        newRABillDetailsList[index].new_quantity = '+'
      }
      setRaBillDetailsList(newRABillDetailsList)
    } else if (type === "Deviation Quantity") {
      if (value !== "" && value !== null && value !== undefined) {
        newRABillDetailsList[index].deviation_quantity = value
      } else {
        newRABillDetailsList[index].deviation_quantity = '+'
      }
      setRaBillDetailsList(newRABillDetailsList)
    } else if (type === "Revised Quantity") {
      if (value !== "" && value !== null && value !== undefined) {
        newRABillDetailsList[index].revised_quantity = value
      } else {
        newRABillDetailsList[index].revised_quantity = '+'
      }
      setRaBillDetailsList(newRABillDetailsList)
    } else if (type === "Rate") {
      if (checkValidation(value)) {
        newRABillDetailsList[index].rate = value
        newRABillDetailsList[index].amount = getTwoDigitDecimal(value) * newRABillDetailsList[index].quantity
      } else {
        newRABillDetailsList[index].rate = ""
      }
      setRaBillDetailsList(newRABillDetailsList)
    } else if (type === "Amount") {
      if (value !== "" && value !== null && value !== undefined) {
        newRABillDetailsList[index].amount = value
      } else {
        newRABillDetailsList[index].amount = '+'
      }
      setRaBillDetailsList(newRABillDetailsList)
    }
  }

  const onClickAddNewRow = () => {

    if (checkValidation(raBillDetailsList) === false) {
      setRaBillDetailsList([{}])
    } else {
      setRaBillDetailsList([...raBillDetailsList, {}])
    }

  }

  const handleDeleteClick = (raBillCell, index) => {
    if (checkValidation(raBillCell?.rabillamendment_details_id)) {
      setRaBillDetails(raBillCell)
      setSelectedRABillDetailsIndex(index)
      setShouldOpenConfirmationDialog(true)
    } else {
      deleteRABillDetailsItem(index)
    }
  }

  const deleteRABillDetailsItem = (index) => {
    const newRABillDetailsList = [...raBillDetailsList]
    const filteredArray = newRABillDetailsList?.filter((item, itemIndex) => {
      return itemIndex !== index
    });
    setRaBillDetailsList(filteredArray)
  }

  const onClickSaveButton = () => {
    if (raBillDetailsList.length === 0) {
      const key = enqueueSnackbar("Please add at least one item to proceed", { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
      return
    }
    const filteredArray = raBillDetailsList.filter(raBill => {
      return raBill.item_code === "" || raBill.rate === "" ||
        raBill.item_code === undefined || raBill.rate === undefined || raBill.item_code === null || raBill.rate === null
    })
    console.log('my filter', filteredArray)
    if (filteredArray.length > 0) {
      const key = enqueueSnackbar('Please fill all RA Bill Amendment details', { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
      return
    }
    const newRABillDetailsList = [...raBillDetailsList]
    var i = 0;
    while (i < newRABillDetailsList.length) {
      newRABillDetailsList[i].runningaccountbill_id = raBill.runningaccountbill_id
      i++;
    }
    saveOrUpdateRABill()
  }

  const getAllSectionListById = (id, index) => {
    setLoading(true)
    getAllSectionListByClassificationId(id).then((res) => {
      setLoading(false)
      if (res?.status < 300) {
        const filteredArray = res.data.filter(section => {
          return section.section_name === "Section 2"
        });
        getAllChapterList(id, filteredArray[0].section_id, index)
      } else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      }
    }).catch(function (error) {
      setLoading(false)
      const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    })
  }


  const getAllChapterList = (classification_id, section_id, index) => {
    setLoading(true)
    getAllChapterListById({ classification_id: classification_id, section_id: section_id }).then((res) => {
      setLoading(false)
      if (res?.status < 300) {
        const newRABillDetailsList = [...raBillDetailsList]
        newRABillDetailsList[index].chapterList = res?.data?.obj
        setRaBillDetailsList(newRABillDetailsList)
      } else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      }
    }).catch(function (error) {
      setLoading(false)
      const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);

    })
  }

  const getAllBuildUpItemistForConstructionNameBOQByCriteria = (chapter_id, index) => {
    setLoading(true)
    getAllBuildUpItemistForConstructionNameBOQ(raBill?.project_location_id, chapter_id).then((res) => {
      setLoading(false)
      if (res?.status < 300) {
        const newRABillDetailsList = [...raBillDetailsList]
        newRABillDetailsList[index].itemList = res?.data
        setRaBillDetailsList(newRABillDetailsList)
      } else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      }
    }).catch(function (error) {
      setLoading(false)
      const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    })
  }


  const saveOrUpdateRABill = () => {
    setLoading(true)
    saveOrUpdateRaBillAmendment(createParamsFoRABillAmendment()).then((res) => {
      setLoading(false)
      if (res?.status < 300) {
        setShouldOpenRaBillDetailsList(false)
        handleSuccess()
        //const key = enqueueSnackbar(res?.data?.message, { variant: 'success' });
        const key = enqueueSnackbar("Item details saved successfully. Please enter DoM if not yet entered.", { variant: 'success' });
        SnackBarProperty.success(key, closeSnackbar);
        getAllRaBillAmendmentDetails(raBill?.runningaccountbill_id, state?.project_location_id)
      } else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      }
    }).catch(function (error) {
      setLoading(false)
      const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    })
  }

  function createParamsFoRABillAmendment() {

    const newRaBillDetailsList = [...raBillDetailsList]
    var i = 0;
    while (i < newRaBillDetailsList?.length) {
      if (checkValidation(raBillDetails?.rabillamendment_id)) {
        newRaBillDetailsList[i].rabillamendment_id = raBillDetails?.rabillamendment_id
      }
      i++;
    }
    const params = {
      runningaccountbill_id: raBill.runningaccountbill_id,
      project_location_id: raBill.project_location_id,
      amendment_date: checkValidation(raBillDetails?.amendment_date) ? dayjs(raBillDetails?.amendment_date).format("YYYY-MM-DDT00:00:00") : dayjs().format("YYYY-MM-DDT00:00:00"),
      amendment_no: checkValidation(raBillDetails?.amendment_no) ? raBillDetails?.amendment_no : raBill?.rabill_no,
      amendment_amount: calculateAmendmentAmount(),
      raBillAmendmentDetails: newRaBillDetailsList

    }
    if (checkValidation(raBillDetails?.rabillamendment_id)) {
      params.rabillamendment_id = raBillDetails?.rabillamendment_id
    }
    return params
  }

  function calculateAmendmentAmount() {
    if ((raBillDetailsList?.length ?? 0) === 0) {
      return 0
    } else {
      const newRABillDetailsList = [...raBillDetailsList]
      const totalCost = newRABillDetailsList?.reduce(
        (previousValue, currentValue, index) => previousValue + getTwoDigitDecimal(currentValue?.amount), 0);
      return getTwoDigitDecimal(totalCost)
    }
  }

  const onClickCancelButton = () => {
    handleClose()
  }

  function multiplyValues(item_no, length, breadth, height) {
    if (checkValidation(height) === false && (checkValidation(item_no) === false)
      && (checkValidation(length) === false) && (checkValidation(breadth) === false)) {
      return ""
    }

    var itemNoFloated = 0
    if (checkValidation(item_no) === false) {
      itemNoFloated = 1
    } else {
      itemNoFloated = parseFloat(item_no)
    }

    var heightFloated = 0
    if (checkValidation(height) === false) {
      heightFloated = 1
    } else {
      heightFloated = parseFloat(height)
    }


    var lengthFloated = 0
    if (checkValidation(length) === false) {
      lengthFloated = 1
    } else {
      lengthFloated = parseFloat(length)
    }

    var breadthFloated = 0
    if (checkValidation(breadth) === false) {
      breadthFloated = 1
    } else {
      breadthFloated = parseFloat(breadth)
    }

    return (itemNoFloated * heightFloated * lengthFloated * breadthFloated)

  }

  const handleDomItemChange = (type, index, value) => {

    const newDomArray = [...domList]

    if (type === 'item_no') {
      if (validateTwoDigitDecimal(value) === false) { return }
      newDomArray[index].item_no = value
      newDomArray[index].quantity = getTwoDigitDecimal(multiplyValues(newDomArray[index].item_no, newDomArray[index].length, newDomArray[index].breadth, newDomArray[index].height))
    } else if (type === 'length') {
      if (validateTwoDigitDecimal(value) === false) { return }
      newDomArray[index].length = value
      newDomArray[index].quantity = getTwoDigitDecimal(multiplyValues(newDomArray[index].item_no, newDomArray[index].length, newDomArray[index].breadth, newDomArray[index].height))
    } else if (type === 'breadth') {
      if (validateTwoDigitDecimal(value) === false) { return }
      newDomArray[index].breadth = value
      newDomArray[index].quantity = getTwoDigitDecimal(multiplyValues(newDomArray[index].item_no, newDomArray[index].length, newDomArray[index].breadth, newDomArray[index].height))
    } else if (type === 'height') {
      if (validateTwoDigitDecimal(value) === false) { return }
      newDomArray[index].height = value
      newDomArray[index].quantity = getTwoDigitDecimal(multiplyValues(newDomArray[index].item_no, newDomArray[index].length, newDomArray[index].breadth, newDomArray[index].height))
    } else if (type === 'item_sign') {
      newDomArray[index].item_sign = value
    }
    // else if (type === 'quantity') {
    //   newDomArray[index].quantity = value
    // }
    else if (type === 'remarks') {
      newDomArray[index].remarks = value
    }
    calculateSubTotalQuantity(newDomArray)
    setDomList(newDomArray)
  }

  const calculateSubTotalQuantity = (newDomArray) => {
    var amount = 0
    newDomArray.forEach((dom, index) => {
      if (dom?.item_sign === "-") {
        amount += -parseFloat(dom?.quantity ?? 0)
      } else {
        amount += parseFloat(dom?.quantity ?? 0)
      }
    });
    console.log("Sub Total", amount)
    setSubTotalDomQuantity(getTwoDigitDecimal(amount))
  }

  useEffect(() => {
    if (shouldShowDOM === true) {
      goToViolation("manage_dom")
    }
  }, [shouldShowDOM]);

  const onClickAddNewDomRow = () => {
    console.log('dom listr is', domList)
    if (domList?.length > 0) {
      setDomList([...domList, {}])
    } else {
      setDomList([{}])
    }
  }

  const onClickCloseDom = () => {
    setShouldShowDOM(false)
  }

  const handleDomDeleteClick = (id, index) => {
    if (id !== null && id !== undefined && id !== "") {
      setShouldOpenDOMDeleteDialog(true)
      setDomDetailsData({ id: id, index: index })
      //deleteBoqDetails(id, index)
    } else {
      deleteDomItem(index)
    }
  }

  const deleteDomItem = (index) => {
    const newDomArray = [...domList]
    const filteredArray = newDomArray?.filter((item, itemIndex) => {
      return itemIndex !== index
    });
    setDomList(filteredArray)
  }

  const handleDOMDialogClose = () => {
    setShouldOpenDOMDeleteDialog(false)
  }

  const handleDOMConfirmationResponse = () => {
    deleteBoqDOM(domDetailsData?.id, domDetailsData?.index)
  }

  const deleteBoqDOM = (id, index) => {
    setLoading(true)
    deleteRaBillAmendmentDetailsDOM(id).then((res) => {
      setLoading(false)
      if (res?.status < 300) {
        deleteDomItem(index)
        setShouldOpenDOMDeleteDialog(false)
        const key = enqueueSnackbar(res?.data?.message, { variant: 'success' });
        SnackBarProperty.success(key, closeSnackbar);

      } else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      }
    }).catch(function (error) {
      setLoading(false)
      const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);

    })
  }

  const onClickSaveDOMDetails = () => {
    console.log('my dom list', domList)
    const newDomArray = [...domList]

    if (newDomArray?.length === 0) {
      const key = enqueueSnackbar("Please add at least one item", { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
      return
    }

    const filteredArray = newDomArray?.filter(dom => {
      return ((dom.height === undefined || dom.height === '' || dom.height === null)
        && (dom.item_no === undefined || dom.item_no === '' || dom.item_no === null)
        && (dom.length === undefined || dom.length === '' || dom.length === null)
        && (dom.breadth === undefined || dom.breadth === '' || dom.breadth === null))

    });

    if (filteredArray.length > 0) {
      const key = enqueueSnackbar("Please fill all DOM details", { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
      return
    }

    if (subTotalDomQuantity <= 0) {
      const key = enqueueSnackbar("Please check your dom quantity", { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
      return
    }


    var i = 0;
    while (i < newDomArray?.length) {
      newDomArray[i].rabillamendment_details_id = raBillDetails.rabillamendment_details_id
      i++;
    }

    saveUpdateBoqDOM(newDomArray)
  }

  const saveUpdateBoqDOM = (params) => {
    setLoading(true)
    saveRaBillAmendmentDetailsDOM(params).then((res) => {
      setLoading(false)
      if (res?.status < 300) {
        setShouldOpenRaBillDetailsList(true)
        // setShouldShowDOM(false)
        const key = enqueueSnackbar(res?.data?.message, { variant: 'success' });
        SnackBarProperty.success(key, closeSnackbar);

        getAllRaBillAmendmentDetails(raBill?.runningaccountbill_id, state?.project_location_id)
      } else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      }
    }).catch(function (error) {
      setLoading(false)
      const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);

    })
  }

  const handleClickManageDom = (raBill) => {
    setShouldShowDOM(false)
    setRaBillDetails(raBill)
    setShouldShowDOM(true)
    setDomList(raBill?.raBillAmendmentDetailsDOM)
  }

  const handleClickNewConstructionType = () => {
    setdialogTitle("Add Construction Type")
    setShouldOpenConstructionTypeDialog(true)
  }

  const handleDetailsClick = (billDetails, index) => {
    console.log('print', index)
    setRaBillDetails(billDetails)
    const params = {
      runningaccountbill_id: raBill.runningaccountbill_id,
      // runningaccountbill_details_id: billDetails.rabillamendment_details_id,
      // rabillamendment_id: billDetails?.rabillamendment_id,
      rabillamendment_details_id: billDetails?.rabillamendment_details_id,
      bml_item_id: billDetails.bml_item_id,
      projectlocation_id: state.project_location_id,
      item_code: billDetails.item_code,
    }

    getRaBillAmendmentRateAnalysisByParams(params)

  }

  const getRaBillAmendmentRateAnalysisByParams = (params) => {
    setLoading(true)
    getRaBillAmendmentRateAnalysis(params).then((res) => {
      setLoading(false)
      if (res?.status < 300 && res?.data?.success === true) {
        if (checkValidation(res?.data?.obj?.rabillamendmentrateanalysis_id) === true) {
          setRaBillRateDetails(res?.data?.obj?.raBillAmendmentRaItem?.raBillAmendmentRaItemDetailsList)
          setRateAnalysisDetails(res?.data?.obj?.raBillAmendmentRaItem)
          setShouldOpenDetailsDialog(true)
        } else {
          setRaBillRateDetails(res?.data?.obj?.projectLocationBsrBuiltupItemsDetailsList)
          setRateAnalysisDetails(res?.data?.obj)
          setShouldOpenDetailsDialog(true)
        }

      } else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      }
    }).catch(function (error) {
      setLoading(false)
      const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    })
  }

  useEffect(() => {
    setState({ ...project })
  }, [project]);

  useEffect(() => {
    // isUpdated === false && 
    if (domList?.length > 0) {
      console.log("dom list:", domList)
      calculateSubTotalQuantity(domList)
    }
  }, [domList])

  const handleRABillRateSuccessDialog = () => {
    setShouldOpenDetailsDialog(false)
    getAllRaBillAmendmentDetails(raBill.runningaccountbill_id, state.project_location_id)
  }

  const handleRABillRateCloseDialog = () => {
    setShouldOpenDetailsDialog(false)
  }

  const handleDetailsDeleteConfirmationResponse = () => {
    deleteRaBillAmendmentDetailsByParams(raBillDetails?.rabillamendment_details_id, selectedRABillDetailsIndex)
  }

  const handleDetailsDeleteDialogClose = () => {
    setShouldOpenConfirmationDialog(false)
  }

  const deleteRaBillAmendmentDetailsByParams = (id, index) => {
    setLoading(true)
    deleteRaBillAmendmentDetails(id).then((res) => {
      setLoading(false)
      if (res?.status < 300) {
        deleteRABillDetailsItem(index)
        setShouldOpenConfirmationDialog(false)
        const key = enqueueSnackbar(res?.data?.message, { variant: 'success' });
        SnackBarProperty.success(key, closeSnackbar);

      } else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      }
    }).catch(function (error) {
      setLoading(false)
      const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);

    })
  }

  const handleClickOpenNewBmlItem = () => {
    const url = `${window.location.origin}/ManageBSR?project_id=${project?.project_id}`
    window.open(url, '_blank');
  }


  return (
    <Dialog open={open} maxWidth="xl" fullWidth={true}>
      <Box p={3}>
        <H4 sx={{ mb: "20px" }}><b>RA Bill Amendment Details</b></H4>
        <H3 sx={{ mb: "20px" }}>RA Bill No: {raBill?.rabill_no}</H3>

        {isUpdated && <div>

          <Button
            sx={{ mb: 2, ml: 2 }}
            color="secondary"
            variant="contained"
            title="Add new construction type"
            onClick={() => handleClickNewConstructionType()}
          >
            <Icon>control_point</Icon>&nbsp;  Construction Type
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            sx={{ mb: 2 }}
            color="secondary"
            variant="contained"
            title="Add new construction name"
            onClick={() => handleClickOpen()}
          >
            <Icon>control_point</Icon>&nbsp; Construction Name
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            sx={{ mb: 2 }}
            color="secondary"
            variant="contained"
            title="Add new bml/builtup item"
            onClick={() => handleClickOpenNewBmlItem()}
          >
            <Icon>control_point</Icon>&nbsp; New Item
          </Button>
        </div>}


        <Card sx={{ width: "100%", overflow: "auto" }} elevation={6}>
          <div style={{ overflowX: "auto", marginBottom: 15 }}>
            <ThemeProvider theme={theme}>
              <MUIDataTable
                sx={{
                  backgroundColor: "yellow",
                  borderBottom: "2px solid black",
                  "& th": {
                    fontSize: "1.25rem",
                    color: "rgba(96, 96, 96)"
                  }
                }}
                data={raBillDetailsList}
                columns={raBillAmendmentColumns}
                title={""}
                options={{
                  fixedHeader: true,
                  responsive: 'scroll',
                  selectableRows: "none", // set checkbox for each row
                  filterType: "textField",
                  pagination: false, //set pagination option
                  rowsPerPageOptions: [10, 15, 20, 25, 50, 100],
                  search: false, // set search option
                  filter: false, // set data filter option
                  download: false, // set download option
                  print: false, // set print option
                  viewColumns: false
                }}
              />
            </ThemeProvider>
          </div>
          <h4>&nbsp;Total Amendment Amount (Nu): {currencyFormat(calculateAmendmentAmount())}</h4>
          <div>
            {isUpdated && <Box component="span" m={1} display="flex" justifyContent="flex-end" >

              <Fab size="small" color="secondary" variant="contained" title="Add new row" onClick={() => onClickAddNewRow()}>
                <Icon>add</Icon>
              </Fab>
            </Box>
            }

            <Box component="span" display="flex" justifyContent="flex-end" >
              {isUpdated && <Button size="medium" color="primary" variant="contained" type="submit" onClick={() => onClickSaveButton()}>Save</Button>}
              &nbsp;<Button size="medium" color="error" variant="contained" onClick={() => onClickCancelButton()}>Close</Button>&nbsp;
            </Box>
          </div>
          <br />
        </Card>
        {
          shouldShowDOM && (

            <div className="manage_dom">
              <Card sx={{ width: "100%", marginTop: 5, overflow: "auto" }} elevation={6}>
                <Grid container spacing={5}>
                  <Grid sx={{ marginLeft: 2 }} item md={12} sm={12} xs={12}>
                    <h2 >Manage DoM</h2>
                    <h4>Item Code: {raBillDetails?.item_code}</h4>
                  </Grid>
                </Grid>
                <Box width="100%" overflow="auto">
                  <MyTable>
                    <TableHead style={{ backgroundColor: "#87CEEB" }} >
                      <TableRow >
                        <TableCell align="center" width="5%">Sl No.</TableCell>
                        <TableCell align="center" width="8%">No.</TableCell>
                        <TableCell align="center" width="10%">Length</TableCell>
                        <TableCell align="center" width="10%">Breadth</TableCell>
                        <TableCell align="center" width="10%">Height</TableCell>
                        <TableCell align="center" width="5%">Deductions</TableCell>
                        <TableCell align="center" width="8%">Quantity</TableCell>
                        <TableCell align="center" width="8%">Remarks</TableCell>
                        {isUpdated && <TableCell align="center" width="8%">Action</TableCell>}

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {domList?.map((dom, index) => (
                        <TableRow>
                          <TableCell sx={{ px: 0 }} align="center">
                            {index + 1}
                          </TableCell>

                          <TableCell sx={{ px: 0 }} align="center">
                            <DecimalInputField
                              size="small"
                              label="No."
                              id="item_no"
                              onKeyDown={(event) => {
                                if (event?.code === "ArrowDown" || event?.code === "ArrowUp" ||
                                  event?.key === "-" || event?.key === "+" || event?.key?.toLowerCase() === "e" || event?.key?.toLowerCase() === ".") {
                                  event.preventDefault();
                                }

                              }}
                              variant="outlined"
                              fullWidth
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              name="item_no"
                              value={dom?.item_no || ""}
                              onChange={e =>
                                handleDomItemChange('item_no', index, e.target.value)
                              }
                            ></DecimalInputField>
                          </TableCell>


                          <TableCell sx={{ px: 0 }} align="center">
                            <DecimalInputField
                              size="small"
                              label="Length"
                              id="length"
                              variant="outlined"
                              fullWidth
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              onKeyDown={(event) => {
                                if (event?.code === "ArrowDown" || event?.code === "ArrowUp" ||
                                  event?.key === "-" || event?.key === "+" || event?.key?.toLowerCase() === "e") {
                                  event.preventDefault();
                                }

                              }}
                              name="length"
                              value={dom?.length || ""}
                              onChange={e =>
                                handleDomItemChange('length', index, e.target.value)
                              }
                            >
                            </DecimalInputField>
                          </TableCell>

                          <TableCell sx={{ px: 0 }} align="center">
                            <DecimalInputField
                              size="small"
                              label="Breadth"
                              id="breadth"
                              variant="outlined"
                              fullWidth
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              name="breadth"
                              onKeyDown={(event) => {
                                if (event?.code === "ArrowDown" || event?.code === "ArrowUp" ||
                                  event?.key === "-" || event?.key === "+" || event?.key?.toLowerCase() === "e") {
                                  event.preventDefault();
                                }

                              }}
                              value={dom?.breadth || ""}
                              onChange={e =>
                                handleDomItemChange('breadth', index, e.target.value)
                              }
                            >
                            </DecimalInputField>
                          </TableCell>



                          <TableCell sx={{ ml: 0 }} align="center">
                            <DecimalInputField
                              size="small"
                              label="Height"
                              id="height"
                              variant="outlined"
                              fullWidth
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              name="height"
                              onKeyDown={(event) => {
                                if (event?.code === "ArrowDown" || event?.code === "ArrowUp" ||
                                  event?.key === "-" || event?.key === "+" || event?.key?.toLowerCase() === "e") {
                                  event.preventDefault();
                                }

                              }}
                              value={dom?.height || ""}
                              onChange={e =>
                                handleDomItemChange('height', index, e.target.value)
                              }
                            >
                            </DecimalInputField>
                          </TableCell>
                          <TableCell sx={{ px: 0 }} align="center">
                            <TextField
                              select
                              size="small"
                              id="item_sign"
                              variant="outlined"
                              label=""
                              fullWidth
                              name="item_sign"
                              value={dom?.item_sign}
                              defaultValue="+"
                              onChange={e =>
                                handleDomItemChange('item_sign', index, e.target.value)
                              }
                            >
                              {deductionsList?.map((item) => (
                                <MenuItem value={item} key={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </TextField>
                          </TableCell>
                          <TableCell sx={{ ml: 0 }} align="center">
                            {dom?.quantity ?? ''}
                            

                          </TableCell>

                          <TableCell sx={{ px: 0 }} align="center">
                            <TextField
                              size="small"
                              label="Remarks"
                              id="remarks"
                              variant="outlined"
                              fullWidth
                              name="remarks"
                              value={dom?.remarks || ""}
                              onChange={e =>
                                handleDomItemChange('remarks', index, e.target.value)
                              }
                            >
                            </TextField>

                          </TableCell>

                          {isUpdated && <TableCell sx={{ px: 0 }} align="center">
                            <Tooltip title="Remove">
                              <IconButton onClick={() => handleDomDeleteClick(dom?.rabillamendment_details_dom_id, index)}>
                                <Icon color="error" fontSize="small">
                                  delete
                                </Icon>
                              </IconButton>
                            </Tooltip>
                          </TableCell>}
                        </TableRow>
                      ))}
                    </TableBody>
                  </MyTable>
                  {isUpdated && <Box component="span" m={1} display="flex" justifyContent="flex-end" >
                    <Fab size="small" color="secondary" variant="contained" title="Add new row" onClick={() => onClickAddNewDomRow()}>
                      <Icon>add</Icon>
                    </Fab>
                  </Box>}
                  <h4 style={{ margin: 15 }}>Sub Total Quantity: {subTotalDomQuantity}</h4>
                  <Box component="span" m={1} display="flex" justifyContent="flex-end" >
                    {isUpdated && <Button size="medium" color="primary" variant="contained" onClick={() => onClickSaveDOMDetails()}>Save</Button>}
                    &nbsp;<Button size="medium" color="error" variant="contained" onClick={() => onClickCloseDom()}>Close</Button>
                  </Box>
                </Box>
              </Card>
            </div>

          )
        }

        {shouldOpenConfirmationDialog && (
          <ConfirmationDialog
            text="Are you sure to delete?"
            open={shouldOpenConfirmationDialog}
            onConfirmDialogClose={handleDetailsDeleteDialogClose}
            onYesClick={handleDetailsDeleteConfirmationResponse}
          />
        )}

        {
          shouldOpenDOMDeleteDialog && (
            <ConfirmationDialog
              text="Are you sure to delete?"
              open={shouldOpenDOMDeleteDialog}
              onConfirmDialogClose={handleDOMDialogClose}
              onYesClick={handleDOMConfirmationResponse}
            />
          )
        }
        {
          shouldOpenConstructionTypeDialog && (
            <NewConstructionTypeDialog
              title={dialogTitle}
              constructiontype={constructionType}
              open={shouldOpenConstructionTypeDialog}
              handleClose={handleConstructionTypeDialogClose}
              handleSuccess={handleConstructionTypeDialogSuccess}
            />
          )
        }
        {
          shouldOpenConstructionNameDialog && (
            <NewConstructionNameDialog
              title={dialogTitle}
              parentState={parentState}
              constructionName={constructionName}
              classificationList={classificationList}
              projectLocationList={projectLocationList}
              constructionTypeList={constructionTypeList}
              open={shouldOpenConstructionNameDialog}
              handleClose={handleConstructionDialogClose}
              handleSuccess={handleConstructionNameSuccess}
            />
          )
        }

        {shouldOpenDetailsDialog && (
          <RABillAmendmentRateDetailsDialog
            isUpdated={true}
            title={dialogTitle}
            // isEvaluate={false}
            parentRaBill={raBill}
            raBillDetails={raBillDetails}
            rateAnalysisDetails={rateAnalysisDetails}
            raBillRateDetails={raBillRateDetails}
            open={shouldOpenDetailsDialog}
            handleClose={handleRABillRateCloseDialog}
            handleSuccess={handleRABillRateSuccessDialog}
          />
        )}
        {loading && (
          <Loader
            show={loading}
          />
        )}
      </Box>
    </Dialog>

  );
};

export default RABillAmendmentDialog;


