import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
  } from '@material-ui/core';
  import { CloudDownload, ErrorOutline, Print } from "@mui/icons-material";
  import { Box, Button, Card, FormControlLabel, FormLabel, Grid, Icon, MenuItem, Radio, RadioGroup, TableFooter, TextField, styled } from "@mui/material";
  import { Loader } from "app/components";
  import { getAllConstructionNameListByParameters, getAllProjectLocationListForReports } from "app/redux/actions/ProjectLocationActions";
  import { getConstructionNameBOQContractReport, getConstructionNameBOQEstimateReport, getProjectConstructionNameBOQDOMReport } from "app/redux/actions/ReportsAction";
  import { AppConstants } from "app/utils/AppConstants";
  import { ServiceUrl } from 'app/utils/Constants';
  import { SnackBarProperty } from "app/utils/SnackBarProperty";
  import { checkValidation, currencyFormat, formatNumberWithCommasAndDecimals, getTwoDigitDecimal } from "app/utils/utils";
  import { SimpleCard } from "components";
  import html2canvas from "html2canvas";
  import jsPDF from "jspdf";
  import 'jspdf-autotable';
  import { useSnackbar } from "notistack";
  import React, { useEffect, useState } from "react";
  import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
  import { useDispatch, useSelector } from "react-redux";
  
  
  const styles = {
    tableCell: {
      textAlign: "center"
    },
    subHeader: {
      textAlign: "center",
      fontWeight: 700
    }
  
  }
  const DecoratedTextField = styled(TextValidator)(() => ({
    width: "100%",
    marginBottom: "16px",
  }));
  
  
  
  
  export const ConstructionNameBoQDomReport = () => {
  
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [reportType, setReportType] = useState("estimate")
    const [isDownloading, setIsDownloading] = useState(false)
    const [isPrinting, setIsPrinting] = useState(false)
    const [constructionNameList, setConstructionNameList] = useState([])
    const [projectDetails, setProjectDetails] = useState()
    const [totalAmount, setTotalAmount] = useState()
    const { reportsProjectLocationList } = useSelector((state) => state.projectLocation);
    // const {userList}=useSelector((state)=>state.userList)
    const [tableData, setTableData] = useState()
      ;
    const [state, setState] = useState({
      project_location_id: '',
      constructionname_id: ''
    })
  
  
  
    useEffect(() => {
      dispatch(getAllProjectLocationListForReports(showLoader, hideLoader, showToast))
    }, []);
    const showLoader = () => {
      setLoading(true)
    }
    const showToast = (msg) => {
      const key = enqueueSnackbar(msg ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    }
  
    const hideLoader = () => {
      setLoading(false)
    }
    const handleChange = (event, source) => {
  
      if (event.target.name === "project_location_id") {
        const filteredArray = reportsProjectLocationList.filter(project => {
          return project.project_location_id === event.target.value
        });
        state.project_name = filteredArray[0].project_name
        state.project_location_id = filteredArray[0].project_location_id
        state.project_id = filteredArray[0].project_id
        setConstructionNameList([])
        setState({ ...state, [event.target.name]: event.target.value, constructionname_id: '' });
  
        //dispatch(getAllConstructionNameListByParams(0, filteredArray[0].project_location_id, showLoader, hideLoader, showToast))
        getAllConstructionNameList(0, filteredArray[0].project_location_id, filteredArray[0].project_id)
  
      } else {
        const filteredArray = constructionNameList.filter(project => {
            return project.constructionname_id === event.target.value
          });
        state.construction_name = filteredArray[0].construction_name
        setState({ ...state, [event.target.name]: event.target.value });
      }
    }
    const getAllConstructionNameList = (project_location_id, constructionname_id, project_id) => {
      setLoading(true)
      getAllConstructionNameListByParameters(project_location_id, constructionname_id).then((res) => {
        setLoading(false)
        if (res?.status < 300) {
          // if (res?.data?.length > 0) {
          //     state.constructionname_id = res?.data[0].constructionname_id
          //     state.construction_name = res?.data[0].construction_name 
          //     state.project_id = project_id
          //     setState({ ...state, ['boq_code']: res?.data[0].boq_code });
          // }
          setConstructionNameList(res?.data)
        } else {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);
  
        }
      }).catch(function (error) {
        setLoading(false)
        const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);
  
      })
    }

    const calculateSubTotalQuantity = (newDomArray) => {
        var quantity = 0
        newDomArray.forEach((dom, index) => {
            if (dom?.item_sign === "-") {
                quantity += -(dom?.quantity)
            } else {
                quantity += dom?.quantity
            }
        });
        return quantity
    }
  
    const handleFormSubmit = () => {
      setTableData([])
      setLoading(true)
    //   if (reportType === "estimate") {
        const params = { constructionname_id: state?.constructionname_id, projectlocation_id: state?.project_location_id }
        getProjectConstructionNameBOQDOMReport(params).then((res) => {
            setLoading(false)
            if (res?.status < 300) {
              if (res?.data?.obj && Object?.keys(res?.data?.obj)?.length > 1) {
                setTableData(res?.data?.obj?.item)
                setProjectDetails({
                  construction_name: state?.construction_name,
                  project_location: state?.project_location,
                  project_name: state?.project_name,
                  project_id: state?.project_id,
                  header: res?.data?.obj?.header_logo,
                  footer: res?.data?.obj?.footer_logo,
                })
                // var rowIndex = 0
                // const tableDataList = res?.data?.obj?.stageInformationList?.map((elem) => {
                //   const stage_name = elem?.stage_name
                //   const getTotalStageWise = (dataList) => {
                //     const totalOfStage = dataList.reduce((acc, item) => acc + (getTwoDigitDecimal(item?.rate) * getTwoDigitDecimal(item?.quantity)), 0)
                //     console.log("total of stage:", totalOfStage)
                //     return totalOfStage
                //   }
                //   const dataList = elem?.constructionNameBOQDetailsList?.map((item, index) => {
                //     rowIndex+=1
                //     return {
                //       sl_no: rowIndex,
                //       item_no: item?.item_code,
                //       length: item?.item_description,
                //       breadth: item?.quantity,
                //       height: item?.unit_name,
                //       item_sign: item?.item_sign,
                //       quantity: getTwoDigitDecimal(item?.quantity),
                //       remarks: item?.remarks
  
                //     }
                //   })
                //   const obj = {
                //     stageName: stage_name,
                //     data: dataList,
                //     total: getTotalStageWise(dataList)
                //   }
                //   return obj
                // })
                // const TotalAmount = res?.data?.obj?.stageInformationList?.reduce((accumulator, stage) => {
                //   const constructionNameBOQDetailsList = stage.constructionNameBOQDetailsList;
                //   const amountSum = constructionNameBOQDetailsList.reduce((acc, item) => acc + (item?.quantity * item?.bsr_rate), 0);
                //   return accumulator + amountSum;
                // }, 0);
                // setTotalAmount(TotalAmount)
                // setProjectDetails({
                //   construction_name: res?.data?.obj?.construction_name,
                //   project_location: res?.data?.obj?.project_location,
                //   project_name: res?.data?.obj?.project_name,
                //   dzongkhag_name: res?.data?.obj?.dzongkhagName,
                //   bsr_year: res?.data?.obj?.bsr_year,
                //   header: res?.data?.obj?.header_logo,
                //   footer: res?.data?.obj?.footer_logo,
                //   labour_costindex: res?.data?.obj?.labour_costindex,
                //   material_costindex: res?.data?.obj?.material_costindex,
                //   cost_index_amount: res?.data?.obj?.cost_index_amount,
                //   classification_name: res?.data?.obj?.classification_name,
                // })
                // setTableData(tableDataList)
              }
              else {
                const key = enqueueSnackbar(res?.data?.message, { variant: 'info' });
                SnackBarProperty.info(key, closeSnackbar);
              }
            }
            else {
              const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
              SnackBarProperty.error(key, closeSnackbar);
  
            }
          }).catch(error => {
            setLoading(false)
  
            const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
            SnackBarProperty.error(key, closeSnackbar);
  
          })
    //   }
    //   else {
    //     getConstructionNameBOQContractReport({ construction_name_id: state?.constructionname_id, project_location_id: state?.project_location_id })
    //       .then((res) => {
    //         setLoading(false)
  
    //         if (res?.status < 300) {
    //           console.log(res.data)
    //           if (res?.data?.obj) {
    //             const tableDataList = res?.data?.obj?.stageInformationList?.map((elem) => {
    //               const stage_name = elem?.stage_name
    //               const getTotalStageWise = (dataList) => {
    //                 const totalOfStage = dataList.reduce((acc, item) => acc +  (getTwoDigitDecimal(item?.rate) * getTwoDigitDecimal(item?.quantity)), 0)
    //                 console.log("total of stage:", totalOfStage)
    //                 return totalOfStage
    //               }
    //               const dataList = elem?.constructionNameBOQDetailsList?.map((item, index) => {
    //                 return {
    //                   sl_no: index + 1,
    //                   bsr_ref_no: item?.item_code,
    //                   item_description: item?.item_description,
    //                   quantity: item?.quantity,
    //                   unit: item?.unit_name,
    //                   rate: item?.contract_rate,
    //                   amount: parseFloat(item?.contract_rate * item?.quantity).toFixed(2)
  
    //                 }
    //               })
    //               const obj = {
    //                 stageName: stage_name,
    //                 data: dataList,
    //                 total: getTotalStageWise(dataList)
    //               }
    //               console.log("obje:", obj)
    //               return obj
    //             })
  
    //             const TotalAmount = res?.data?.obj?.stageInformationList?.reduce((accumulator, stage) => {
    //               const constructionNameBOQDetailsList = stage.constructionNameBOQDetailsList;
    //               const amountSum = constructionNameBOQDetailsList.reduce((acc, item) => acc + (item?.contract_rate * item?.quantity), 0);
    //               return accumulator + amountSum;
    //             }, 0);
    //             setTotalAmount(TotalAmount)
    //             setProjectDetails({
    //               construction_name: res?.data?.obj?.construction_name,
    //               bsr_year: res?.data?.obj?.bsr_year,
    //               project_location: res?.data?.obj?.project_location,
    //               project_name: res?.data?.obj?.project_name,
    //               dzongkhag_name: res?.data?.obj?.dzongkhagName,
    //               header: res?.data?.obj?.header_logo,
    //               footer: res?.data?.obj?.footer_logo,
    //               labour_costindex: res?.data?.obj?.labour_costindex,
    //               material_costindex: res?.data?.obj?.material_costindex,
    //               cost_index_amount: res?.data?.obj?.cost_index_amount,
  
    //             })
    //             setTableData(tableDataList)
    //           }
    //           else {
    //             const key = enqueueSnackbar(res?.data?.message, { variant: 'info' });
    //             SnackBarProperty.info(key, closeSnackbar);
    //           }
    //         }
    //         else {
    //           const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
    //           SnackBarProperty.error(key, closeSnackbar);
  
    //         }
    //       }).catch(error => {
    //         setLoading(false)
    //         console.log("error:", error)
    //         const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
    //         SnackBarProperty.error(key, closeSnackbar);
  
    //       })
    //   }
  
  
  
    }
  
    const handleDownload = (isPrint) => {
      if (isPrint) {
        setIsPrinting(true)
        setIsDownloading(false)
      }
      else {
        setIsPrinting(false)
        setIsDownloading(true)
      }
      const doc = new jsPDF();
      const contentHeight = 150;
      const headerImage = document.getElementById("headerLogo"); // Assuming you have an element with id "header" for the header image
  
      doc.setFontSize(12); // Set font size
  
  
      html2canvas(headerImage, {
        useCORS: true, // Add this line if the image is from a different domain
        allowTaint: true, // Add this line if the image is from a different domain
      }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
  
        // A4 dimensions in mm
        const pdfWidth = 210;
        const pdfHeight = 297;
  
        // Calculate aspect ratio
        const aspectRatio = imgWidth / imgHeight;
  
        // Calculate scaled dimensions to fit within A4 while maintaining aspect ratio
        let scaledWidth = pdfWidth;
        let scaledHeight = pdfWidth / aspectRatio;
  
        if (scaledHeight > pdfHeight) {
          scaledHeight = pdfHeight;
          scaledWidth = pdfHeight * aspectRatio;
        }
        const topBorderPadding = 10; // Adjust the padding value as needed
        doc.setDrawColor(255, 255, 255); // Black color for border
        doc.setLineWidth(topBorderPadding); // Border width (padding)
  
        // Draw the top border (padding)
        doc.line(0, topBorderPadding / 2, pdfWidth, topBorderPadding / 2);
  
        // Insert the header image into the PDF
        doc.addImage(imgData, "PNG", 0, 0, scaledWidth, scaledHeight);
        const textX = 15;
        const textY = scaledHeight + 10; // Adjust the value for vertical separation
  
        // Add the text content below the header image
        doc.setFont('helvetica', 'bold'); // Set font to bold
        doc.setFontSize(12); // Set font size
        doc.text("Construction Name BOQ DOM", (doc.internal.pageSize.getWidth() / 2) - ((doc.getStringUnitWidth("Construction Name BOQ DOM") * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2), textY);
        // doc.text(`Bill of Quantities (${reportType === "estimate" ? "Estimate" : "Contract"})`, textX, textY);
        doc.setFontSize(8); // Set font size
        const textWidth = doc.getStringUnitWidth("Construction Name:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
        doc.text("Project Name:", textX, textY + 10);
        doc.setFont('helvetica', 'normal');
        doc.text(state?.project_name, textX + textWidth + 5, textY + 10);
        doc.setFont('helvetica', 'bold');
        doc.text("Project ID:", textX, textY + 15);
        doc.setFont('helvetica', 'normal');
        doc.text(state?.project_id, textX + textWidth + 5, textY + 15);
        doc.setFont('helvetica', 'bold');
        doc.text("Construction Name:", textX, textY + 20);
        doc.setFont('helvetica', 'normal');
        doc.text(projectDetails?.construction_name, textX + textWidth + 5, textY + 20);
        doc.setFont('helvetica', 'bold');
        // doc.text("Dzongkhag:", textX, textY + 20);
        // doc.setFont('helvetica', 'normal');
        // doc.text(`${projectDetails?.dzongkhag_name}`, textX + textWidth + 5, textY + 20);
        // doc.setFont('helvetica', 'bold');
        // doc.text("BSR Year:", textX, textY + 25);
        // doc.setFont('helvetica', 'normal');
        // doc.text(`${projectDetails?.bsr_year}`, textX + textWidth + 5, textY + 25);
  
        const headerSectionHeight = scaledHeight + 40; // Adjust the value as needed
  
        // Calculate the starting Y-coordinate for the table
        const tableStartY = headerSectionHeight + 5;
        doc.setFont('helvetica', 'normal');
  
        function transformData(data) {
          const transformedData = [];
          var rowIndex = 0
          data.forEach((row, index) => {
  
            // Create a row for the section header value (spanning across all 8 columns)
            const headerItemCodeRow = [{ content: `Item Code: ${row?.item_code}`, colSpan: 8, styles: { fontStyle: "bold", fontSize: 8 } }];
            transformedData.push(headerItemCodeRow);
            const headerDescriptionRow = [{ content: `Item Description: ${row?.description}`, colSpan: 8, styles: { fontStyle: "bold", fontSize: 8 } }];
            transformedData.push(headerDescriptionRow);
            const headerUnitNameRow = [{ content: `Unit Name: ${row?.unit_name}`, colSpan: 8, styles: { fontStyle: "bold", fontSize: 8 } }];
            transformedData.push(headerUnitNameRow);
  
            // Loop through the data of the section and create rows for each object in the data
  
            // section.data.forEach((row, index) => {
            //   rowIndex+=1
            //   const rowData = Object.values(row);
  
            //   const rate = formatNumberWithCommasAndDecimals(rowData?.amount)
            //   rowData.amount = rate// Extract values of each object
  
              transformedData.push([
                { content: index+1, styles: { valign: "middle", halign: "center",fontSize: 8 } },
                { content: row?.item_no, styles: { valign: "middle", halign: "center",fontSize: 8 } },
                { content: row?.length, styles: { valign: "middle", halign: "center",fontSize: 8 } },
                { content: row?.breadth, styles: { valign: "middle", halign: "center",fontSize: 8 } },
                { content: row?.height, styles: { valign: "middle", halign: "center",fontSize: 8 } },
                { content: row?.item_sign, styles: { valign: "middle", halign: "center",fontSize: 8 } },
                { content: currencyFormat(row?.quantity), styles: { valign: "middle", halign: "center",fontSize: 8 } },
                { content: row?.remarks, styles: { valign: "middle", halign: "center",fontSize: 8 } },
              ]);
            // });
            transformedData.push([
              { content: `Total Quantity: ${currencyFormat(calculateSubTotalQuantity(data))}`, colSpan: 8, styles: { halign: "left", fontStyle: "bold", fontSize: 8 } },
            //   { content: `${section?.total ?? "0.00"}`, colSpan: 1, styles: { halign: "right", fontStyle: "bold", fontSize: 8 } },
            ])
          });
          return transformedData;
        }
  
        const transformedData = transformData(tableData);
  
        // transformedData.push([{ content: reportType === "estimate" ? "Sub-Total Amount (Nu):" : "Total Amount (Nu):", colSpan: 6, styles: { halign: "right", fontStyle: "bold", fontSize: 8 } }, { content: formatNumberWithCommasAndDecimals(totalAmount), styles: { halign: "right", fontStyle: "bold", fontSize: 8 } }])
        // if (reportType === "estimate") {
        //   transformedData.push([
        //     { content: "Labour Cost Index (%):", colSpan: 2, styles: { halign: "left", fontStyle: "bold", fontSize: 8 } },
        //     { content: parseFloat(projectDetails?.labour_costindex ?? 0)?.toFixed(2), styles: { halign: "left", fontSize: 8 } },
        //     { content: "Material Cost Index (%):", colSpan: 1, styles: { halign: "left", fontStyle: "bold", fontSize: 8 } },
        //     { content: parseFloat(projectDetails?.material_costindex ?? 0)?.toFixed(2), styles: { halign: "right", fontSize: 8 } },
        //     { content: "Cost Index Amount (Nu):", colSpan: 2, styles: { halign: "left", fontStyle: "bold", fontSize: 8 } },
        //     { content: formatNumberWithCommasAndDecimals(projectDetails?.cost_index_amount ?? 0), styles: { halign: "right", fontStyle: "bold", fontSize: 8 } }
        //   ])
        //   transformedData.push([
        //     { content: "Total Amount (Nu):", colSpan: 6, styles: { halign: "right", fontStyle: "bold", fontSize: 8 } },
        //     { content: formatNumberWithCommasAndDecimals(projectDetails?.cost_index_amount + parseFloat(totalAmount)), styles: { halign: "right", fontStyle: "bold", fontSize: 8 } },
  
        //   ])
        // }
  
        doc.autoTable({
          startY: tableStartY,
          head: [[
            { content: "SL NO.", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
            { content: "NO", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
            { content: "LENGTH", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
            { content: "BREADTH", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
            { content: "HEIGHT", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
            { content: "DEDUCTIONS", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
            { content: "QUANTITY", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
            { content: "REMARKS", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } }

          ]],
          body: transformedData, // Use the transformed data here
          theme: "grid",
          styles: {
            fillColor: [255, 255, 255], // Remove background color from the table
            lineColor: [0, 0, 0], // Set border color for main table
            lineWidth: 0.1, // Set border width for main table
            textColor: [0, 0, 0], // Set text color to black
            cellPadding: 2, // Add padding to cells
          },
          columnStyles: {
            2: { cellWidth: 50 }, // "ITEM DESCRIPTION" column width
            5: { halign: "right" }, // "RATE" column width
            6: { halign: "right" }, // "AMOUNT" column width
            3: { halign: "right" }, // "UNIT" column width
          },
        });
  
        // Function to add footer on each page
        function addFooter(pageNumber) {
          // const footerText = "Hello CMS User";
          // const pageString = "Page " + pageNumber + " of " + doc.internal.getNumberOfPages();
          const footerX = 0; // Starting X position for the footer
          const footerY = doc.internal.pageSize.getHeight() - 10; // 10 units from the bottom
          const pdfWidth = doc.internal.pageSize.getWidth();
  
  
          doc.addImage(`${ServiceUrl?.showImageUrl}${projectDetails?.footer}`, "PNG", footerX, footerY, pdfWidth, 10, 'FAST')
        }
        // Get the total number of pages
        const totalNumPages = doc.internal.getNumberOfPages();
  
        // Loop through each page to add the footer with page numbers
        for (let i = 1; i <= totalNumPages; i++) {
          doc.setPage(i);
          addFooter(i);
        }
  
        const scaleFactor = 1.0; // Adjust this value as needed
        const pdfBlob = doc.output('blob', { scale: scaleFactor });
  
        // Create a URL for the Blob
        const pdfUrl = URL.createObjectURL(pdfBlob);
  
        // Open a new window with the PDF URL
  
        if (isPrint) {
          const newWindow = window.open(pdfUrl, '_blank');
  
          // After the new window is opened, you can close the URL object
          URL.revokeObjectURL(pdfUrl);
          if (newWindow) {
            newWindow.onload = () => {
              setIsPrinting(false)
              setIsDownloading(false)
              // Wait for the window to load and then trigger the print dialog
              newWindow.print();
            };
          }
        }
  
        setIsPrinting(false)
        setIsDownloading(false)
  
        isPrint === false && doc.save('ConstructionNameBoQ_DOM_Report.pdf');
  
      });
    };
  
  
    return (
      <>
        <div style={{ height: 'auto', width: "100%", padding: '30px', display: "flex", flexDirection: "column", gap: "20px" }}>
          <Grid container>
            <Grid item xs={12}>
              <Button
                size="small"
                sx={{ width: "100px" }}
                variant="contained"
                color="error"
                onClick={() => { window.history.back() }}
              >
                <Icon color="primary" fontSize="small">
                  skip_previous
                </Icon>
                Back
              </Button>
            </Grid>
          </Grid>
          <SimpleCard>
            <ValidatorForm onSubmit={handleFormSubmit}>
              <Grid container spacing={3} style={{ marginTop: '-45px', marginBottom: '15px' }} >
                <Grid item xs={12}><b className="font20 extraBold" style={{ color: "#ebad4e" }}>Construction Name BoQ DOM</b></Grid>
              </Grid>
  
               <Grid container spacing={3} >
               {/* <Grid item xs={12}>
                  <RadioGroup
                    row
                    name="reportType"
                    value={reportType}
                    onChange={(e) => { setReportType(e.target.value); setTableData([]) }}
                  >
  
                    <FormControlLabel
                      label="Construction Name BoQ (Estimate)"
                      value="estimate"
                      control={<Radio size="small" color="secondary" />}
                      sx={{ mr: 3, height: 20 }}
                    />
                    <FormControlLabel
                      label="Construction Name BoQ (Contract)"
                      value="contract"
                      control={<Radio size="small" color="secondary" />}
                      sx={{ height: 20 }}
                    />
                  </RadioGroup>
  
                </Grid> */}
  
                <Grid item sm={4} xs={12}>
                  <TextField
                    select
                    size="small"
                    fullWidth
                    sx={{ mb: 3 }}
                    label="Project ID"
                    id="project_location_id"
                    variant="outlined"
                    name="project_location_id"
                    value={state?.project_location_id}
                    onChange={handleChange}
                    validators={["required"]}
                    errorMessages={["Project ID is required"]}
                  >
                    {reportsProjectLocationList?.map((item) => (
                      <MenuItem value={item?.project_location_id} key={item?.project_id}>
                        {item?.project_id}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item sm={4} xs={12}>
  
                  <DecoratedTextField
                    select
                    size="small"
                    fullWidth
                    label="Construction Name"
                    id="constructionname_id"
                    variant="outlined"
                    sx={{ mb: 3 }}
                    name="constructionname_id"
                    value={state?.constructionname_id || ""}
                    onChange={handleChange}
                    validators={["required"]}
                    errorMessages={["Construction Name is required"]}
                  >
                    {constructionNameList?.map((item) => (
                      <MenuItem value={item?.constructionname_id} key={item?.constructionname_id}>
                        {item?.construction_name}
                      </MenuItem>
                    ))}
                  </DecoratedTextField>
  
                </Grid>
                <Grid item sm={4} xs={12}>
  
  
                  <Button size="medium" color="success" variant="contained" type="submit" style={{ marginLeft: 4 }} >View</Button>
  
                </Grid>
  
              </Grid>
  
            </ValidatorForm>
          </SimpleCard>
          {tableData && tableData?.length > 0 && <Grid container>
            <Grid xs={12} md={6}>
              {checkValidation(projectDetails?.header) === false && <FormLabel sx={{ color: "#ff9800" }} color="warning">! Please upload header and footer for download/print</FormLabel>}
            </Grid>
            <Grid item xs={12} md={6}>
              <Box component="span" display="flex" justifyContent="flex-end">
                <Button
                  size="small"
                  disabled={projectDetails?.header ? false : true}
                  sx={{ width: "130px", marginLeft: 4 }}
                  variant="contained"
                  color="warning"
                  startIcon={<CloudDownload />}
                  onClick={() => { handleDownload(false) }}
                >
                  Download
                </Button>
                <Button
                  size="small"
                  sx={{ width: "100px", marginLeft: 2 }}
                  variant="contained"
                  color="primary"
                  disabled={projectDetails?.header ? false : true}
                  startIcon={<Print />}
                  onClick={() => { handleDownload(true) }}
                >
                  Print
                </Button>
              </Box>
            </Grid>
  
          </Grid>}
          {tableData && tableData?.length > 0 && <Card sx={{ padding: 3 }}>
  
            <div style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px" }}>
              <div id="header" style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px", paddingLeft: "100px", paddingRight: "100px" }}>
                <div style={{ width: "100%", display: "flex", justifyContent: "center", gap: "20px", alignItems: "center" }}>
                  <img id="headerLogo" src={`${ServiceUrl?.showImageUrl}${projectDetails?.header}`}  style={{ height: "150px", width: "auto" }} alt="img" />
  
                </div>
  
                <Grid container spacing={0} >
                  <Grid item xs={12} align='center' >
                    <FormLabel sx={{ fontSize: 22 }} ><b>Construction Name BOQ DOM</b></FormLabel>
                  </Grid>
  
                  <Grid item xs={12} sx={{ marginTop: 2 }}>
                    <FormLabel sx={{ fontSize: 18 }}><b>Project Name: </b>{projectDetails?.project_name}</FormLabel>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: 2 }}>
                    <FormLabel sx={{ fontSize: 18 }}><b>Project ID: </b>{projectDetails?.project_id}</FormLabel>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: 2 }}>
                    <FormLabel sx={{ fontSize: 18 }}><b>Construction Name: </b>{projectDetails?.construction_name}</FormLabel>
                  </Grid>
                  {/* <Grid item xs={12} >
                    <FormLabel sx={{ fontSize: 18 }}><b>Dzongkhag: </b>{projectDetails?.dzongkhag_name}</FormLabel>
                  </Grid>
                  <Grid item xs={12} >
                    <FormLabel sx={{ fontSize: 18 }}><b>BSR Year: </b>{projectDetails?.bsr_year}</FormLabel>
                  </Grid> */}
                </Grid>
              </div>
  
              <div id="tableData">
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead style={{ backgroundColor: '#87CEEB' }}>
                      <TableRow>
                        <TableCell>Sl No.</TableCell>
                        <TableCell align="right">NO</TableCell>
                        <TableCell align="right">LENGTH</TableCell>
                        <TableCell align="right">BREADTH</TableCell>
                        <TableCell align="right">HEIGHT</TableCell>
                        <TableCell align="center">DEDUCTIONS</TableCell>
                        <TableCell align="right">QUANTITY</TableCell>
                        <TableCell>REMARKS</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* {tableData?.map((section) => (
                        <React.Fragment key={section.stageName}>
                          <TableRow>
                            <TableCell colSpan={7}>{section.stageName}</TableCell>
                          </TableRow> */}
                          {tableData?.map((row, index) => (
                            <React.Fragment key={row.item_code}>
                            <TableRow key={index}>
                            <TableCell colSpan={8}>
                                <p><b>Item Code:</b> {row.item_code}</p>
                            </TableCell>
                            </TableRow>
                            <TableRow key={index}>
                            <TableCell colSpan={8}>
                            <p><b>Item Description:</b> {row.description}</p>
                            </TableCell>
                            </TableRow>
                            <TableRow key={index}>
                            <TableCell colSpan={8}>
                            <p><b>Unit:</b> {row.unit_name}</p>
                            </TableCell>
                             </TableRow>

                            <TableRow>
                              <TableCell>{index+1}</TableCell>
                              <TableCell align="right">{row.item_no}</TableCell>
                              <TableCell align="right">{row.length}</TableCell>
                              <TableCell align="right">{row?.breadth}</TableCell>
                              <TableCell align="right">{row.height}</TableCell>
                              <TableCell align="center">{row.item_sign}</TableCell>
                              <TableCell align="right">{currencyFormat(row?.quantity)}</TableCell>
                              <TableCell>{row?.remarks}</TableCell>
                            </TableRow>
                            <TableRow key={index}>
                                <TableCell colSpan={8}>
                                <p><b>Total Quantity:</b> {currencyFormat(calculateSubTotalQuantity(tableData))}</p>
                                </TableCell>
                            </TableRow>
                            </React.Fragment>
                          ))}
                          
                          {/* <TableRow>
                            <TableCell colSpan={6} align="right">Sub-Total Amount (Nu) of {section.stageName} :</TableCell>
                            <TableCell align="right"><b>{currencyFormat(section?.total)}</b></TableCell>
                          </TableRow>
                        </React.Fragment> */}
                      {/* ))} */}
  
                      {/* <TableRow>
                        <TableCell colSpan={6} align="right" style={{ fontWeight: 300 }}>{reportType === "estimate" ? "Sub-Total Amount (Nu):" : "Total Amount (Nu):"}  ({projectDetails?.classification_name})</TableCell>
                        <TableCell colSpan={1} style={{ fontWeight: 600 }} align="right"><b>{formatNumberWithCommasAndDecimals(totalAmount)}</b></TableCell>
                      </TableRow> */}
                    </TableBody>
  
                  </Table>
                  {reportType === "estimate" && <Table>
                    {/* <TableFooter> */}
                      {/* <TableRow>
                        <TableCell colSpan={2} align="right" style={{ fontWeight: 300 }}>Labour Cost Index (%):</TableCell>
                        <TableCell colSpan={1} style={{ fontWeight: 200 }} align="left">{currencyFormat(projectDetails?.labour_costindex)}</TableCell>
                        <TableCell colSpan={2} align="right" style={{ fontWeight: 300 }}>Material Cost Index (%):</TableCell>
                        <TableCell colSpan={1} style={{ fontWeight: 200 }} align="left">{currencyFormat(projectDetails?.material_costindex)}</TableCell>
                        <TableCell colSpan={2} align="right" style={{ fontWeight: 300 }}>Cost Index Amount (Nu):</TableCell>
                        <TableCell colSpan={1} style={{ fontWeight: 200 }} align="right">{formatNumberWithCommasAndDecimals(projectDetails?.cost_index_amount)}</TableCell>
                      </TableRow> */}
                      {/* <TableRow>
                        <TableCell colSpan={8} align="right" style={{ fontWeight: 400 }}>Sub-total Quantity (Nu):</TableCell>
                        <TableCell colSpan={1} style={{ fontWeight: 600 }} align="right"><b>{formatNumberWithCommasAndDecimals(projectDetails?.cost_index_amount + parseFloat(totalAmount))}</b></TableCell>
                      </TableRow>
                    </TableFooter> */}
                  </Table>}
                </TableContainer>
  
              </div>
            </div>
          </Card>}
  
        </div>
        {loading && (
          <Loader
            show={loading}
          />
        )}
        {(isPrinting || isDownloading) && (
          <Loader
            show={isPrinting ? isPrinting : isDownloading}
            text="Processing...Please wait."
          />
        )}
  
      </>
    )
  }