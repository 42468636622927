import { TextField } from '@mui/material';
import { styled } from '@mui/styles';
import { differenceInSeconds } from 'date-fns';
import dayjs from 'dayjs';
import moment from 'moment';
import { TextValidator } from 'react-material-ui-form-validator';
import * as _ from "lodash";
import React from "react";
import { NumericFormat } from 'react-number-format';

export function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        });
      }}
      thousandSeparator={!checkValidation(props.thousandSeparatorValue) ? ',' : props.thousandSeparatorValue}
      // allowNegative={false}
      // prefix={!checkValidation(props.prefix) ? '' : props.prefix}
      allowNegative={!checkValidation(props.allowNegativeValue) ? false : props.allowNegativeValue}
      // isNumericString
      decimalScale={!checkValidation(props.decimalScaleValue) ? 2 : props.decimalScaleValue}
      fixedDecimalScale={!checkValidation(props.fixedDecimalScale) ? true : props.fixedDecimalScale}
      isAllowed={(values) => {
        const { floatValue } = values;
        if (!checkValidation(floatValue)) { return true }
        const limitMinValue = !checkValidation(props.limitMinValue) ? 0 : props.limitMinValue
        const limitMaxValue = !checkValidation(props.limitMaxValue) ? 10000000000 : props.limitMaxValue
        return floatValue >= limitMinValue && floatValue <= limitMaxValue;
      }}
    />
  );
}

export const convertHexToRGB = (hex) => {
  // check if it's a rgba
  if (hex.match('rgba')) {
    let triplet = hex.slice(5).split(',').slice(0, -1).join(',');
    return triplet;
  }

  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');

    return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',');
  }
};

export function getFormattedDateTime(value, dateFormat = "YYYY-MM-DDTHH:mm:ss") {
  return dayjs(value).format(dateFormat)
}

export function getEpochTime() {
  const secondsSinceEpoch = Math.round(Date.now() / 1000)
  return secondsSinceEpoch
}

export function checkValidation(value) {
  if (value === '' || value === null || value === undefined) {
    return false
  } else {
    return true
  }
}

export function createYearArray() {
  var fiscalyear = "";
  var today = new Date();
  if ((today.getMonth() + 1) <= 3) {
    fiscalyear = (today.getFullYear() - 1)
  } else {
    fiscalyear = today.getFullYear()
  }
  const years = Array.from(new Array(5), (val, index) => fiscalyear - index);
  return years
}

export const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
};

export function createCurrentYearArray() {
  var fiscalyear = "";
  var today = new Date();
  if ((today.getMonth() + 1) <= 5) {
    fiscalyear = (today.getFullYear())
  } else {
    fiscalyear = today.getFullYear() + 2
  }
  const years = Array.from(new Array(5), (val, index) => fiscalyear - index);
  return years
}

export function createCustomYearArray(count) {
  var fiscalyear = "";
  var today = new Date();
  if ((today.getMonth() + 1) <= 3) {
    fiscalyear = (today.getFullYear() - 1)
  } else {
    fiscalyear = today.getFullYear()
  }
  const years = Array.from(new Array(count), (val, index) => fiscalyear - index);
  return years
}


export function exportDataToFile(data, name, originalFileName) {
  const fileExtension = originalFileName.split('.').pop()
  const href = URL.createObjectURL(data);
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', `${name}-${getEpochTime()}.${fileExtension}`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

export const timeStampToDate = (timeStamp) => {

  const date = new Date(timeStamp);
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Months are 0-based, so add 1
  const day = date.getDate();

  const formattedDate = `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;

  return formattedDate
}

export function validateTwoDigitDecimal(value) {
  if (value?.toString().split('.')[1]?.length > 2) {
    return false
  } else {
    return true
  }
};
export function validateFourDigitDecimal(value) {
  if (value?.toString().split('.')[1]?.length > 4) {
    return false
  } else {
    return true
  }
};

export function validatePercentCompletion(value) {
  var newValue = getTwoDigitDecimal(value)
  if (value?.toString().split('.')[1]?.length > 2) {
    return false
  } else {
    if (newValue <= 100) {
      console.log('ent fr1')
      return true
    } else {
      console.log('ent frw')
      return false
    }
  }
};

export var validate = function (e) {
  var t = e.value;
  e.value = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;
}

export function createYearArrayForFinancial() {
  var fiscalyear = "";
  var today = new Date();
  if ((today.getMonth() + 1) <= 3) {
    fiscalyear = (today.getFullYear() - 1)
  } else {
    fiscalyear = today.getFullYear()
  }
  const years = Array.from(new Array(2), (val, index) => fiscalyear - index);
  return years
}

export function createFinancialYearArray() {
  var yearArray = createYearArrayForFinancial()
  var financialYearArray = []
  yearArray.forEach((year, index) => {
    //var value = Number(year.toString().slice(-2)) + 1
    var value = Number(year.toString()) + 1
    financialYearArray.push(`${year}-${value}`)
  });
  return financialYearArray
}

export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
}

export function isMobile() {
  if (window) {
    return window.matchMedia(`(max-width: 767px)`).matches;
  }
  return false;
}

export function isValidEmail(email) {
  return /\S+@\S+\.\S+/.test(email);
}

export function isMdScreen() {
  if (window) {
    return window.matchMedia(`(max-width: 1199px)`).matches;
  }
  return false;
}

function currentYPosition(elm) {
  if (!window && !elm) {
    return;
  }
  if (elm) return elm.scrollTop;
  // Firefox, Chrome, Opera, Safari
  if (window.pageYOffset) return window.pageYOffset;
  // Internet Explorer 6 - standards mode
  if (document.documentElement && document.documentElement.scrollTop)
    return document.documentElement.scrollTop;
  // Internet Explorer 6, 7 and 8
  if (document.body.scrollTop) return document.body.scrollTop;
  return 0;
}

export function goToViolation(id) {
  let toogleElement = document.getElementsByClassName(id);
  toogleElement[0].scrollIntoView({
    block: 'start',
    behavior: 'smooth'
  });
};

export function getStatus(value) {
  switch (value) {
    case 1:
      return 'YES';
    case 2:
      return 'NO';
    case 3:
      return 'CREATED';
    case 4:
      return 'SUBMITTED';
    case 5:
      return 'RECOMMENDED';
    case 6:
      return 'REVIEWED';
    case 7:
      return 'APPROVED';
    case 8:
      return 'REJECTED';
    case 9:
      return 'PUBLISED';
    case 10:
      return 'EDITED';
    case 11:
      return 'CLOSED';
    case 12:
      return 'SENT TO EGP';
    case 13:
      return 'SENT_FOR_REVIEW';
    case 14:
      return 'REJECTED BY RECOMMENDER';
    case 15:
      return 'REJECTED BY APPROVER';
    case 16:
      return 'CANCELLED';
    default:
      return 'NOT AVAILABLE';
  }
};

export function convertTo12Hour(time24) {
  // Split the time string into hours and minutes
  const [hours, minutes] = time24.split(":").map(Number);

  // Determine AM or PM
  const period = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  const hours12 = hours % 12 || 12; // Convert 0 to 12 for midnight

  // Return formatted string
  return `${hours12}:${minutes.toString().padStart(2, "0")} ${period}`;
}


export function getTwoDigitDecimal(value, digit = 2) {
  if (!checkValidation(value) || value === 0 || isNaN(value)) {
    return 0.00
  } else {
    // var newValue = FixWithoutRounding(value, digit)
    // return parseFloat(newValue)
    const roundedString = parseFloat(value)?.toFixed(digit);
    return parseFloat(roundedString)
  }
}

function FixWithoutRounding(v, l = 2) {
  const intPart = Math.trunc(v).toString()
  if (v?.toString().split('.')[1]?.length > 1) {
    const fractionPart = v.toString().slice(v.toString().indexOf('.') + 1)
    return Number(intPart.concat('.', fractionPart.slice(0, l))).toString()
  } else {
    return v.toFixed(l)
  }
}

export function getCurrentRole() {
  let currentRole = window.localStorage.getItem('role_id')
  return parseInt(currentRole ?? "0")
}

export function returnNumber(str) {
  var num = str.replace(/[^0-9]/g, '');
  return parseInt(num, 10);
}

export function currencyFormat(num, digit = 2) {
  if (!checkValidation(num) || num === 0 || isNaN(num)) {
    return 0.00
  } else {
    // let number = FixWithoutRounding(num, digit)
    //return number.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return parseFloat(num).toFixed(digit).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,')
    // return parseFloat(num).toFixed(digit).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }
}

export const multiGroupBy = (seq, keys) => {
  if (!keys.length) return seq;
  var first = keys[0];
  var rest = keys.slice(1);
  return _.mapValues(_.groupBy(seq, first), function (value) {
    return multiGroupBy(value, rest);
  });
};


export function getFileName(value) {
  console.log('file path is', value?.split('uploads\\'))
  let fileName = value?.split('uploads\\')[0]
  if (fileName) {
    return fileName
  }
}

export const getResourceForecastCategoryShortName = (category_id) => {
  // eslint-disable-next-line default-case
  switch (category_id) {
    case 1:
      return "MR"
    case 2:
      return "MT"
    case 3:
      return "MP"
    case 4:
      return "TP"
  }
}

export const getResourceForecastCategoryName = (category_id) => {
  // eslint-disable-next-line default-case
  switch (category_id) {
    case 1:
      return "Muster Roll"
    case 2:
      return "Materials"
    case 3:
      return "Machinery"
    case 4:
      return "Tools and Plants"
  }
}

export function formatDate(date) {
  const customDate = new Date(date)
  return moment(customDate).format('DD-MM-YYYY');
}

function getKeyByValue(object, value) {
  return Object.keys(object).find(key =>
    object[key] === value);
}

export const getMonthYear = (dateString) => {
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth(); // Adding 1 because getMonth() returns values from 0 to 11
  return `${monthNames[month]}, ${year}`
}

export function formatDateWithFormatter(date, formatter) {
  const customDate = new Date(date)
  return moment(customDate).format(formatter);
}

export function goToViolationWithAutoAnimation(id) {
  let toogleElement = document.getElementsByClassName(id);
  console.log('toggled element', toogleElement)
  toogleElement[0].scrollIntoView({
    block: 'start',
    behavior: 'auto'
  });
};

function elmYPosition(elm) {
  var y = elm.offsetTop;
  var node = elm;
  while (node.offsetParent && node.offsetParent !== document.body) {
    node = node.offsetParent;
    y += node.offsetTop;
  }
  return y;
}

export function customScrollTo(scrollableElement, elmID) {
  var elm = document.getElementById(elmID);

  if (!elmID || !elm) {
    return;
  }

  var startY = currentYPosition(scrollableElement);
  var stopY = elmYPosition(elm);

  var distance = stopY > startY ? stopY - startY : startY - stopY;
  if (distance < 100) {
    customScrollTo(0, stopY);
    return;
  }
  var speed = Math.round(distance / 50);
  if (speed >= 20) speed = 20;
  var step = Math.round(distance / 25);
  var leapY = stopY > startY ? startY + step : startY - step;
  var timer = 0;
  if (stopY > startY) {
    for (var i = startY; i < stopY; i += step) {
      setTimeout(
        (function (leapY) {
          return () => {
            scrollableElement.scrollTo(0, leapY);
          };
        })(leapY),
        timer * speed
      );
      leapY += step;
      if (leapY > stopY) leapY = stopY;
      timer++;
    }
    return;
  }
  for (let i = startY; i > stopY; i -= step) {
    setTimeout(
      (function (leapY) {
        return () => {
          scrollableElement.scrollTo(0, leapY);
        };
      })(leapY),
      timer * speed
    );
    leapY -= step;
    if (leapY < stopY) leapY = stopY;
    timer++;
  }
  return false;
}

export function getTimeDifference(date) {
  let difference = differenceInSeconds(new Date(), date);

  if (difference < 60) return `${Math.floor(difference)} sec`;
  else if (difference < 3600) return `${Math.floor(difference / 60)} min`;
  else if (difference < 86400) return `${Math.floor(difference / 3660)} h`;
  else if (difference < 86400 * 30) return `${Math.floor(difference / 86400)} d`;
  else if (difference < 86400 * 30 * 12) return `${Math.floor(difference / 86400 / 30)} mon`;
  else return `${(difference / 86400 / 30 / 12).toFixed(1)} y`;
}

export function generateRandomId() {
  let tempId = Math.random().toString();
  let uid = tempId.substr(2, tempId.length - 1);
  return uid;
}

export function getQueryParam(prop) {
  var params = {};
  var search = decodeURIComponent(
    window.location.href.slice(window.location.href.indexOf('?') + 1)
  );
  var definitions = search.split('&');
  definitions.forEach(function (val, key) {
    var parts = val.split('=', 2);
    params[parts[0]] = parts[1];
  });
  return prop && prop in params ? params[prop] : params;
}

export function classList(classes) {
  return Object.entries(classes)
    .filter((entry) => entry[1])
    .map((entry) => entry[0])
    .join(' ');
}

export const flat = (array) => {
  var result = [];
  array.forEach(function (a) {
    result.push(a);
    if (Array.isArray(a.children)) {
      result = result.concat(flat(a.children));
    }
  });
  return result;
};

export const DecimalInputField = styled(TextField)(({ theme }) => ({
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none"
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
  "& input[type=text]": {
    textAlign: "right"
  }
}));
export const DecimalInputFieldWithValidation = styled(TextValidator)(({ theme }) => ({
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none"
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
  "& input[type=text]": {
    textAlign: "right"
  }
}));

export function checkDateAndDisplay(date) {
  return checkValidation(date) ? formatDate(date) : ""
}


export const formatNumberWithCommasAndDecimals = (input) => {
  // Try to parse the input string to a number
  const number = parseFloat(input);

  // Check if the parsing was successful and it's a finite number
  if (!isNaN(number) && isFinite(number)) {
    const formattedNumber = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    return formattedNumber;
  }

}


