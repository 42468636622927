import { LoadingButton } from "@mui/lab";
import { Box, Button, Card, Dialog, Divider, Fab, Grid, Icon, Table, TableBody, TableCell, TableHead, TableRow, createTheme, styled } from "@mui/material";
import { ConfirmationDialog, Loader } from "app/components";
import { FlexBetween } from "app/components/FlexBox";
import { H4 } from "app/components/Typography";
import useAuth from "app/hooks/useAuth";
import { downloadImplementationFile, getAlRunningAccountBillDetailsListById } from "app/redux/actions/ImplementationActions";
import { getAllPriceAdjustmentListByRunningAccountBill } from "app/redux/actions/PriceAdjustmentActions";
import { CREDIT_DEBIT_LIST, PRICE_ADJUSTMENT_LIST_BILL, RECLAIM_ADVANCES_LIST, getAllAdvanceDetailsReport, getAllPriceAdjustmentReport, getAllRecommededBillsByProjectLocation, getDebitCreditDetailsForRABillReport, saveOrUpdateRunningAccountBill, sendRaBillReportEmail } from "app/redux/actions/RABillActions";
import { getRunningAccountBillForReport } from "app/redux/actions/ReportsAction";
import { AppConstants } from "app/utils/AppConstants";
import { ServiceUrl } from "app/utils/Constants";
import { ProjectStatus } from "app/utils/ProjectStatus";
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import { checkValidation, currencyFormat, formatDate, formatNumberWithCommasAndDecimals, getTwoDigitDecimal, isValidEmail } from "app/utils/utils";
import dayjs from "dayjs";
import jsPDF from "jspdf";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";

const TextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));

const DecimalField = styled(TextField)(({ theme }) => ({
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
}));

const FabIcon = styled(Fab)(() => ({
  textTransform: "capitalize",
  "& .icon": { marginRight: "8px" },
}));

const MyCard = styled(Card)(() => ({
  "& thead": { "& th": { padding: 5 } },
  "& td": { borderBottom: "none" },
  "& td": { padding: "5px !important" },
}));


const theme = createTheme({

  components: {
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          backgroundColor: "#87CEEB",
        }
      }
    }
  }
});

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));


const HiddenInput = styled("input")(() => ({ display: "none" }));


const NewRABillVerificationDialog = ({ title, project, type, raBill, open, handleClose, handleSuccess }) => {

  const [verificationArray, setVerificationArray] = useState(["Recommended", "Rejected"])
  const [copyToDetailsArray, setCopyToDetailsArray] = useState([{ email: "", address: '' }, { email: "", address: '' }, { email: "", address: '' }])

  const { priceAdjustmentList, creditDebitList, reclaimAdvancesList } = useSelector((state) => state.raBill);
  const [disableButton, setDisableButton] = useState(false)
  const [tableData, setTableData] = useState()
  const [grandTotal, setGrandTotal] = useState()
  const [shouldOpenRejectDialog, setShouldOpenRejectDialog] = useState(false)
  const [shouldOpenRecommendConfirmationDialog, setShouldOpenRecommendConfirmationDialog] = useState(false)
  const [projectDetails, setProjectDetails] = useState()
  const { user } = useAuth();

  const [state, setState] = useState({
    project_name: "",
    project_id: '',
    project_location_id: "",
    runningaccountbill_id: "",
    created_on: "",
    description: '',
    status: ProjectStatus.RECOMMENDED,
    status_name: 'Recommended',
    submission_filename: '',
    file: '',
    recommended_amount: '',
    verification_remarks: '',
    verification_file_name: '',
    verification_file: '',
    recommended_amendment_amount: '',
    recommended_recovery_amount: '',
    penalty_amount: ''
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    console.log("type:", type)
  }, [type])

  const handleChange = (event, source) => {
    ;
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleFileSelect = (event) => {
    let files = event.target.files;
    if (files.length > 0) {
      state.verification_file_name = files[0].name
      setState({ ...state, ['verification_file']: files[0] });
    }
  };

  function calculateSubTotalPrice() {
    const sum = priceAdjustmentList?.reduce(
      (previousValue, currentValue, index) => previousValue + currentValue?.adjustment_amount, 0);
    return getTwoDigitDecimal(sum)
  }

  // function calculateTotalAdvanceAmount() {
  //   const sum = reclaimAdvancesList?.reduce(
  //     (previousValue, currentValue, index) => previousValue + currentValue?.approved_amount, 0);
  //   return getTwoDigitDecimal(sum)
  // }

  function calculateTotalCurrentRecovery() {
    const sum = reclaimAdvancesList?.reduce(
      (previousValue, currentValue, index) => previousValue + currentValue?.current_recovered_amount, 0);
    return getTwoDigitDecimal(sum)
  }

  function calculateTotalRecoveredAmount() {
    const sum = reclaimAdvancesList?.reduce(
      (previousValue, currentValue, index) => previousValue + currentValue?.recovered_amount, 0);
    return getTwoDigitDecimal(sum)
  }
  function calculateTotalAdvanceAmount() {
    const sum = reclaimAdvancesList?.reduce(
      (previousValue, currentValue, index) => previousValue + currentValue?.approved_amount, 0);
    return getTwoDigitDecimal(sum)
  }

  function calculateTotalBalanceAmount() {
    const sum = reclaimAdvancesList?.reduce(
      (previousValue, currentValue, index) => previousValue + ((currentValue?.approved_amount ?? 0) - (currentValue?.recovered_amount ?? 0)), 0);
    return getTwoDigitDecimal(sum)
  }

  function getTdsAmount() {
    const tdsPercent = getSubTotalPrice() <= 0 ? 0 : getTwoDigitDecimal(((project.tds_percent ? project?.tds_percent : 0) / 100) * getTwoDigitDecimal((creditDebitList[0]?.credit_amount ?? 0) - (creditDebitList[1]?.debit_amount ?? 0) + parseFloat(calculateSubTotalPrice())))
    return tdsPercent
  }

  function getRetentionAmount() {
    const retention = getSubTotalPrice() <= 0 ? 0 : getTwoDigitDecimal(((project.retention ? project?.retention : 0) / 100) * getTwoDigitDecimal((creditDebitList[0]?.credit_amount ?? 0) - (creditDebitList[1]?.debit_amount ?? 0) + parseFloat(calculateSubTotalPrice())))
    return retention
  }

  function calculateDepositSubTotal() {
    const tdsPercent = getTdsAmount()
    const retention = getRetentionAmount()

    const mainTotal =
      getTwoDigitDecimal(getTwoDigitDecimal(creditDebitList[0]?.credit_amount ?? 0) - getTwoDigitDecimal(creditDebitList[1]?.debit_amount ?? 0)) + getTwoDigitDecimal(calculateSubTotalPrice()) -
        getTwoDigitDecimal(raBill?.penalty_amount ?? 0) - getTwoDigitDecimal(retention) - getTwoDigitDecimal(tdsPercent)

    return getTwoDigitDecimal(mainTotal)
  }

  function calculateNetPayableTotal() {
    var depositSubTotal = parseFloat(calculateDepositSubTotal())
    var totalCurrentRecovery = parseFloat(calculateTotalCurrentRecovery())
    var netPayableTotal = depositSubTotal - totalCurrentRecovery
    return getTwoDigitDecimal(netPayableTotal)
  }

  const handleFormSubmit = (event) => {
    if (validateAllValues() === false) { return }
    setShouldOpenRecommendConfirmationDialog(true)
  };

  function getRAStatus() {
    if (type === 'Recommendation') {
      return 'Reviewed'
    } else if (type === 'Verification') {
      return 'Recommended'
    } else if (type === 'Approval') {
      return 'Approved'
    }
    return ''
  }

  const getRecommendedBillList = () => {
    setLoading(true)
    getAllRecommededBillsByProjectLocation(raBill.project_location_id, 'RECOMMENDED', 'RECOMMEND').then((res) => {
      setLoading(false)
      if (res?.status < 300) {
        if (res?.data?.length > 0) {
          setShouldOpenRecommendConfirmationDialog(false)
          const key = enqueueSnackbar(AppConstants.previousBillwarning, { variant: 'info' });
          SnackBarProperty.info(key, closeSnackbar);
          return
        } else {
          getPriceAdjustmentListByParams()
          // getAlRunningAccountBillDetailsListByParams(getRAStatus())
        }
      } else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);
      }
    }).catch(function (error) {
      setLoading(false)
      const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    })
  }

  const getPriceAdjustmentListByParams = () => {
    setLoading(true)
    getAllPriceAdjustmentListByRunningAccountBill(raBill?.runningaccountbill_id).then((res) => {
      setLoading(false)
      if (res?.status < 300) {
        if (res?.data?.length > 0) {
          const filteredResponse = res?.data?.filter((e) => e.status_id === ProjectStatus.SUBMITTED)
          if (filteredResponse?.length > 0) {
            const key = enqueueSnackbar("There are pending price adjustments for this ra bill which needs to be reviewed.", { variant: 'info' });
            SnackBarProperty.info(key, closeSnackbar);
            return
          } else {
            getAlRunningAccountBillDetailsListByParams(getRAStatus())
          }
          return
        } else {
          getAlRunningAccountBillDetailsListByParams(getRAStatus())
        }
      } else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);
      }
    }).catch(function (error) {
      setLoading(false)
      const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    })
  }


  const saveRunningAccountBill = (status_name, rabilldetails) => {

    setLoading(true)
    saveOrUpdateRunningAccountBill(createParamsFoRABill(status_name, rabilldetails)).then((res) => {

      if (res?.status < 300) {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'success' });
        SnackBarProperty.success(key, closeSnackbar);

        if (status_name === "Approved") {
          const report_file = getRABillReportFile()
          const pdfFile = handleReportSend()
          const formData = new FormData()
          formData.append('file', pdfFile)
          formData.append('raBillReportFile', report_file)
          sendRaBillReportEmail({ ra_bill_id: raBill.runningaccountbill_id, data: formData }).then((res) => {
            setLoading(false)
            console.log("response", res)
          }).catch(error => {
            console.log("error1:", error)
            setLoading(false)
            const key = enqueueSnackbar("Email sending unsuccessful", { variant: 'error' });
            SnackBarProperty.error(key, closeSnackbar);

          })
        }
      } else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      }
      handleSuccess()
    }).catch(function (error) {
      setLoading(false)
      console.log("error2:", error)
      const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);


    })
    // handleReportSend()
  }

  function validateAllValues() {

    // if (checkValidation(type === 'Approval'?state?.approval_remarks:state?.verification_remarks) === false) {
    //   console.log("state:",state)
    //   const key = enqueueSnackbar('Please enter remarks', SnackBarProperty.error(key, closeSnackbar));
    //   return false
    // }
    let filteredArray = copyToDetailsArray?.filter((details) => checkValidation(details.email) && !isValidEmail(details.email))

    console.log('my array', filteredArray)

    if (filteredArray.length > 0) {
      const key = enqueueSnackbar('Please enter email in correct format', { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
      return false
    }

    return true

  }

  const getAlRunningAccountBillDetailsListByParams = (status) => {
    setLoading(true)
    getAlRunningAccountBillDetailsListById(raBill?.runningaccountbill_id, project?.project_location_id).then((res) => {
      setLoading(false)
      if (res?.status < 300 && res?.data?.success) {
          saveRunningAccountBill(status ,res?.data?.obj)
      } else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      }
    }).catch(function (error) {
      setLoading(false)
      const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    });;
  }

  function createParamsFoRABill(status_name, rabilldetails) {
    const formData = new FormData()
    formData.append('runningaccountbill_id', raBill.runningaccountbill_id);
    formData.append('project_location_id', raBill.project_location_id);
    formData.append('rabill_no', raBill.rabill_no);
    formData.append('final_bill', raBill?.final_bill ? raBill?.final_bill : false);

    if (checkValidation(raBill?.rabill_date)) {
      formData.append('rabill_date', dayjs(raBill?.rabill_date).format("ddd, DD MMM YYYY 00:00:00") + " GMT")
    }

    if (checkValidation(raBill?.work_completion_date)) {
      formData.append('work_completion_date', dayjs(raBill?.work_completion_date).format("ddd, DD MMM YYYY 00:00:00") + " GMT")
    }

    if (checkValidation(raBill.extention_date)) {
      formData.append('extention_date', dayjs(raBill?.extention_date).format("ddd, DD MMM YYYY 00:00:00") + " GMT")
    }

    if (type === 'Recommendation') {
      formData.append('verification_remarks', raBill?.verification_remarks);
      formData.append('verified_on', new Date(raBill?.verified_on))
      formData.append('verified_by', raBill?.verified_by)
    }

    if (type === 'Approval') {
      formData.append('verification_remarks', raBill?.verification_remarks);
      formData.append('verified_on', new Date(raBill?.verified_on))
      formData.append('verified_by', raBill?.verified_by)

      formData.append('recommended_on', new Date(raBill?.recommended_on))
      formData.append('recommended_by', raBill?.recommended_by)
      formData.append('recommendation_remarks', raBill?.recommendation_remarks);
    }

    if (type === 'Verification' && status_name !== 'Rejected') {
      formData.append('verification_remarks', state.verification_remarks);
      formData.append('verified_on', new Date())
      formData.append('verified_by', user?.id)
    }

    if (type === 'Recommendation' && status_name !== 'Rejected') {
      formData.append('recommended_on', new Date())
      formData.append('recommended_by', user?.id)
      formData.append('recommendation_remarks', state.recommendation_remarks);
      if (checkValidation(state.amount)) {
        formData.append('recommended_amount', state.amount);
      }
    }

    if (type === 'Approval' && status_name !== 'Rejected') {
      formData.append('approval_remarks', state.approval_remarks);
      formData.append('approved_on', new Date())
      formData.append('approved_by', user?.id)
      if (checkValidation(state.amount)) {
        formData.append('approved_amount', state.amount);
      }
      formData.append('retention_amount', parseFloat(parseFloat(((project.retention ? project?.retention : 0) / 100) * getTwoDigitDecimal((creditDebitList[0]?.credit_amount ?? 0) - (creditDebitList[1]?.debit_amount ?? 0))) + parseFloat(calculateSubTotalPrice())).toFixed(2));
      formData.append('tds_amount', parseFloat(parseFloat(((project.tds_percent ? project?.tds_percent : 0) / 100) * getTwoDigitDecimal((creditDebitList[0]?.credit_amount ?? 0) - (creditDebitList[1]?.debit_amount ?? 0))) + parseFloat(calculateSubTotalPrice())).toFixed(2));

    }

    formData.append('extention_no', raBill.extention_no)
    formData.append('total_delay_days', raBill.total_delay_days);
    formData.append('penalty_amount', raBill.penalty_amount);
    formData.append('amount', raBill.amount);
    formData.append('amendment_amount', raBill.amendment_amount);
    formData.append('recovery_amount', raBill.recovery_amount)
    formData.append('submission_filename', raBill.submission_filename);

    if (status_name === 'Recommended') {
      formData.append('status', ProjectStatus.RECOMMENDED);
    } else if (status_name === 'Reviewed') {
      formData.append('status', ProjectStatus.REVIEWED);
    } else if (status_name === 'Approved') {
      formData.append('status', ProjectStatus.APPROVED);
    } else {
      formData.append('status', ProjectStatus.REJECTED);
    }

    formData.append('status_name', status_name.toUpperCase());
    formData.append('net_payable_amount', calculateNetPayableTotal());


    if (checkValidation(raBill?.bill_submission_date)) {
      formData.append('bill_submission_date', dayjs(raBill?.bill_submission_date).format("ddd, DD MMM YYYY 00:00:00") + " GMT");
    }

    if (checkValidation(state.recommended_amendment_amount)) {
      formData.append('recommended_amendment_amount', state.recommended_amendment_amount);
    }
    if (checkValidation(state.recommended_recovery_amount)) {
      formData.append('recommended_recovery_amount', state.recommended_recovery_amount);
    }

    copyToDetailsArray?.forEach((details, index) => {
      if (checkValidation(details?.address)) {
        formData.append(`raBillCopyToDetailsList[${index}].address`, details.address)
      }
      if (checkValidation(details?.email)) {
        formData.append(`raBillCopyToDetailsList[${index}].email`, details.email)
      }

      if (checkValidation(details?.rabillcopytodetails_id)) {
        formData.append(`raBillCopyToDetailsList[${index}].rabillcopytodetails_id`, details.rabillcopytodetails_id)
        formData.append(`raBillCopyToDetailsList[${index}].runningaccountbill_id`, details.runningaccountbill_id)
      } else {
        if (checkValidation(details?.address) || checkValidation(details?.email)) {
          formData.append(`raBillCopyToDetailsList[${index}].runningaccountbill_id`, raBill?.runningaccountbill_id)
        }
      }
    })

    rabilldetails?.forEach((item, index) => {

      if (checkValidation(item.runningaccountbill_details_id) === true) {
        formData.append(`runningAccountBillDetailsList[${index}].runningaccountbill_details_id`, item.runningaccountbill_details_id);
      }
      if (checkValidation(item.runningaccountbill_id) === true) {
        formData.append(`runningAccountBillDetailsList[${index}].runningaccountbill_id`, item.runningaccountbill_id);
      }
      if (checkValidation(item.item_code) === true) {
        formData.append(`runningAccountBillDetailsList[${index}].item_code`, item.item_code);
      }
      if (checkValidation(item.contract_quantity) === true) {
        formData.append(`runningAccountBillDetailsList[${index}].contract_quantity`, item.contract_quantity);
      }
      if (checkValidation(item.contract_rate) === true) {
        formData.append(`runningAccountBillDetailsList[${index}].contract_rate`, item.contract_rate);
      }
      if (checkValidation(item.boq_name) === true) {
        formData.append(`runningAccountBillDetailsList[${index}].boq_name`, item.boq_name);
      }
      // if (checkValidation(item.prev_quantity)) {
      //   formData.append(`runningAccountBillDetailsList[${index}].previous_quantity`, item.prev_quantity);
      // }
      if (checkValidation(item.new_quantity) === true) {
        formData.append(`runningAccountBillDetailsList[${index}].new_quantity`, item.new_quantity);
      }
      if (checkValidation(item.revised_rate) === true) {
        formData.append(`runningAccountBillDetailsList[${index}].revised_rate`, item.revised_rate);
      }
      if (checkValidation(item?.total_amount) === true) {
        formData.append(`runningAccountBillDetailsList[${index}].total_amount`, item?.total_amount);
      }
      if (checkValidation(item.is_deviated) === true) {
        formData.append(`runningAccountBillDetailsList[${index}].is_deviated`, item.is_deviated);
      }
      if (checkValidation(item.deviation_quantity) === true) {
        formData.append(`runningAccountBillDetailsList[${index}].deviation_quantity`, item.deviation_quantity);
      }

      if (checkValidation(item.revised_quantity) === true) {
        formData.append(`runningAccountBillDetailsList[${index}].revised_quantity`, item.revised_quantity);
      }

      if (checkValidation(item.dom_quantity) === true) {
        formData.append(`runningAccountBillDetailsList[${index}].dom_quantity`, item.dom_quantity);
      }

      if (checkValidation(item.contract_amount) === true) {
        formData.append(`runningAccountBillDetailsList[${index}].contract_amount`, item.contract_amount);
      }
      if (checkValidation(item.deviation_amount) === true) {
        formData.append(`runningAccountBillDetailsList[${index}].deviation_amount`, item.deviation_amount);
      }

      if (checkValidation(item.stage_name) === true) {
        formData.append(`runningAccountBillDetailsList[${index}].stage_name`, item.stage_name);
      }


    });
    return formData
  }

  useEffect(() => {
    fetchDataSequentially()
  }, [])

  const fetchDataSequentially = async () => {
    try {
      showLoader()
      const priceResponse = await getAllPriceAdjustmentReport(raBill?.runningaccountbill_id);
      if (priceResponse.status < 300) {
        dispatch({ type: PRICE_ADJUSTMENT_LIST_BILL, payload: priceResponse?.data?.obj?.price_adjustment });
      } else {
        setDisableButton(true)
        const key = enqueueSnackbar(priceResponse?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);
      }

      const debitCreditResponse = await getDebitCreditDetailsForRABillReport(raBill?.project_location_id, raBill?.runningaccountbill_id);
      if (debitCreditResponse.status < 300) {
        dispatch({ type: CREDIT_DEBIT_LIST, payload: debitCreditResponse?.data?.obj?.debit_credit_details });
      } else {
        setDisableButton(true)
        const key = enqueueSnackbar(debitCreditResponse?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);
      }

      const advanceDetailsResponse = await getAllAdvanceDetailsReport(raBill?.project_location_id, raBill?.runningaccountbill_id);
      if (advanceDetailsResponse.status < 300) {
        const sortedArray = advanceDetailsResponse?.data?.obj?.advance_details?.sort((a, b) => b?.advancetype?.length - a?.advancetype?.length);
        dispatch({ type: RECLAIM_ADVANCES_LIST, payload: sortedArray });
        if (type === 'Approval') {
          getRABillReport()
        }

      } else {
        setDisableButton(true)
        const key = enqueueSnackbar(debitCreditResponse?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);
      }
      hideLoader()
    } catch (error) {
      setDisableButton(true)
      hideLoader()
      showToast(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong)
    }
  };

  const showLoader = () => {
    setLoading(true)
  }

  const hideLoader = () => {
    setLoading(false)
  }

  const showToast = (msg) => {
    const key = enqueueSnackbar(msg ?? AppConstants.somethingWentWrong, { variant: 'error' });
    SnackBarProperty.error(key, closeSnackbar);
  }

  const onClickReject = () => {
    setShouldOpenRejectDialog(true)
  }

  const handleRejectConfirmationResponse = () => {
    if (validateAllValues() === false) { return }
    getAlRunningAccountBillDetailsListByParams("Rejected")
  }

  const handleRejectDialogClose = () => {
    setShouldOpenRejectDialog(false)
  }




  function getButtonTitle() {
    if (type === 'Recommendation') {
      return 'Submit for Approval'
    } else if (type === 'Verification') {
      return 'Submit for Recommendation'
    } else if (type === 'Approval') {
      return 'Approve'
    }
    return ''
  }

  function getPopupTitle() {
    if (type === 'Recommendation') {
      return "Are you sure you want to submit this RA Bill for approval?"
    } else if (type === 'Verification') {
      //return 'Please do <span style="color:red;">advance recovery and PA</span>. Are you sure you want to submit this RA Bill for recommendation?'
      return (
        <>
          <span style={{ color: 'red', fontWeight: "bold" }}>Please do advance recovery and PA.</span>
          {" Are you sure you want to submit this RA Bill for recommendation?"}
        </>
      );
    } else if (type === 'Approval') {
      return "Are you sure you want to approve this RA Bill?"
    }
    return ''
  }

  function getRemarksFieldTitle() {
    if (type === 'Recommendation') {
      return 'recommendation_remarks'
    } else if (type === 'Verification') {
      return 'verification_remarks'
    } else if (type === 'Approval') {
      return 'approval_remarks'
    }
    return ''
  }

  function getRemarksFieldValue() {
    if (type === 'Recommendation') {
      return state?.recommendation_remarks
    } else if (type === 'Verification') {
      return state?.verification_remarks
    } else if (type === 'Approval') {
      return state?.approval_remarks
    }
    return ''
  }

  const setDetailsTableUpdatedRateValues = (type, index, value) => {

    const newCopyToDetailsArray = [...copyToDetailsArray]
    if (type === "Email") {
      newCopyToDetailsArray[index].email = value
    } else if (type === "Address") {
      newCopyToDetailsArray[index].address = value
    }
    setCopyToDetailsArray(newCopyToDetailsArray)
  }

  useEffect(() => {

    const newCopyToDetailsArray = [...copyToDetailsArray]

    if (raBill?.raBillCopyToDetailsList?.length > 0) {
      raBill?.raBillCopyToDetailsList?.forEach((details, index) => {
        newCopyToDetailsArray[index].address = details.address
        newCopyToDetailsArray[index].email = details.email
        newCopyToDetailsArray[index].rabillcopytodetails_id = details.rabillcopytodetails_id
        newCopyToDetailsArray[index].runningaccountbill_id = details.runningaccountbill_id
      })
      setCopyToDetailsArray(newCopyToDetailsArray)

    }

    console.log('copy array', copyToDetailsArray)

  }, [raBill]);

  const handleRecommendConfirmationDialogClose = () => {
    setShouldOpenRecommendConfirmationDialog(false)
  }

  const handleRecommendConfirmationResponse = () => {
    if (getRAStatus() === 'Recommended') {
      getRecommendedBillList()
    } else {
      getAlRunningAccountBillDetailsListByParams(getRAStatus())
    }
  }


  const handleReportSend = () => {

    const doc = new jsPDF();
    doc.setFontSize(12); // Set font size
    const textX = 15;
    const textY = 15; // Adjust the value for vertical separation

    // Add the text content below the header image
    doc.setFont('helvetica', 'bold'); // Set font to bold
    doc.setFont('helvetica', 'bold'); // Set font to bold
    doc.setFontSize(12); // Set font size
    doc.text("SUMMARY OF RUNNING ACCOUNT BILL", (doc.internal.pageSize.getWidth() / 2) - ((doc.getStringUnitWidth("SUMMARY OF RUNNING ACCOUNT BILL") * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2), textY);

    doc.setFontSize(8);
    doc.text("Project Id:", textX, textY + 10);
    const firstTextWidth = doc.getStringUnitWidth("Project Id:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(project?.project_id, textX + firstTextWidth + 5, textY + 10);
    doc.setFont('helvetica', 'bold');
    doc.text("Project Location:", textX, textY + 15);
    const secondTextWidth = doc.getStringUnitWidth("Project Location:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(project?.project_location, textX + secondTextWidth + 5, textY + 15);
    doc.setFont('helvetica', 'bold');
    doc.text("RA Bill No.:", textX, textY + 20);
    const thirdTextWidth = doc.getStringUnitWidth("RA Bill No.:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(raBill?.rabill_no, textX + thirdTextWidth + 5, textY + 20);
    doc.setFont('helvetica', 'bold');
    doc.text("RA Bill Date:", textX, textY + 25);
    const fourthTextWidth = doc.getStringUnitWidth("Bill Date:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(formatDate(raBill?.rabill_date), textX + fourthTextWidth + 5, textY + 25);
    doc.setFont('helvetica', 'bold');
    doc.text("Contractor:", textX, textY + 30);
    const newTextWidth = doc.getStringUnitWidth("Contractor:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(project?.company ? project?.company : "", textX + newTextWidth + 5, textY + 30);
    doc.setFont('helvetica', 'bold');
    doc.text("Project Name:", textX, textY + 35);
    const fifthTextWidth = doc.getStringUnitWidth("Project Name:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(project?.project_name, textX + fifthTextWidth + 5, textY + 35);
    doc.setFont('helvetica', 'bold');
    doc.text("Client:", textX, textY + 40);
    const sixthTextWidth = doc.getStringUnitWidth("Client:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(raBill?.agency_name ? raBill?.agency_name : "", textX + sixthTextWidth + 5, textY + 40);
    doc.setFont('helvetica', 'bold');
    doc.text("Bill Submission Date:", (doc.internal.pageSize.getWidth() / 2), textY + 10);
    const seventhTextWidth = doc.getStringUnitWidth("Bill Submission Date:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(formatDate(raBill?.bill_submission_date), (doc.internal.pageSize.getWidth() / 2) + seventhTextWidth + 5, textY + 10);
    doc.setFont('helvetica', 'bold');
    doc.text("Initial Contract Amount (Nu):", (doc.internal.pageSize.getWidth() / 2), textY + 15);
    const eighthTextWidth = doc.getStringUnitWidth("Initial Contract Amount:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(parseFloat(project?.initial_contract_amount).toFixed(2), (doc.internal.pageSize.getWidth() / 2) + eighthTextWidth + 5, textY + 15);
    //doc.setFont('helvetica', 'bold');
    // doc.text("Current RA Bill Amount:", (doc.internal.pageSize.getWidth()/2), textY + 20);
    // const ninthTextWidth = doc.getStringUnitWidth("Current RA Bill Amount:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    // doc.setFont('helvetica', 'normal');
    // doc.text(parseFloat(raBill?.amount).toFixed(2), (doc.internal.pageSize.getWidth()/2) + ninthTextWidth + 5, textY + 20);
    doc.setFont('helvetica', 'bold');
    doc.text("Total Paid to Date (Nu):", (doc.internal.pageSize.getWidth() / 2), textY + 20);
    const tenthTextWidth = doc.getStringUnitWidth("Total Paid to Date: ") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(parseFloat(getTwoDigitDecimal(creditDebitList[0]?.credit_amount ?? 0)).toFixed(2), (doc.internal.pageSize.getWidth() / 2) + tenthTextWidth + 5, textY + 20);
    doc.setFont('helvetica', 'bold');
    doc.text("Balance Amount (Nu):", (doc.internal.pageSize.getWidth() / 2), textY + 25);
    const eleventhTextWidth = doc.getStringUnitWidth("Balance Amount: ") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(parseFloat(((project.initial_contract_amount ? project?.initial_contract_amount : 0) - getTwoDigitDecimal(creditDebitList[0]?.credit_amount ?? 0))).toFixed(2), (doc.internal.pageSize.getWidth() / 2) + eleventhTextWidth + 5, textY + 25);

    // const headerSectionHeight = headerHeight + pdfHeight; // Adjust the value as needed

    // // Calculate the starting Y-coordinate for the table
    // const tableStartY = headerSectionHeight + 30;
    doc.setFont('helvetica', 'normal');
    // console.log(creditDebitList)
    const body = [
      ["Total measured amount of work", "", { content: parseFloat(creditDebitList[0]?.credit_amount ? creditDebitList[0]?.credit_amount : 0).toFixed(2), styles: { halign: "right" } }, ""],
      ["Less Previous Running Account Total", { content: parseFloat(creditDebitList[1]?.debit_amount ? creditDebitList[1]?.debit_amount : 0).toFixed(2), styles: { halign: "right" } }, "", ""],
      [{ content: "Sub-Total", colSpan: 1, styles: { fontStyle: "bold" } }, { content: parseFloat(getTwoDigitDecimal((creditDebitList[0]?.credit_amount ?? 0) - (creditDebitList[1]?.debit_amount ?? 0))).toFixed(2), colSpan: 2, styles: { halign: "center", fontStyle: "bold" } }, ""]

    ]
    if (priceAdjustmentList?.length > 0) {
      body.push([{ content: "Price Adjustment", colSpan: 4, styles: { fontStyle: "bold" } }])
      priceAdjustmentList?.forEach((elem) => {
        body.push([{ content: elem?.category, colSpan: 2 }, { content: parseFloat(elem?.adjustment_amount ? elem?.adjustment_amount : 0).toFixed(2), colSpan: 2 }])
      })
      body.push([{ content: "Sub-Total", colSpan: 2, styles: { fontStyle: "bold" } }, { content: parseFloat(calculateSubTotalPrice()).toFixed(2), colSpan: 2, styles: { fontStyle: "bold" } }])
    }
    body.push([{ content: "Sub-Total", colSpan: 2, styles: { fontStyle: "bold" } }, { content: parseFloat(parseFloat(getTwoDigitDecimal((creditDebitList[0]?.credit_amount ?? 0) - (creditDebitList[1]?.debit_amount ?? 0))) + parseFloat(calculateSubTotalPrice())).toFixed(2), colSpan: 2, styles: { fontStyle: "bold", } }])
    body.push([{ content: "Less Delay Penalty", colSpan: 1, styles: { fontStyle: "bold" } }, { content: parseFloat(raBill?.penalty_amount ? raBill?.penalty_amount : 0).toFixed(2), colSpan: 1, styles: { fontStyle: "bold", halign: "right" } }])
    body.push([{ content: "SubTotal", colSpan: 2, styles: { fontStyle: "bold" } },
    {
      content: parseFloat(parseFloat(parseFloat(getTwoDigitDecimal((creditDebitList[0]?.credit_amount ?? 0) - (creditDebitList[1]?.debit_amount ?? 0))) + parseFloat(calculateSubTotalPrice())) -
        parseFloat(getTwoDigitDecimal(raBill?.penalty_amount ?? 0))).toFixed(2), colSpan: 2,
      styles: { fontStyle: "bold" }
    }])
    body.push([{ content: `Less ${project?.retention ?? 0}% Retention`, colSpan: 1, }, { content: getRetentionAmount(), colSpan: 1, styles: { halign: "right" } }])
    body.push([{ content: `Less ${project?.tds_percent ?? 0}% TDS`, colSpan: 1 }, { content: getTdsAmount(), colSpan: 1, styles: { halign: "right" } }])
    body.push([{ content: "Sub-Total", colSpan: 2, styles: { fontStyle: "bold" } }, { content: parseFloat(calculateDepositSubTotal()).toFixed(2), colSpan: 2, styles: { fontStyle: "bold", } }])

    doc.autoTable({
      startY: 60,
      head: [[
        { content: "DESCRIPTION", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
        { content: "DEBIT (NU)", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
        { content: "CREDIT (NU)", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
        { content: "REMARKS", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },

      ]],
      body: body, // Use the transformed data here
      theme: "grid",
      styles: {
        fillColor: [255, 255, 255], // Remove background color from the table
        lineColor: [0, 0, 0], // Set border color for main table
        lineWidth: 0.1, // Set border width for main table
        textColor: [0, 0, 0], // Set text color to black
        cellPadding: 2, // Add padding to cells
      },
      columnStyles: {
        0: { cellWidth: 80 }
      },
    });
    // doc.autoTable.previous.finalY + 20
    const reclaimTableData = []

    if (reclaimAdvancesList?.length > 0) {
      reclaimAdvancesList?.forEach((elem) => {
        reclaimTableData.push([elem?.advancetype,
        { content: parseFloat(elem?.approved_amount ? elem?.approved_amount : 0).toFixed(2), styles: { halign: "right" } },
        { content: parseFloat(elem?.recovered_amount ? elem?.recovered_amount : 0).toFixed(2), styles: { halign: "right" } },
        { content: parseFloat((elem?.approved_amount ? elem?.approved_amount : 0) - (elem?.recovered_amount ? elem?.recovered_amount : 0)).toFixed(2), styles: { halign: "right" } },
        { content: parseFloat(elem?.current_recovered_amount ? elem?.current_recovered_amount : 0).toFixed(2), styles: { halign: "right" } }
        ])
      })
      reclaimTableData.push([
        { content: "Total", styles: { fontStyle: "bold" } },
        { content: parseFloat(calculateTotalAdvanceAmount()).toFixed(2), styles: { halign: "right" } },
        { content: parseFloat(calculateTotalRecoveredAmount()).toFixed(2), styles: { halign: "right" } },
        { content: parseFloat(calculateTotalBalanceAmount()).toFixed(2), styles: { halign: "right" } },
        { content: parseFloat(calculateTotalCurrentRecovery()).toFixed(2), styles: { halign: "right" } }
      ])
      reclaimTableData.push([{ content: "Net Payment to Contractor (Nu.)", colSpan: 2, styles: { fontStyle: "bold" } }, { content: parseFloat(calculateNetPayableTotal()).toFixed(2), colSpan: 2, styles: { fontStyle: "bold", halign: "right" } }])
      doc.autoTable({
        startY: doc.autoTable.previous.finalY + 10,
        head: [[
          { content: "Reclaim of Advances", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Total Advances (NU)", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Advance Recovered till Last R.A Bill", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Balance", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Current Recovery", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },

        ]],
        body: reclaimTableData, // Use the transformed data here
        theme: "grid",
        styles: {
          fillColor: [255, 255, 255], // Remove background color from the table
          lineColor: [0, 0, 0], // Set border color for main table
          lineWidth: 0.1, // Set border width for main table
          textColor: [0, 0, 0], // Set text color to black
          cellPadding: 2, // Add padding to cells
        },
        columnStyles: {
          0: { cellWidth: 60 }
        },
      });



    }
    doc.setFontSize(8);
    doc.setFont('helvetica', 'bold');// Set font size
    doc.text(`Submitted by:`, 15, doc.autoTable.previous.finalY + 20);
    const sub_text = doc.getStringUnitWidth("Submitted by:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(`${raBill?.created_name ?? ""} `, 15 + 3 + sub_text, doc.autoTable.previous.finalY + 20);
    doc.setFont('helvetica', 'bold');
    doc.text(`Verified by:  `, doc.internal.pageSize.getWidth() - 100, doc.autoTable.previous.finalY + 20);
    const ver_text = doc.getStringUnitWidth("Verified by:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(`${raBill?.verified_name ?? ""} `, doc.internal.pageSize.getWidth() - 100 + 3 + ver_text, doc.autoTable.previous.finalY + 20);
    doc.setFont('helvetica', 'bold');
    doc.text(`Recommended by:`, 15, doc.autoTable.previous.finalY + 25);
    const rec_text = doc.getStringUnitWidth("Recommended by:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(`${raBill?.recommended_name ?? ""} `, 15 + 3 + rec_text, doc.autoTable.previous.finalY + 25);
    doc.setFont('helvetica', 'bold');
    doc.text(`Approved by:`, doc.internal.pageSize.getWidth() - 100, doc.autoTable.previous.finalY + 25);
    const apv_text = doc.getStringUnitWidth("Approved by:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(`${raBill?.approved_name ?? ""} `, doc.internal.pageSize.getWidth() - 100 + 3 + apv_text, doc.autoTable.previous.finalY + 25);

    const pdfBlob = doc.output('blob');
    const pdfFile = new File([pdfBlob], '"Ra_Bill_Summary_Report.pdf', { type: 'application/pdf' });
    console.log("PDF File:", pdfFile)
    return pdfFile
    // doc.save("Ra_Bill_Summary_Report.pdf");
  };

  const getRABillReport = () => {
    setLoading(true)
    setDisableButton(true)
    getRunningAccountBillForReport({ project_location_id: raBill?.project_location_id, ra_bill_id: raBill?.runningaccountbill_id })
      .then((res) => {
        setLoading(false)
        // console.log("response:", res)
        if (res?.status < 300) {
          setDisableButton(false)
          if (res?.data?.obj) {
            if (res?.data?.obj && res?.data?.obj?.item) {
              if (res?.data?.obj?.item?.item && Object.keys(res?.data?.obj?.item?.item).length > 0) {
                const table = []
                Object.keys(res?.data?.obj?.item?.item).forEach(key => {
                  let obj = {}
                  const constructionName = key
                  const itemList = res?.data?.obj?.item?.item[key];
                  if (res?.data?.obj?.item?.item[key]?.length > 0) {
                    const subTotal = res?.data?.obj?.item?.item[key]?.reduce((sum, item) => sum + parseFloat(item?.new_quantity ? (parseFloat(item?.new_quantity) * parseFloat(item?.contract_rate)) : 0), 0);
                    const subTotalRevised = res?.data?.obj?.item?.item[key]?.reduce((sum, item) => sum + parseFloat(item.revised_amount), 0);
                    obj = {
                      construction_name: constructionName,
                      item_list: itemList,
                      sub_total_tender: parseFloat(subTotal ? subTotal : 0).toFixed(2),
                      sub_total_revised: parseFloat(subTotalRevised ? subTotalRevised : 0).toFixed(2)
                    }
                  }
                  // console.log(`Key: ${key}, Value: ${value}`);
                  table.push(obj)
                });
                console.log("table: ", table)
                const grand_total_tender = table.reduce((sum, item) => sum + parseFloat(item?.sub_total_tender), 0)
                const grand_total_revised = table.reduce((sum, item) => sum + parseFloat(item?.sub_total_revised), 0)
                const obj = {
                  grand_tender_amount: parseFloat(grand_total_tender ? grand_total_tender : 0).toFixed(2),
                  grand_revised_amount: parseFloat(grand_total_revised ? grand_total_revised : 0).toFixed(2)
                }
                setGrandTotal(obj)
                setTableData(table)
              }


              setProjectDetails({
                company: res?.data?.obj?.item?.company,
                contract_no: res?.data?.obj?.item?.tender_id,
                project_id: res?.data?.obj?.item?.project_id,
                project_name: res?.data?.obj?.item?.project_name,
                dzongkhag_name: res?.data?.obj?.item?.dzongkhagName,
                header: res?.data?.obj?.header_logo,
                footer: res?.data?.obj?.footer_logo

              })
            }
          }
        }
        else {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        }
      }).catch(err => {
        setDisableButton(true)
        setLoading(false)
        const key = enqueueSnackbar(err?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      })
  }
  const getRABillReportFile = () => {
    setLoading(true)
    const doc = new jsPDF();
    doc.setFontSize(12); // Set font size
    const textX = 15;
    const textY = 15; // Adjust the value for vertical separation

    // Add the text content below the header image
    doc.setFont('helvetica', 'bold'); // Set font to bold
    doc.setFont('helvetica', 'bold'); // Set font to bold
    doc.setFontSize(12); // Set font size
    doc.text("RUNNING ACCOUNT BILL", (doc.internal.pageSize.getWidth() / 2) - ((doc.getStringUnitWidth("RUNNING ACCOUNT BILL") * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2), textY);

    doc.setFontSize(8);
    doc.text("Project Id:", textX, textY + 10);
    const firstTextWidth = doc.getStringUnitWidth("Project Id:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(project?.project_id, textX + firstTextWidth + 5, textY + 10);
    doc.setFont('helvetica', 'bold');
    doc.text("Project Location:", textX, textY + 15);
    const secondTextWidth = doc.getStringUnitWidth("Project Location:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(project?.project_location, textX + secondTextWidth + 5, textY + 15);
    doc.setFont('helvetica', 'bold');
    doc.text("Project Name:", textX, textY + 20);
    const thirdTextWidth = doc.getStringUnitWidth("Project Name:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(project?.project_name, textX + thirdTextWidth + 5, textY + 20);
    doc.setFont('helvetica', 'bold');
    doc.text("Client:", textX, textY + 25);
    const fourthTextWidth = doc.getStringUnitWidth("Client:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(raBill?.agency_name ? raBill?.agency_name : "", textX + fourthTextWidth + 5, textY + 25);
    doc.setFont('helvetica', 'bold');
    doc.text("Contractor:", textX, textY + 30);
    const newTextWidth = doc.getStringUnitWidth("Contractor") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(project?.company ? project?.company : "", textX + newTextWidth + 5, textY + 30);
    doc.setFont('helvetica', 'bold');
    doc.text("RA Bill No.:", textX, textY + 35);
    const fifthTextWidth = doc.getStringUnitWidth("RA Bill No.:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(raBill?.rabill_no, textX + fifthTextWidth + 5, textY + 35);
    doc.setFont('helvetica', 'bold');
    doc.text("RA Bill Date:", textX, textY + 40);
    const sixthTextWidth = doc.getStringUnitWidth("Bill Date:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(formatDate(raBill?.rabill_date), textX + sixthTextWidth + 5, textY + 40);
    doc.setFont('helvetica', 'bold');
    doc.text("Bill Submission Date:", (doc.internal.pageSize.getWidth() / 2), textY + 10);
    const seventhTextWidth = doc.getStringUnitWidth("Bill Submission Date:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(moment(raBill?.bill_submission_date).format("DD-MM-YYYY"), (doc.internal.pageSize.getWidth() / 2) + seventhTextWidth + 5, textY + 10);




    // Calculate the starting Y-coordinate for the table
    // const tableStartY = headerSectionHeight + 20;
    doc.setFont('helvetica', 'normal');

    const body = []
    body.push([{ content: "", colSpan: 2 },
    { content: "Tender", styles: { fontStyle: "bold" } },
    { content: "Till Last RA", styles: { fontStyle: "bold" } },
    { content: "Current", styles: { fontStyle: "bold" } },
    { content: "Deviated", styles: { fontStyle: "bold" } },
    { content: "Cumulative", styles: { fontStyle: "bold" } },
      "",
    { content: "Tender", styles: { fontStyle: "bold" } },
    { content: "Revised", styles: { fontStyle: "bold" } },
    { content: "Tender", styles: { fontStyle: "bold" } },
    { content: "Revised", styles: { fontStyle: "bold" } }])
    tableData?.forEach((elem) => {
      body.push([
        { content: elem?.construction_name, colSpan: 12, styles: { fontStyle: "bold" } }
      ])
      elem?.item_list?.forEach((item) => {
        body.push([
          item?.item_code, item?.description,
          `${formatNumberWithCommasAndDecimals(parseFloat(item?.contract_quantity ? item?.contract_quantity : 0).toFixed(2))}`,
          `${formatNumberWithCommasAndDecimals(parseFloat(item?.prev_quantity ? item?.prev_quantity : 0).toFixed(2))}`,
          `${formatNumberWithCommasAndDecimals(parseFloat(item?.new_quantity ? item?.new_quantity : 0).toFixed(2))}`,
          `${formatNumberWithCommasAndDecimals(parseFloat(item?.deviation_quantity ? item?.deviation_quantity : 0).toFixed(2))}`,
          `${formatNumberWithCommasAndDecimals(parseFloat(parseFloat(item?.prev_quantity ? item?.prev_quantity : 0) + parseFloat(item?.new_quantity ? item?.new_quantity : 0) + parseFloat(item?.deviation_quantity ? item?.deviation_quantity : 0)).toFixed(2))}`,
          item?.unit_name,
          { content: formatNumberWithCommasAndDecimals(parseFloat(item?.contract_rate ? item?.contract_rate : 0).toFixed(2)), styles: { halign: "right" } },
          { content: formatNumberWithCommasAndDecimals(parseFloat(item?.revised_rate ? item?.revised_rate : 0).toFixed(2)), styles: { halign: "right" } },
          { content: formatNumberWithCommasAndDecimals(parseFloat((item?.new_quantity ? parseFloat(item?.new_quantity) * parseFloat(item?.contract_rate) : 0)).toFixed(2)), styles: { halign: "right" } },
          { content: formatNumberWithCommasAndDecimals(parseFloat(item?.revised_amount ? item?.revised_amount : 0).toFixed(2)), styles: { halign: "right" } }
        ])
      })
      body.push([
        { content: "Sub-Total", colSpan: 10, styles: { fontStyle: "bold", halign: "right" } }, { content: formatNumberWithCommasAndDecimals(elem?.sub_total_tender), colSpan: 1, styles: { fontStyle: "bold", halign: "right" } }, { content: elem?.sub_total_revised, colSpan: 1, styles: { fontStyle: "bold", halign: "right" } }
      ])
    })
    body.push([
      { content: "Grand Total", colSpan: 10, styles: { fontStyle: "bold", halign: "right" } }, { content: formatNumberWithCommasAndDecimals(grandTotal?.grand_tender_amount), colSpan: 1, styles: { fontStyle: "bold", halign: "right" } },
      { content: formatNumberWithCommasAndDecimals(grandTotal?.grand_revised_amount), colSpan: 1, styles: { fontStyle: "bold", halign: "right" } }
    ])


    doc.autoTable({
      startY: 60,
      head: [[
        { content: "Item Code", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2 } },
        { content: "Description", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2 } },
        { content: "Quantity", colSpan: 5, styles: { valign: "middle", halign: "center", cellPadding: 2 } },
        { content: "Unit", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2 } },
        { content: "Rate (Nu)", colSpan: 2, styles: { valign: "middle", halign: "center", cellPadding: 2 } },
        { content: "Amount (Nu)", colSpan: 2, styles: { valign: "middle", halign: "center", cellPadding: 2 } }
      ]],
      body: body, // Use the transformed data here
      theme: "grid",
      styles: {
        fillColor: [255, 255, 255], // Remove background color from the table
        lineColor: [0, 0, 0], // Set border color for main table
        lineWidth: 0.1, // Set border width for main table
        textColor: [0, 0, 0], // Set text color to black
        cellPadding: 2, // Add padding to cells
        fontSize: 7
      },
      columnStyles: {
      },
    });
    doc.setFontSize(8);
    doc.setFont('helvetica', 'bold');// Set font size
    doc.text(`Checked by:  `, doc.internal.pageSize.getWidth() - 100, doc.autoTable.previous.finalY + 20);
    doc.text("Prepared by:", 15, doc.autoTable.previous.finalY + 20);
    const scaleFactor = 1.0; // Adjust this value as needed


    setLoading(false)
    const pdfBlob = doc.output('blob');
    const pdfFile = new File([pdfBlob], '"Ra_Bill_Report.pdf.pdf', { type: 'application/pdf' });
    console.log("PDF File:", pdfFile)
    return pdfFile

  };

  function getSubTotalPrice() {
    return getTwoDigitDecimal((getTwoDigitDecimal(creditDebitList[0]?.credit_amount ?? 0) - getTwoDigitDecimal(creditDebitList[1]?.debit_amount ?? 0)) + parseFloat(calculateSubTotalPrice())) -
      getTwoDigitDecimal(raBill?.penalty_amount ?? 0)
  }


  return (
    <Dialog open={open} maxWidth="lg" fullWidth={true}>
      <Box p={3}>
        <H4 sx={{ mb: "10px" }}><b>RA Bill Summary</b></H4>
        <Divider sx={{ mb: 1 }} />

        <ValidatorForm onSubmit={handleFormSubmit}>
          <Grid sx={{ mb: "16px" }} container spacing={4}>
            <Grid item sm={6} xs={12}>
              <p><b>Contract ID:</b> {project?.project_id}</p>
              <p><b>Project Location: </b>{project?.project_location}</p>
              <p style={{ marginTop: 5 }}><b>RA Bill No.:</b> {raBill?.rabill_no}</p>
              <p style={{ marginTop: 5 }}><b>RA Bill Date:</b> {formatDate(raBill?.rabill_date)}</p>
              <p style={{ marginTop: 5 }}><b>Project Name:</b> {project?.project_name}</p>
              <p style={{ marginTop: 5 }}><b>Client:</b> {raBill?.agency_name}</p>
              <p style={{ marginTop: 5 }}><b>Contractor:</b> {project?.company}</p>
            </Grid>
            <Grid item sm={6} xs={12}>
              <p style={{ marginTop: 5 }}><b>Bill Submission Date: </b> {formatDate(raBill?.bill_submission_date)}</p>
              <p style={{ marginTop: 5 }}><b>Initial Contract Amount: </b> {currencyFormat(project.initial_contract_amount ? project?.initial_contract_amount : 0)}</p>
              <p style={{ marginTop: 5 }}><b>Total Paid to Date: </b>
                {currencyFormat(creditDebitList[0]?.credit_amount)}
              </p>
              <p style={{ marginTop: 5 }}><b>Balance Amount: </b>
                {currencyFormat(((project.initial_contract_amount ? project?.initial_contract_amount : 0) - getTwoDigitDecimal(creditDebitList[0]?.credit_amount ?? 0)))}
              </p>
            </Grid>
          </Grid>

          <Grid sx={{ mb: "16px" }} container spacing={4}>
            <Grid item sm={6} xs={12}>
              {checkValidation(raBill?.submission_filename) && (
                <Box mb={3} display="flex" flexWrap={1} >
                  <FabIcon color="warning" component="span" variant="extended"
                    onClick={() => downloadImplementationFile(state?.submission_filename, 'RaBill')}>
                    <FlexBox>
                      <Icon className="icon">cloud_download</Icon>
                      <span>View Supporting Document</span>
                    </FlexBox>
                  </FabIcon>
                </Box>
              )}
            </Grid>
            <Grid item sm={6} xs={12}>
            </Grid>
          </Grid>


          <Grid container sx={{ mb: 4 }}>
            <Table sx={{ border: 1 }} >
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2} style={{ padding: '5px' }}>DESCRIPTION</TableCell>
                  <TableCell>DEBIT (Nu)</TableCell>
                  <TableCell>CREDIT (Nu)</TableCell>
                  <TableCell>REMARKS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={2} style={{ padding: '5px' }}>Total measured amount of work</TableCell>
                  <TableCell></TableCell>
                  <TableCell >{currencyFormat(creditDebitList[0]?.credit_amount)}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} style={{ padding: '5px' }}>Less Previous Running Account Total</TableCell>
                  <TableCell>{currencyFormat(creditDebitList[1]?.debit_amount)}</TableCell>
                  <TableCell ></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={3} style={{ padding: '5px' }}><b>Sub-Total</b></TableCell>
                  <TableCell ><b>{currencyFormat(getTwoDigitDecimal(creditDebitList[0]?.credit_amount ?? 0) - getTwoDigitDecimal(creditDebitList[1]?.debit_amount ?? 0))}</b></TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <H4 style={{ marginTop: 15, marginBottom: 10, padding: '5px' }} ><b>Price Adjustment</b></H4>

            <Table sx={{ border: 1 }}>
              <TableBody>
                {priceAdjustmentList?.map((price, index) => (
                  <TableRow>
                    <TableCell colSpan={2} style={{ padding: '5px' }}>{price?.category}</TableCell>
                    <TableCell></TableCell>
                    <TableCell >{currencyFormat(price?.adjustment_amount)}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={3} style={{ padding: '5px' }}><b>Sub-Total</b></TableCell>
                  <TableCell ><b>{currencyFormat(calculateSubTotalPrice())}</b></TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table sx={{ border: 1, mt: 2 }}>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={2} style={{ padding: '5px' }}><b>Sub-Total</b></TableCell>
                  <TableCell ></TableCell>
                  <TableCell ><b>
                    {
                      currencyFormat((getTwoDigitDecimal(creditDebitList[0]?.credit_amount) - getTwoDigitDecimal(creditDebitList[1]?.debit_amount)) + getTwoDigitDecimal(calculateSubTotalPrice()))
                    }
                  </b></TableCell>
                  <TableCell ></TableCell>
                </TableRow>
              </TableBody>
            </Table>


            <Table sx={{ mt: 2 }}>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={2} style={{ padding: '5px' }}><b>Less Delay Penalty</b></TableCell>
                  <TableCell><b>{currencyFormat(raBill?.penalty_amount)}</b></TableCell>
                  <TableCell ></TableCell>
                  <TableCell ></TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <Table sx={{ border: 1 }}>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={2} style={{ padding: '5px' }}><b>Sub-Total</b></TableCell>
                  <TableCell ></TableCell>
                  <TableCell ><b>

                    {
                      currencyFormat(getSubTotalPrice())
                    }

                  </b></TableCell>
                  <TableCell ></TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <Table sx={{ border: 1, mt: 3 }}>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={2} style={{ padding: '5px' }}>Less {project?.retention ?? 0}% Retention </TableCell>
                  <TableCell >
                    {
                      currencyFormat(getRetentionAmount())
                    }
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} style={{ padding: '5px' }}>Less {project?.tds_percent ?? 0}% TDS </TableCell>
                  <TableCell>
                    {
                      currencyFormat(getTdsAmount())
                    }
                  </TableCell>
                  <TableCell ></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={3} style={{ padding: '5px' }}><b>Sub-Total</b></TableCell>
                  <TableCell ><b>{currencyFormat(calculateDepositSubTotal())}</b></TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <Table sx={{ border: 1, mt: 4 }} >
              <TableHead>
                <TableRow>
                  <TableCell style={{ padding: '5px' }}>Reclaim of Advances</TableCell>
                  <TableCell align="right">Total Advances</TableCell>
                  <TableCell align="right">Advance Recovered till Last R.A Bill</TableCell>
                  <TableCell align="right">Balance</TableCell>
                  <TableCell align="right">Current Recovery</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reclaimAdvancesList?.map((advance, index) => (
                  <TableRow>
                    <TableCell style={{ padding: '5px' }}>{advance?.advancetype}</TableCell>
                    <TableCell align="right">{currencyFormat(advance?.approved_amount)}</TableCell>
                    <TableCell align="right">{currencyFormat(advance?.recovered_amount)}</TableCell>
                    <TableCell align="right">{currencyFormat(getTwoDigitDecimal(advance?.approved_amount ?? 0) - getTwoDigitDecimal(advance?.recovered_amount ?? 0))}</TableCell>
                    <TableCell align="right">{currencyFormat(advance?.current_recovered_amount)}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell style={{ padding: '5px' }}><b>Total</b></TableCell>
                  <TableCell align="right">{currencyFormat(calculateTotalAdvanceAmount())}</TableCell>
                  <TableCell align="right">{currencyFormat(calculateTotalRecoveredAmount())}</TableCell>
                  <TableCell align="right">{currencyFormat(calculateTotalBalanceAmount())}</TableCell>
                  <TableCell align="right">{currencyFormat(calculateTotalCurrentRecovery())}</TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <Table sx={{ border: 1, mt: 3 }}>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={2} style={{ padding: '5px' }}><b>Net Payment to Contractor (Nu.)</b></TableCell>
                  <TableCell ></TableCell>
                  <TableCell ><b>{currencyFormat(calculateNetPayableTotal())}</b></TableCell>
                  <TableCell ></TableCell>
                </TableRow>
              </TableBody>
            </Table>

          </Grid>
          {type === 'Verification' &&
            <div>
              <H4 sx={{ mb: 2 }}>Copy to:</H4>
              {copyToDetailsArray?.map((details, index) => (
                <div>
                  <Grid sx={{ mb: "16px" }} container spacing={4}>
                    <Grid item sm={6} xs={12}>

                      <TextField
                        size='small'
                        disabled={type !== 'Verification'}
                        type="text"
                        name="address"
                        id="address"
                        label="Address"
                        variant="outlined"
                        value={details?.address || ''}
                        onChange={e => setDetailsTableUpdatedRateValues('Address', index, e.target.value)}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        disabled={type !== 'Verification'}
                        size='small'
                        label="Email"
                        id="email"
                        variant="outlined"
                        name="email"
                        value={details?.email || ""}
                        onChange={e => setDetailsTableUpdatedRateValues('Email', index, e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </div>
              ))}
            </div>
          }
          <Grid container>
            <Grid items xs={12} md={4}>
              <TextField
                type="text"
                rows={2.5}
                multiline={true}
                name={getRemarksFieldTitle()}
                label="Remarks"
                value={getRemarksFieldValue()}
                onChange={handleChange}
                validators={["required"]}
                errorMessages={["Remarks is required"]}
              />
            </Grid>

          </Grid>

          <FlexBetween>
            <LoadingButton disabled={disableButton} variant="contained" id="recommend" name="recommend" color="primary" onClick={(e) => handleFormSubmit()}>
              {getButtonTitle()}
            </LoadingButton>
            {type === "Verification" && <LoadingButton disabled={disableButton} variant="contained" id="reject" name="reject" color="error" onClick={(e) => onClickReject()}>
              Reject
            </LoadingButton>}
            <Button variant="outlined" color="secondary" onClick={() => handleClose()}>
            Close
            </Button>
          </FlexBetween>
        </ValidatorForm>
      </Box>
      {loading && (
        <Loader
          show={loading}
        />
      )}
      {
        shouldOpenRecommendConfirmationDialog && (
          <ConfirmationDialog
            text={getPopupTitle()}
            type="green"
            open={shouldOpenRecommendConfirmationDialog}
            onConfirmDialogClose={handleRecommendConfirmationDialogClose}
            onYesClick={handleRecommendConfirmationResponse}
          />
        )
      }
      {
        shouldOpenRejectDialog && (
          <ConfirmationDialog
            text={"Are you sure you want to reject this RA Bill?"}
            type="red"
            open={shouldOpenRejectDialog}
            onConfirmDialogClose={handleRejectDialogClose}
            onYesClick={handleRejectConfirmationResponse}
          />
        )
      }
    </Dialog>
  );
};

export default NewRABillVerificationDialog;
